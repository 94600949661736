<template>
  <faq-form
    :on-submit="addFaq"
    :submit-in-progress="showLoading"
    :created-success="isCreatedSuccess"
    :submit-button-text="
      $t('FAQ_MANAGE_SETTINGS.CREATE_FLOW.CREATE.BUTTON_TEXT')
    "
  />
</template>

<script>
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';
import FaqForm from '../FaqForm';

export default {
  components: {
    FaqForm,
  },
  mixins: [alertMixin],
  data() {
    return { isCreatedSuccess: false, showLoading: false };
  },
  methods: {
    async addFaq(data) {
      try {
        this.showLoading = true;
        const faq = await this.$store.dispatch('aiStoryManage/createStory', {
          ...data,
        });
        if (faq) {
          this.showAlert(
            this.$t('FAQ_MANAGE_SETTINGS.CREATE_FLOW.API.SUCCESS_MESSAGE')
          );
          this.showLoading = false;
          router.replace({
            name: 'settings_faq_manage_list',
          });
        }
      } catch (error) {
        this.showAlert(
          this.$t('FAQ_MANAGE_SETTINGS.CREATE_FLOW.API.ERROR_MESSAGE')
        );
        this.showLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
