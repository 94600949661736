/* global axios */
import ApiClient from './ApiClient';

export class GptModelsApi extends ApiClient {
  constructor() {
    super('gpt/gpt_models', { accountScoped: true });
  }

  getModels({ id, pageNum }) {
    return axios.get(`${this.url}/${id}/gpt/gpt_models?page=${pageNum}`);
  }

  updateModels({ id, Model_id }) {
    return axios.patch(`${this.url}/${id}/gpt/gpt_models${Model_id}`);
  }

  deleteModels({ Model_id }) {
    return axios.delete(`${this.url}/${Model_id}/gpt/gpt_models${Model_id}`);
  }
}

export default new GptModelsApi();
