export const getters = {
  getActions: $state => {
    const data = Object.values($state.actions.data).sort((a, b) => b.id - a.id);
    const count = $state.actions.count;
    return { data, count };
  },
  getUIFlags: $state => {
    return $state.uiFlags;
  },
  getAction: $state => id => {
    const action = $state.actions.data[id];
    return action || {};
  },
};
