/**
 * https://happy-coding-clans.github.io/vue-easytable/#/en/doc/base/loading
 *
 * mixin:veLoading
 * 在组件中引入该 mixin 后，可以使用 showVeLoading() 和 closeVeLoading() 方法控制 loading 的显示和隐藏。
 * 需要给对应的元素绑定id="loading-container"
 * 绑定的元素如果有使用v-if，请替换为v-show,否则loading状态会覆盖整个页面
 *
 * @example
 *  引入 mixin
 * import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
 * <template>
 * <div id="loading-container">
 *  .....
 * </div>
 * <template>
 * 在组件中使用 mixin
 * export default {
 *   mixins: [veLoadingMixin],
 *   ...
 * }
 */
export default {
  data() {
    return {
      loadingInstance: null,
    };
  },
  methods: {
    showVeLoading() {
      this.loadingInstance.show();
    },
    closeVeLoading() {
      this.loadingInstance.close();
    },
  },
  mounted() {
    this.loadingInstance = this.$veLoading({
      target: document.querySelector('#loading-container'),
      name: 'flow',
    });
  },
  destroyed() {
    this.loadingInstance.destroy();
  },
};
