<template>
  <div class="wizard-body columns">
    <form @submit.prevent="handleSubmit">
      <!-- <content-type-picker
        :options="contentTypeGroup"
        :placeholder="$t('AI_ACTION_MANAGE_SETTINGS.SELECT.PLACEHOLDER')"
        :current-content-type="selectedContentType"
        @type-change="handleSelectType"
      /> -->
      <div v-if="!showLoading">
        <woot-input
          v-model.trim="record.name"
          class="medium-12 input-row"
          :label="$t('AI_ACTION_MANAGE_SETTINGS.FORM.ACTION_NAME')"
          :placeholder="$t('AI_ACTION_MANAGE_SETTINGS.FORM.PLACEHOLDER')"
        />
        <textarea
          v-model="record.text"
          :rows="15"
          type="text"
          :placeholder="
            $t('AI_ACTION_MANAGE_SETTINGS.FORM.TEXT_AREA_PLACEHOLDER')
          "
          :maxlength="maxTextareaLength"
        />
      </div>
      <woot-loading-state v-if="false" />
      <div class="medium-12 columns">
        <woot-submit-button
          :disabled="isButtonDisabled"
          :button-text="submitButtonText"
          :loading="submitInProgress"
        />
        <woot-button
          variant="clear"
          color-scheme="secondary"
          size=""
          @click.prevent="handleCancel"
        >
          {{ $t('AI_ACTION_MANAGE_SETTINGS.FORM.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
    <woot-confirm-modal
      ref="confirmDialog"
      :title="$t('AI_ACTION_MANAGE_SETTINGS.FORM.CONFIRM.TITLE')"
      :description="$t('AI_ACTION_MANAGE_SETTINGS.FORM.CONFIRM.MESSAGE')"
      :confirm-label="$t('AI_ACTION_MANAGE_SETTINGS.FORM.CONFIRM.YES')"
      :cancel-label="$t('AI_ACTION_MANAGE_SETTINGS.FORM.CONFIRM.NO')"
    />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../index';

export default {
  components: {},
  mixins: [alertMixin],
  validations: {
    record: {
      name: required,
      text: required,
    },
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    createdSuccess: {
      type: Boolean,
      default: false,
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    const formData = this.formData || {};
    const { name, text = '' } = formData;
    return {
      selectedContentType: this.$t(
        'AI_ACTION_MANAGE_SETTINGS.SELECT.CONTENT_TYPE'
      )[0],
      contentTypeGroup: this.$t(
        'AI_ACTION_MANAGE_SETTINGS.SELECT.CONTENT_TYPE'
      ),
      name,
      text,
      maxTextareaLength: 5000,
      showLoading: false,
      content: '',
      record: {
        name: '',
        text: '',
        created_at: '',
        content_type: 0,
      },
    };
  },
  computed: {
    isButtonDisabled() {
      return (
        this.submitInProgress ||
        this.record.text.length >= this.maxTextareaLength ||
        this.$v.record.name.$invalid
      );
    },
    responseId() {
      return Number(this.$route.params.responseId);
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.formData) this.record = this.formData;
    },
    handleSelectType(selectedType) {
      this.record.content_type = selectedType.type;
    },
    async handleCancel() {
      const ok = await this.$refs.confirmDialog.showConfirmation();
      if (ok) {
        router.replace({
          name: 'settings_ai_action_manage_list',
        });
      }
    },
    resetForm() {
      if (this.createdSuccess) {
        this.record = {
          name: '',
          text: '',
          created_at: '',
          content_type: null,
        };
      }
    },
    async handleSubmit() {
      const { name, content_type, text, id } = this.record;
      const formData = {
        id,
        name,
        text,
        content_type,
      };
      await this.onSubmit(formData);
      await this.resetForm();
    },
  },
};
</script>

<style lang="scss" scoped>
.input-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-one) 0 var(--space-medium) 0;
  :nth-child(1) {
    flex-shrink: 0;
    flex-grow: 1;
    font-size: var(--font-size-default);
    margin-right: var(--space-one);
  }
  :nth-child(2) {
    margin: 0;
    height: var(--space-large);
  }
}
</style>
