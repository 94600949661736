import { render, staticRenderFns } from "./AiConfig.vue?vue&type=template&id=f2acac42&scoped=true&"
import script from "./AiConfig.vue?vue&type=script&lang=js&"
export * from "./AiConfig.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2acac42",
  null
  
)

export default component.exports