<template>
  <div class="wizard-body column content-box">
    <form @submit.prevent="handleSubmit">
      <h3>{{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.HEADER') }}</h3>
      <div class="small-4 columns">
        <p>{{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.ROW_1') }}</p>
        <p>{{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.ROW_2') }}</p>
        <p>{{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.ROW_3') }}</p>
        <p>
          {{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.ROW_4') }}
        </p>
        <p>{{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.ROW_5') }}</p>
        <p class="input-tip small-8 columns">
          {{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.EXAMPLE_QUESTION') }}
          <br />
          {{ $t('FAQ_MANAGE_SETTINGS.DESCRIPTION.EXAMPLE_ANSWER') }}
        </p>
      </div>
      <div>
        <woot-input
          v-model.trim="title"
          class="medium-12 input-row"
          :label="$t('FAQ_MANAGE_SETTINGS.FORM.FAQ_NAME')"
          :placeholder="$t('FAQ_MANAGE_SETTINGS.FORM.PLACEHOLDER')"
        />
        <textarea
          v-model="content"
          :placeholder="$t('FAQ_MANAGE_SETTINGS.FORM.TEXT_AREA_PLACEHOLDER')"
          :rows="15"
          type="text"
        />
      </div>
      <div class="medium-12 columns">
        <woot-submit-button
          :disabled="isButtonDisabled"
          :button-text="submitButtonText"
          :loading="submitInProgress"
        />
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { STORY_CONTENT_TYPE } from 'dashboard/constants';
import { required } from 'vuelidate/lib/validators';
export default {
  mixins: [alertMixin],
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const formData = this.formData || {};
    const { title, steps = [] } = formData;

    return {
      title,
      steps,
      content: '',
      result: [],
      showLoading: false,
    };
  },
  computed: {
    isButtonDisabled() {
      return (
        this.$v.content.$invalid ||
        this.$v.title.$invalid ||
        this.submitInProgress
      );
    },
    storyId() {
      return Number(this.$route.params.storyId);
    },
  },
  validations: {
    title: {
      required,
    },
    content: {
      required,
    },
  },
  created() {
    if (this.formData) {
      this.init();
    }
  },
  methods: {
    init() {
      const { steps } = this;
      this.content = this.parseStepsToQA(steps);
    },
    // 将steps数组转换为QA
    parseStepsToQA(steps) {
      let result = '';
      for (let i = 0; i < steps.length; i += 2) {
        if (steps[i].ask && steps[i + 1].reply) {
          result += 'Q:' + steps[i].ask + '\nA:' + steps[i + 1].reply + '\n';
        } else {
          // 如果数据结构不符合要求，返回空字符串
          return '';
        }
      }
      return result.trim();
    },
    // 将QA转换为steps数组
    parseQAToSteps(content) {
      const regex = /^Q:(.*)\nA:(.*)$/;
      const lines = content.trim().split('\n');
      const result = [];
      for (let i = 0; i < lines.length; i += 2) {
        if (!regex.test(lines[i] + '\n' + lines[i + 1])) {
          this.showAlert(
            this.$t('FAQ_MANAGE_SETTINGS.API.CHECK_CONTENT_ERROR_MESSAGE')
          );
          throw new Error(
            this.$t('FAQ_MANAGE_SETTINGS.API.CHECK_CONTENT_ERROR_MESSAGE')
          );
        }
        const q = { ask: lines[i].substring(2) };
        const a = { reply: lines[i + 1].substring(2) };
        result.push(q);
        result.push(a);
      }
      this.result = result;
    },
    async handleSubmit() {
      if (!this.content.trim()) {
        this.showAlert(
          this.$t('FAQ_MANAGE_SETTINGS.API.EMPTY_CONTENT_ERROR_MESSAGE')
        );
        return;
      }
      this.parseQAToSteps(this.content);
      const { storyId, title, result } = this;
      const formData = {
        id: storyId,
        title,
        steps: result,
        content_type: STORY_CONTENT_TYPE.FAQ,
      };
      this.onSubmit(formData);
      this.result = [];
    },
  },
};
</script>
<style lang="scss" scoped>
.input-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-one) 0 var(--space-medium) 0;
  :nth-child(1) {
    flex-shrink: 0;
    flex-grow: 1;
    font-size: var(--font-size-default);
    margin-right: var(--space-one);
  }
  :nth-child(2) {
    margin: 0;
    height: var(--space-large);
  }
}
.input-tip {
  top: 0;
  left: 0;
  padding: 5px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: var(--font-size-small);
  color: #999;
}
</style>
