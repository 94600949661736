<template>
  <div v-show="false">
    <input
      ref="selectFileDom"
      type="file"
      :accept="fileType"
      :multiple="multiple"
      @change="handleFileSelection"
    />
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    fileType: {
      type: String,
      default: '',
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    onSelect: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      msg: 'hello',
    };
  },
  methods: {
    handleFileSelection(event) {
      const files = event.target.files;
      this.onSelect(files);
    },
    onOpen() {
      this.$refs.selectFileDom.click();
    },
  },
};
</script>
<style lang="scss" scoped></style>
