<template>
  <document-form
    :on-submit="addDocument"
    :submit-in-progress="showLoading"
    :created-success="isCreatedSuccess"
    :submit-button-text="buttonText"
  />
</template>

<script>
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';
import documentForm from '../documentForm';
export default {
  components: {
    documentForm,
  },
  mixins: [alertMixin],
  data() {
    return { isCreatedSuccess: false, showLoading: false };
  },
  computed: {
    buttonText() {
      return this.$t('KNOWLEDGE_BASE_SETTINGS.CREATE_FLOW.CREATE.BUTTON_TEXT');
    },
  },
  methods: {
    async addDocument(data) {
      this.showLoading = true;
      try {
        const document = await this.$store.dispatch(
          'aiStoryManage/createStory',
          {
            ...data,
          }
        );
        if (document) {
          this.showAlert(
            this.$t('KNOWLEDGE_BASE_SETTINGS.CREATE_FLOW.API.SUCCESS_MESSAGE')
          );
          this.showLoading = false;
          router.replace({
            name: 'settings_knowledge_base_list',
          });
        }
      } catch (error) {
        this.showAlert(
          this.$t('KNOWLEDGE_BASE_SETTINGS.CREATE_FLOW.API.ERROR_MESSAGE')
        );
        this.showLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
