<template>
  <div class="column content-box">
    <h1>{{ msg }}</h1>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      msg: 'hello',
    };
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
