/* eslint arrow-body-style: 0 */
import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';
import AiStoriesHome from './index';
import CreateStepWrap from './Create/index.vue';
import EditStepWrap from './Edit/index.vue';
import CreateStory from './Create/CreateStory.vue';
import EditStory from './Edit/EditStory';
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/ai-story-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_ai_story_manage_list';
        return {
          headerTitle: 'AI_STORY_MANAGE_SETTINGS.HEADER',
          headerButtonText: 'AI_STORY_MANAGE_SETTINGS.NEW_STORY',
          icon: 'note-book',
          newButtonRoutes: ['settings_ai_story_manage_new'],
          showBackButton,
          keepAlive: false,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_ai_story_manage',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_ai_story_manage_list',
          component: AiStoriesHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'settings_ai_story_manage_new',
              component: CreateStory,
              roles: ['administrator'],
            },
          ],
        },
        {
          path: ':storyId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_ai_story_manage_edit',
              component: EditStory,
              roles: ['administrator'],
            },
          ],
        },
      ],
    },
  ],
};
