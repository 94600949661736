import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  intentList: [],
  dialogIntentList: [],
  count: 0,
  uiFlags: {
    isFetching: false,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isFetchingItem: false,
  },
  importCompleted: false,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
