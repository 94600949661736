export const getters = {
  getIntents($state) {
    const Intents = Object.values($state.intentList).sort(
      (a, b) => b.id - a.id
    );
    Intents.forEach(Intent => {
      Intent.examples = Intent.examples?.sort((a, b) => b.id - a.id);
    });
    return Intents;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
  getIntent: $state => id => {
    const intent = $state.intentList[id];
    return intent || {};
  },
  getCount: $state => {
    return $state.count;
  },
  getDialogIntents($state) {
    return Object.values($state.dialogIntentList).sort((a, b) => b.id - a.id);
  },
  getGptImportedIntentStatus($state) {
    return $state.importCompleted;
  },
};
