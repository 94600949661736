<template>
  <div class="column content-box">
    <woot-loading-state
      v-if="uiFlags.isFetching"
      :message="$t('FAQ_MANAGE_SETTINGS.LOADING')"
    />
    <div v-else>
      <empty-state
        v-if="!faqList.length"
        :title="$t('FAQ_MANAGE_SETTINGS.EMPTY_DATA')"
      />
    </div>
    <div v-show="canRenderTable" id="loading-container">
      <ve-table
        max-height="calc(100vh - 200px)"
        :columns="columns"
        :table-data="faqList"
        :border-x="true"
        :border-y="true"
        row-key-field-name="id"
        :cell-selection-option="cellSelectionOption"
      />
      <ve-pagination
        class="ve-pagination"
        :total="stories.count"
        :page-index="pageIndex"
        :layout="['total', 'prev', 'pager', 'next', 'jumper']"
        @on-page-number-change="onPageChange"
      />
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('FAQ_MANAGE_SETTINGS.DELETE.CONFIRM.TITLE')"
      :message="$t('FAQ_MANAGE_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import router from '../../../index';
import { VePagination } from 'vue-easytable';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import storyMixin from 'dashboard/mixins/storyMixin';
import { STORY_CONTENT_TYPE } from 'dashboard/constants';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
export default {
  components: {
    VeTable,
    EmptyState,
    VePagination,
  },
  mixins: [accountMixin, alertMixin, storyMixin, veLoadingMixin],

  data() {
    return {
      pageIndex: 1,
      faqData: [],
      showDeleteConfirmationPopup: false,
      selectedFaq: {},
      cellSelectionOption: {
        enable: false,
      },
      columns: [
        {
          field: 'id',
          key: 'id',
          title: this.$t('FAQ_MANAGE_SETTINGS.LIST_HEADER.ID'),
          align: 'left',
          width: '5%',
        },
        {
          field: 'title',
          key: 'title',
          title: this.$t('FAQ_MANAGE_SETTINGS.LIST_HEADER.NAME'),
          align: 'left',
          width: 180,
          ellipsis: {
            showTitle: true,
            lineClamp: 3,
          },
        },
        {
          field: 'steps',
          key: 'steps',
          title: this.$t('FAQ_MANAGE_SETTINGS.LIST_HEADER.FAQ_EXAMPLE'),
          align: 'left',
          width: '',
          ellipsis: {
            showTitle: false,
            lineClamp: 5,
          },
        },
        {
          field: '',
          key: 'e',
          title: this.$t('FAQ_MANAGE_SETTINGS.LIST_HEADER.ACTIONS'),
          width: 90,
          center: 'left',
          renderBodyCell: ({ row: stories }) => {
            return (
              <span>
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary
                  "
                  icon="edit"
                  class="edit-custom-view__button"
                  on-click={() => this.editRow(stories.id)}
                ></woot-button>
                &nbsp;
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="delete"
                  class="delete-custom-view__button"
                  on-click={() => this.openDeletePopup(stories)}
                ></woot-button>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      stories: 'aiStoryManage/getStories',
      uiFlags: 'aiStoryManage/getUIFlags',
    }),
    faqList() {
      let storiesArr = this.stories.data;
      if (storiesArr) {
        let newStoriesArr = storiesArr.map(story => ({
          id: story.id,
          title: story.title,
          steps: `${this.formatStepsToText(story.steps)}`,
        }));
        return newStoriesArr;
      }
      return {};
    },
    canRenderTable() {
      return (
        this.faqList && this.faqList.length > 0 && !this.uiFlags.isFetching
      );
    },
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('FAQ_MANAGE_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedFaq.title
      }`;
    },
    deleteRejectText() {
      return `${this.$t('FAQ_MANAGE_SETTINGS.DELETE.CONFIRM.NO')} ${
        this.selectedFaq.title
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedFaq.title}?`;
    },
  },
  created() {
    this.$store.dispatch('aiStoryManage/getStories', {
      page: 1,
      contentType: STORY_CONTENT_TYPE.FAQ,
    });
  },

  methods: {
    onPageChange(page) {
      this.pageIndex = page;
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('aiStoryManage/getStories', {
        page,
        contentType: STORY_CONTENT_TYPE.FAQ,
      });
    },
    editRow(id) {
      router.push({
        path: this.addAccountScoping(`settings/faq-manage/${id}/edit`),
        params: {
          page: 'edit',
          id: id,
        },
      });
    },
    openDeletePopup(faq) {
      this.showDeleteConfirmationPopup = true;
      this.selectedFaq = faq;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteFaqById(this.selectedFaq.id);
    },
    async deleteFaqById(id) {
      this.showVeLoading();
      try {
        await this.$store.dispatch('aiStoryManage/deleteStory', id);
        await this.$store.dispatch('aiStoryManage/getStories', {
          page: this.pageIndex,
          contentType: STORY_CONTENT_TYPE.FAQ,
        });
        if (!this.stories.data.length && this.pageIndex > 1) {
          this.onPageChange(1);
        }
        this.showAlert(
          this.$t('FAQ_MANAGE_SETTINGS.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(this.$t('FAQ_MANAGE_SETTINGS.DELETE.API.ERROR_MESSAGE'));
      } finally {
        this.closeVeLoading();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ve-pagination {
  margin-top: var(--space-one);
  display: flex;
  justify-content: flex-end;
  background-color: transparent !important;
}
</style>
