export const SET_MODELS = 'SET_MODELS';
export const START_TRAIN = 'START_TRAIN';
export const SET_MODELS_COUNT = 'SET_MODELS_COUNT';
export const SET_MODELS_UI_FLAG = 'SET_MODELS_UI_FLAG';
export const SET_TRAINING_IN_PROGRESS = 'SET_TRAINING_IN_PROGRESS';
export const DEPLOY_MODELS = 'DEPLOY_MODELS';
export const DELETE_MODEL = 'DELETE_MODEL';
export const CLEAR_MODELS = 'CLEAR_MODELS';

/**
 * @param {number} status
 *  0 已禁用/删除
 *  1 训练错误或者无效
 *  2 已生成
 *  3 训练中
 *  4 训练完成
 */
export const TRAIN_STATUS = {
  DISABLED: 0,
  ERROR: 1,
  GENERATED: 2,
  TRAINING: 3,
  SUCCESS: 4,
};
/**
 * @param {number} deploy
 *  0 未发布/未部署
 *  1 发布者
 *  2 已发布
 */
export const DEPLOY_STATUS = {
  NOT_RELEASED: 0,
  RELEASE_IN: 1,
  RUNNING: 2,
};
