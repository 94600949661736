export const getSelectedChatConversation = ({
  allConversations,
  selectedChatId,
}) =>
  allConversations.filter(conversation => conversation.id === selectedChatId);

export const getters = {
  getUIFlags: $state => {
    return $state.uiFlags;
  },
  getStories: $state => {
    const data = Object.values($state.stories.data).sort((a, b) => b.id - a.id);
    const count = $state.stories.count;
    return { data, count };
  },
  getStory: $state => id => {
    const story = $state.stories.data[id];
    return story || {};
  },
  getMessagesLoaded: $state => {
    return $state.messagesLoaded;
  },
  getAllConversations: $state => {
    const all = Object.values($state.allConversations);
    return all;
  },
  getGptTurboRecord: $state => {
    return $state.gptTurboRecord.messages;
  },
  getCounters: $state => {
    return $state.counters;
  },
};
