import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';
import FaqHome from './Index.vue';
import AddFAQ from './Add/AddFAQ.vue';
import EditFAQ from './Edit/EditFAQ.vue';
import AddStepWrap from './Add/Index.vue';
import EditStepWrap from './Edit/Index.vue';
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/faq-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_faq_manage_list';
        return {
          headerTitle: 'FAQ_MANAGE_SETTINGS.HEADER',
          icon: 'book-question',
          headerButtonText: 'FAQ_MANAGE_SETTINGS.NEW_FAQ',
          newButtonRoutes: ['settings_faq_manage_new'],
          showBackButton,
          keepAlive: false,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_faq_manage',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_faq_manage_list',
          component: FaqHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: AddStepWrap,
          children: [
            {
              path: '',
              name: 'settings_faq_manage_new',
              component: AddFAQ,
              roles: ['administrator'],
            },
          ],
        },
        {
          path: ':storyId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_faq_manage_edit',
              component: EditFAQ,
              roles: ['administrator'],
            },
          ],
        },
      ],
    },
  ],
};
