<template>
  <div class="flex-1 overflow-auto p-4">
    <story-list />
  </div>
</template>

<script>
import StoryList from './StoryList';
export default {
  components: {
    StoryList,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
