<template>
  <modal :show.sync="show" size="medium" :on-close="onClose">
    <div class="column content-box">
      <woot-modal-header :header-title="headerTitle" />
      <form class="" @submit.prevent="submitResult">
        <div class="conversation-panel">
          <div>
            <label
              class="date-hours-subtitle
          "
            >
              {{ $t('AI_CONFIGS_SETTINGS.MODAL.SUBTITLE') }}
            </label>
            <custom-config-day
              v-for="timeSlot in timeSlots"
              :key="timeSlot.day"
              :day-name="dayNames[timeSlot.day]"
              :time-slot="timeSlot"
              :default-options="deepOptions"
              @update="data => onSlotUpdate(timeSlot.day, data)"
              @updateOptions="onOptionsUpdate"
              @removeTimeRange="onRemoveTimeRange"
              @clear="clearDay(timeSlot.day)"
            />
          </div>
          <div class="input-reply">
            <label>
              {{
                isNewBuilt
                  ? $t('AI_CONFIGS_SETTINGS.MODAL.ADD.LABEL')
                  : $t('AI_CONFIGS_SETTINGS.MODAL.EDIT.LABEL')
              }}
            </label>
            <textarea
              v-model.trim="utter_default.timeConfigText"
              class="medium-12"
              rows="4"
              type="text"
              :placeholder="$t('AI_CONFIGS_SETTINGS.MODAL.PLACEHOLDER')"
            />
          </div>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :button-text="buttonText"
              :disabled="hasError || showLoading"
              :loading="showLoading"
            />
            <button class="button clear" @click.prevent="onClose">
              {{ $t('AI_CONFIGS_SETTINGS.MODAL.CANCEL_BUTTON_TEXT') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Modal from 'dashboard/components/Modal.vue';
import CustomConfigDay from '../components/CustomConfigDay.vue';
import _cloneDeep from 'lodash/cloneDeep';
import _findIndex from 'lodash/findIndex';
import _some from 'lodash/some';
import _isEmpty from 'lodash/isEmpty';
import {
  timeZoneOptions,
  formatTime,
  formatTimeToSecond,
  getWeekday,
} from '../helpers/customHour';
import alertMixin from 'shared/mixins/alertMixin';
import { nanoid } from 'nanoid';

export default {
  components: {
    Modal,
    CustomConfigDay,
  },
  validations: {
    utter_default: {
      timeConfigText: { required },
    },
  },
  mixins: [alertMixin],
  props: {
    modelConfigs: {
      type: Object,
      default: () => {},
    },
    onClose: {
      type: Function,
      default: () => {},
    },
    isNewBuilt: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dayNames: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday',
      },
      defaultTimeSlot: [
        {
          day: 0,
          from: '',
          to: '',
          options: [],
          valid: false,
          openAllDay: false,
        },
        {
          day: 1,
          from: '',
          to: '',
          options: [],
          valid: false,
          openAllDay: false,
        },
        {
          day: 2,
          from: '',
          to: '',
          options: [],
          valid: false,
          openAllDay: false,
        },
        {
          day: 3,
          from: '',
          to: '',
          options: [],
          valid: false,
          openAllDay: false,
        },
        {
          day: 4,
          from: '',
          to: '',
          options: [],
          valid: false,
          openAllDay: false,
        },
        {
          day: 5,
          from: '',
          to: '',
          options: [],
          valid: false,
          openAllDay: false,
        },
        {
          day: 6,
          from: '',
          to: '',
          options: [],
          valid: false,
          openAllDay: false,
        },
      ],
      utter_default: {},
      timeSlots: [],
      show: true,
      showLoading: false,
      cache: {
        timeConfigText: '',
      },
      newOption: {
        Sunday: [],
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
      },
    };
  },
  computed: {
    deepOptions() {
      return _cloneDeep(this.utter_default.options);
    },
    headerTitle() {
      const title = this.isNewBuilt
        ? this.$t('AI_CONFIGS_SETTINGS.MODAL.ADD.HEADER')
        : this.$t('AI_CONFIGS_SETTINGS.MODAL.EDIT.HEADER');
      return title;
    },
    buttonText() {
      const text = this.isNewBuilt
        ? this.$t('AI_CONFIGS_SETTINGS.MODAL.ADD.SUBMIT_ADD')
        : this.$t('AI_CONFIGS_SETTINGS.MODAL.EDIT.SUBMIT_UPDATE');
      return text;
    },
    timeZones() {
      return timeZoneOptions().map(option => option.value);
    },
    hasValidSlot() {
      return _some(
        this.timeSlots,
        obj => obj.valid === true && _isEmpty(obj.options)
      );
    },
    hasError() {
      return this.timeSlots.filter(slot => slot.from && !slot.valid).length > 0;
    },
  },
  created() {
    this.init();
    this.setDefault();
  },

  methods: {
    setDefault() {
      if (!this.isNewBuilt) {
        const { options, replyKey } = this.utter_default;
        const filteredFallback = options.filter(
          o => o.replyKey === replyKey
        )[0];
        const daysOfWeek = Object.keys(filteredFallback).filter(
          key => key !== 'replyKey' && key !== 'text'
        );
        for (let i = 0; i < daysOfWeek.length; i += 1) {
          const day = daysOfWeek[i];
          const timeSlots = filteredFallback[day];
          const slotIndex = _findIndex(this.timeSlots, { day: i });
          for (let j = 0; j < timeSlots.length; j += 1) {
            const timeSlot = timeSlots[j];
            const formattedOption = {
              timePeriodKey: timeSlot.timePeriodKey,
              day: slotIndex,
              from: formatTime(timeSlot.from),
              to: formatTime(timeSlot.to),
              valid: true,
            };
            this.timeSlots[slotIndex].options.push(formattedOption);
            this.newOption[day].push(formattedOption);
            if (this.timeSlots[slotIndex].options.length) {
              this.timeSlots[slotIndex].valid = true;
            }
          }
        }
      }
      // this.onSlotUpdate();
    },
    clearDay(day) {
      const week = getWeekday(day);
      // 如果是编辑，原本的options清空
      if (this.utter_default.replyKey && !this.isNewBuilt) {
        const foundObject = this.utter_default.options.find(
          o => o.replyKey === this.utter_default.replyKey
        );
        foundObject[week] = [];
      }
      this.newOption[week] = [];
    },
    onRemoveTimeRange(segment) {
      if (this.utter_default.replyKey) {
        const foundObject = this.utter_default.options.find(
          o => o.replyKey === this.utter_default.replyKey
        );
        const newOptions = {
          Sunday: [],
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: [],
        };
        let week;
        segment.forEach(o => {
          if (typeof o.day === 'number') {
            week = this.dayNames[o.day];
          } else {
            week = o.day;
          }
          newOptions[week].push({
            timePeriodKey: o.timePeriodKey,
            from: formatTimeToSecond(o.from),
            to: formatTimeToSecond(o.to),
          });
          // 每次删除清空对应的星期，然后重新赋值
          foundObject[week] = [];
          foundObject[week] = newOptions[week];
        });
      } else {
        let week;
        const filtered = segment.map(se => {
          const day = se.day;
          const timePeriodKey = se.timePeriodKey;
          if (typeof day === 'number') {
            week = getWeekday(day);
          } else {
            week = day;
          }
          return {
            timePeriodKey,
            hours: se.hours,
            from: formatTimeToSecond(se.from),
            to: formatTimeToSecond(se.to),
            valid: true,
          };
        });

        this.newOption[week] = filtered;
      }
    },
    init() {
      this.timeSlots = this.defaultTimeSlot;
      if (this.modelConfigs.utter_default) {
        this.utter_default = this.modelConfigs.utter_default;
      }
      if (this.isNewBuilt) {
        this.utter_default.timeConfigText = '';
        this.utter_default.replyKey = '';
      } else {
        this.cache.timeConfigText = this.utter_default.timeConfigText;
        this.cache = Object.assign({}, this.utter_default);
      }
    },
    onOptionsUpdate(options) {
      // 有replyKey的情况下的更新逻辑
      if (this.utter_default.replyKey) {
        const foundObject = this.utter_default.options.find(
          o => o.replyKey === this.utter_default.replyKey
        );
        let week;
        const newOptions = {
          Sunday: [],
          Monday: [],
          Tuesday: [],
          Wednesday: [],
          Thursday: [],
          Friday: [],
          Saturday: [],
        };
        options.forEach(o => {
          if (typeof o.day === 'string') {
            week = o.day;
          } else {
            week = this.dayNames[o.day];
          }
          newOptions[week].push({
            timePeriodKey: o.timePeriodKey,
            from: formatTimeToSecond(o.from),
            to: formatTimeToSecond(o.to),
          });
          // 每次添加清空对应的星期，然后重新赋值
          foundObject[week] = [];
          foundObject[week] = newOptions[week];
        });
      } else {
        const day = options.length > 0 ? options[0].day : null;
        if (day !== null) {
          this.newOption[day] = [];
        }
        options.forEach(o => {
          this.newOption[o.day].push({
            timePeriodKey: o.timePeriodKey,
            from: formatTimeToSecond(o.from),
            to: formatTimeToSecond(o.to),
          });
        });
      }
    },
    onSlotUpdate(slotIndex, slotData) {
      this.timeSlots = this.timeSlots.map(item =>
        item.day === slotIndex ? slotData : item
      );
    },
    addFallbackSchedule() {
      const hasDuplicate = this.utter_default.options.some(option => {
        return option.text === this.utter_default.timeConfigText;
      });
      if (!hasDuplicate) {
        this.newOption.replyKey = nanoid();
        this.newOption.text = this.utter_default.timeConfigText;
        this.utter_default.options.push(this.newOption);
      } else {
        this.showAlert(
          this.$t('AI_CONFIGS_SETTINGS.MODAL.API.EXIST_RESPONSE_MESSAGE')
        );
        throw new Error(
          '🚀 ~ file: TimeBasedFallbackModal.vue:440 ~ submitResult ~ '
        );
      }
    },
    updateFallbackSchedule() {
      const hasDuplicate = this.utter_default.options.some(option => {
        return (
          option.text === this.utter_default.timeConfigText &&
          option.replyKey !== this.utter_default.replyKey
        );
      });
      const existingOption = this.utter_default.options.find(
        option => option.text === this.cache.timeConfigText
      );

      if (existingOption && !hasDuplicate) {
        this.$emit(
          'updateTable',
          this.utter_default.timeConfigText,
          existingOption.replyKey
        );
      } else {
        this.showAlert(
          this.$t('AI_CONFIGS_SETTINGS.MODAL.API.EXIST_RESPONSE_MESSAGE')
        );
        throw new Error(
          '🚀 ~ file: TimeBasedFallbackModal.vue:415 ~ submitResult ~ '
        );
      }
    },
    async handleUpdatedConfigs(utter_default) {
      this.showLoading = true;
      try {
        await this.$store.dispatch('aiConfigs/updateConfig', utter_default);
        this.showAlert(
          this.$t('AI_CONFIGS_SETTINGS.MODAL.API.SUCCESS_MESSAGE')
        );
        this.showLoading = false;
        this.onClose();
      } catch (error) {
        this.showLoading = false;
        this.showAlert(this.$t('AI_CONFIGS_SETTINGS.MODAL.API.ERROR_MESSAGE'));
        this.onClose();
      }
    },
    submitResult() {
      if (!this.utter_default.timeConfigText) {
        this.showAlert(
          this.$t('AI_CONFIGS_SETTINGS.MODAL.API.EMPTY_RESPONSE_MESSAGE')
        );
        return;
      }
      if (this.hasValidSlot) {
        this.showAlert(
          this.$t('AI_CONFIGS_SETTINGS.MODAL.API.EMPTY_DAY_OPTIONS')
        );
        return;
      }
      // 更新
      if (this.utter_default.replyKey) {
        this.updateFallbackSchedule();
      }
      // 新增
      if (this.isNewBuilt && !this.utter_default.replyKey) {
        this.addFallbackSchedule();
      }
      delete this.utter_default.timeConfigText;
      delete this.utter_default.replyKey;
      this.handleUpdatedConfigs({
        utter_default: this.utter_default,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.date-hours-subtitle {
  font-size: var(--font-size-default);
}
.input-reply {
  :nth-child(1) {
    margin: var(--font-size-mini) 0 var(--space-micro) 0;
  }
}
</style>
