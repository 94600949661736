<template>
  <intent-form
    :on-submit="createIntent"
    :submit-in-progress="showLoading"
    :created-success="isCreatedSuccess"
    :submit-button-text="$t('INTENT_MANAGE_SETTINGS.FORM.CREATE')"
  />
</template>

<script>
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';
import IntentForm from '../IntentForm';

export default {
  components: {
    IntentForm,
  },
  mixins: [alertMixin],
  data() {
    return { isCreatedSuccess: false, showLoading: false };
  },
  methods: {
    async createIntent(data) {
      try {
        this.showLoading = true;
        const intent = await this.$store.dispatch('intentManage/create', {
          intentInfo: { ...data },
        });
        if (intent) {
          this.showAlert(
            this.$t('INTENT_MANAGE_SETTINGS.CREATE_FLOW.API.SUCCESS_MESSAGE')
          );
          this.isCreatedSuccess = true;
          this.showLoading = false;
          router.replace({
            name: 'settings_intent_manage_list',
          });
        }
      } catch (error) {
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.CREATE_FLOW.API.ERROR_MESSAGE')
        );
        this.isCreatedSuccess = false;
        this.showLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped></style>
