<template>
  <div class="wizard-body columns">
    <form class="" @submit.prevent="handleSubmit">
      <woot-input
        v-model.trim="title"
        :label="$t('AI_STORY_MANAGE_SETTINGS.FORM.STORY_EXAMPLE_NAME')"
        :placeholder="$t('AI_STORY_MANAGE_SETTINGS.FORM.PLACEHOLDER')"
        class="medium-12 input-row"
        :class="{ error: $v.title.$error }"
        @keydown.enter.prevent
        @input="$v.title.$touch"
      />
      <div
        id="loading-container"
        ref="scrollArea"
        class="conversation-panel message-wrapper"
      >
        <story-message
          v-for="message in processedMessages"
          :key="message.id"
          :data="message"
          :delete-message="handleDeleteMessage"
        />
      </div>
      <!-- 暂时隐藏 -->
      <!-- <div>
        <label>{{ $t('AI_STORY_MANAGE_SETTINGS.RADIO.LABEL') }}</label>
        <input-radio-group
          name="response-types"
          :items="responseTypes"
          :action="handleTypeChange"
        />
      </div> -->
      <div class="add-reply-group">
        <label>{{ $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.QUESTION') }}</label>
        <textarea
          v-model.trim="messages.ask.content"
          class="medium-3 fixed-input"
          :placeholder="
            $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.QUESTION_PLACEHOLDER')
          "
          :rows="'1'"
        />

        <label>{{ $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.ANSWER') }}</label>

        <textarea
          v-model.trim="messages.reply.content"
          class="medium-3 fixed-input"
          :placeholder="
            $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.ANSWER_PLACEHOLDER')
          "
          :rows="'1'"
        />
        <woot-button
          icon="add-circle"
          variant="clear"
          color-scheme="secondary"
          @click.prevent="handleAddMessage"
        >
          {{ $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.BUTTON_TEXT') }}
        </woot-button>
      </div>

      <div class="medium-12 columns fixed-bottom">
        <woot-submit-button
          :button-text="submitButtonText"
          :disabled="isButtonDisabled"
          :loading="showLoading"
        />
        <woot-button
          variant="clear"
          color-scheme="secondary"
          size=""
          @click.prevent="handleCancel"
        >
          {{ $t('AI_STORY_MANAGE_SETTINGS.FORM.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
    <woot-confirm-modal
      ref="confirmDialog"
      :title="$t('AI_STORY_MANAGE_SETTINGS.FORM.CONFIRM.TITLE')"
      :description="$t('AI_STORY_MANAGE_SETTINGS.FORM.CONFIRM.MESSAGE')"
      :confirm-label="$t('AI_STORY_MANAGE_SETTINGS.FORM.CONFIRM.YES')"
      :cancel-label="$t('AI_STORY_MANAGE_SETTINGS.FORM.CONFIRM.NO')"
    />
  </div>
</template>

<script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import StoryMessage from './components/StoryMessage.vue';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import _cloneDeep from 'lodash/cloneDeep';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import storyMixin from 'dashboard/mixins/storyMixin';
import InputRadioGroup from './components/InputRadioGroup.vue';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
import { RESPONSE_CONTENT_TYPE } from '../../../../constants';
import { mapGetters } from 'vuex';
import router from '../../../index';

export default {
  components: {
    WootSubmitButton,
    StoryMessage,
    InputRadioGroup,
  },
  validations: {
    title: { required },
    processedMessages: {
      required,
    },
  },
  mixins: [alertMixin, storyMixin, veLoadingMixin],
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    createdSuccess: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    const formData = this.formData || {};
    const { title, steps = [] } = formData;
    return {
      title,
      steps,
      processedMessages: [],
      showLoading: false,
      currentSelectedType: RESPONSE_CONTENT_TYPE.TEXT_RESPONSE,
      messages: {
        ask: {
          id: null,
          content: '',
          message_type: MESSAGE_TYPE.INCOMING,
          created_at: 0,
        },
        reply: {
          id: null,
          content: '',
          message_type: MESSAGE_TYPE.OUTGOING,
          created_at: 1,
          content_type: null,
        },
      },
      example: '',
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: false,
      },
    };
  },

  computed: {
    ...mapGetters({
      isReadyJumpToIntentEditor: 'intentManage/getUIFlags',
    }),
    storyId() {
      return Number(this.$route.params.storyId);
    },
    isButtonDisabled() {
      return (
        this.$v.title.$invalid ||
        this.$v.processedMessages.$invalid ||
        this.showLoading
      );
    },
    responseTypes() {
      const CONTENT_TYPE_KEYS = {
        text_response: RESPONSE_CONTENT_TYPE.TEXT_RESPONSE,
        html_response: RESPONSE_CONTENT_TYPE.HTML_RESPONSE,
        image_response: RESPONSE_CONTENT_TYPE.IMAGE_RESPONSE,
        custom: RESPONSE_CONTENT_TYPE.CUSTOM,
      };
      return Object.keys(CONTENT_TYPE_KEYS).map(key => ({
        type: CONTENT_TYPE_KEYS[key],
        name: key,
        checked: this.currentSelectedType === CONTENT_TYPE_KEYS[key],
        title: this.$t(`AI_STORY_MANAGE_SETTINGS.RADIO.CONTENT_TYPE.${key}`),
      }));
    },
    // 格式化后的steps
    formatStepsToMessage() {
      const { storyId } = this.$route.params;
      const stories = this.$store.getters['aiStoryManage/getStory'](storyId);

      const formatStep = this.addResponseToAsk(stories)?.steps?.map(step => {
        return {
          id: step.id,
          content: step.ask || step.reply,
          // ask才有intent
          intent: step?.intent,
          action: step?.response,
          // reply才有content_type
          content_type: step?.content_type,
          created_at: 0,
          message_type: step.ask
            ? MESSAGE_TYPE.INCOMING
            : MESSAGE_TYPE.OUTGOING,
        };
      });

      return formatStep;
    },
    cloneMessages() {
      const messages = Object.values(this.messages);
      const cloneContent = _cloneDeep(
        messages.map(message => {
          const timestamp = Date.now();
          const random = Math.floor(Math.random() * 1000000);
          const id = `${timestamp}${random}`;
          return { ...message, id };
        })
      );
      return cloneContent;
    },
    formatMessages() {
      return this.processedMessages.map(
        ({ content, message_type, content_type }) => {
          let formattedContent = content
            .trim()
            .split('\n')
            .join('');
          return message_type === MESSAGE_TYPE.INCOMING
            ? { ask: formattedContent }
            : { reply: formattedContent, content_type };
        }
      );
    },
  },
  watch: {
    isReadyJumpToIntentEditor({ isFetchingItem }) {
      if (isFetchingItem) {
        this.showVeLoading();
      } else {
        this.closeVeLoading();
      }
    },
    formData: {
      handler(newChange) {
        this.title = newChange.title;
        this.processedMessages = newChange.steps;
        this.processedMessages = this.formatStepsToMessage;
      },
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      if (this.formData) {
        this.processedMessages = this.formatStepsToMessage;
      }
    },
    addResponseToAsk(obj) {
      const steps = obj.steps;
      for (let i = 1; i < steps.length; i += 1) {
        if (steps[i].reply && steps[i].reply.length > 0) {
          steps[i - 1].response = {
            id: steps[i].response.id,
            name: steps[i].response.name,
            text: steps[i].reply,
            content_type: steps[i].content_type,
          };
        }
      }
      return obj;
    },
    handleTypeChange(newChange) {
      this.currentSelectedType = newChange.type;
    },
    async handleCancel() {
      const ok = await this.$refs.confirmDialog.showConfirmation();
      if (ok) {
        router.replace({
          name: 'settings_ai_story_manage_list',
        });
      }
    },
    resetForm() {
      if (this.createdSuccess) {
        this.title = '';
        this.processedMessages = [];
        this.$v.title.$reset();
        this.$v.processedMessages.$reset();
      }
    },
    handleAddMessage() {
      if (!this.messages.ask.content || !this.messages.reply.content) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.MODAL.API.ADD_EMPTY_MESSAGE')
        );
        return;
      }
      this.messages.reply.content_type = this.currentSelectedType;
      this.processedMessages.push(...this.cloneMessages);
      this.$nextTick(() => {
        const scrollArea = this.$refs.scrollArea;
        scrollArea.scrollTop = scrollArea.scrollHeight;
      });
      this.messages.ask.content = '';
      this.messages.reply.content = '';
    },
    handleDeleteMessage(id) {
      this.processedMessages = this.processedMessages.filter(
        msg => msg.id !== id
      );
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.shouldShowInvalidAlert()) {
        await this.showInvalidAlert();
        return;
      }
      this.showLoading = true;
      const { title, formatMessages } = this;
      await this.onSubmit({
        id: this.storyId,
        title,
        content_type: RESPONSE_CONTENT_TYPE.TEXT_RESPONSE,
        steps: formatMessages,
      });
      await this.resetForm();
      this.showLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.add-reply-group {
  display: flex;
  align-items: baseline;
  padding: var(--space-small) 0;
  > * {
    margin-right: var(--space-one);
    font-weight: var(--font-weight-medium);
  }
}
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  max-width: 800px;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
}

.message-wrapper {
  height: calc(100vh - 400px);
  overflow: auto;
  max-width: 800px;
}

.fixed-input {
  max-width: 300px;
}
.input-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin-bottom: var(--space-large);
  :nth-child(1) {
    flex-shrink: 0;
    flex-grow: 1;
    font-size: var(--font-size-default);
    margin-right: var(--space-one);
  }
  :nth-child(2) {
    margin: 0;
    height: var(--space-large);
  }
}
</style>
