import {
  SET_INTENT_UI_FLAG,
  CLEAR_INTENTS,
  SET_INTENTS,
  SET_INTENT_ITEM,
  EDIT_INTENT,
  DELETE_INTENT,
  SET_INTENTS_COUNT,
  SET_INTENTS_DIALOG,
  UPDATE_RESPONSE,
  SET_GPT_IMPORTED_INTENT_STATUS,
} from './types';
import GptIntentsApi from '../../../api/gptIntents';
import GptIntentExamplesApi from '../../../api/gptIntentExamples';
import GptIntentResponseApi from '../../../api/gptIntentResponse';
import GptTurboApi from '../../../api/GptTurbo';
import { formatUnixDate } from 'shared/helpers/DateHelper';
import { GPT_MODEL } from 'shared/constants/gptModel';
import { parseGptContent } from './helpers';
import _has from 'lodash/has';
export const actions = {
  create: async (
    { commit, dispatch },
    { intentInfo, isConversation = false }
  ) => {
    commit(SET_INTENT_UI_FLAG, { isCreating: true });
    try {
      const response = await GptIntentsApi.create(intentInfo);
      const intent = response.data;
      // // 如果是从会话界面创建的，就调用gpt生成一些例子
      // if (isConversation) {
      //   await dispatch('generateExamples', { intentInfo, intent });
      // }
      commit(SET_INTENT_ITEM, intent);
      return intent;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INTENT_UI_FLAG, { isCreating: false });
    }
  },
  getAll: async ({ commit }) => {
    commit(SET_INTENT_UI_FLAG, { isFetching: true });
    try {
      const { data: payload } = await GptIntentsApi.get();
      commit(CLEAR_INTENTS);
      if (payload.data.length === 0) {
        commit(SET_INTENT_UI_FLAG, { isFetching: false });
      } else {
        commit(SET_INTENTS, payload.data);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INTENT_UI_FLAG, { isFetching: false });
    }
  },
  get: async ({ commit }, { page = 1 } = {}) => {
    commit(SET_INTENT_UI_FLAG, { isFetching: true });
    try {
      const { data: payload } = await GptIntentsApi.page(page);
      commit(CLEAR_INTENTS);
      if (payload.data.length === 0) {
        commit(SET_INTENT_UI_FLAG, { isFetching: false });
      } else {
        commit(SET_INTENTS, payload.data);
        commit(SET_INTENTS_COUNT, payload.count);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INTENT_UI_FLAG, { isFetching: false });
    }
  },
  show: async ({ commit }, { id }) => {
    commit(SET_INTENT_UI_FLAG, { isFetchingItem: true });
    try {
      const response = await GptIntentsApi.show(id);
      commit(SET_INTENT_ITEM, response.data);
      commit(SET_INTENT_UI_FLAG, {
        isFetchingItem: false,
      });
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INTENT_UI_FLAG, {
        isFetchingItem: false,
      });
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    commit(SET_INTENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await GptIntentsApi.update(id, updateObj);
      commit(EDIT_INTENT, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INTENT_UI_FLAG, { isUpdating: false });
    }
  },
  delete: async ({ commit }, IntentId) => {
    try {
      await GptIntentsApi.delete(IntentId);
      commit(DELETE_INTENT, IntentId);
    } catch (error) {
      throw new Error(error);
    }
  },
  DialogIntentList: async ({ commit }, { page = 1 } = {}) => {
    try {
      const { data: payload } = await GptIntentExamplesApi.page(page);
      commit(SET_INTENTS_DIALOG, payload.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  createExample: async ({ commit, dispatch }, intentInfo) => {
    commit(SET_INTENT_UI_FLAG, { isCreating: true });
    try {
      // 调用GPT生成example
      // await dispatch('generateExamples', { intentInfo });
      const response = await GptIntentExamplesApi.create(intentInfo);
      const intent = response.data;
      commit(SET_INTENT_ITEM, intent);
      return intent;
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INTENT_UI_FLAG, { isCreating: false });
    }
  },
  /*  在会话界面的添加案例和创建意图共用这个函数进行example生成  */
  generateExamples: async (_, { intentInfo, intent = {} }) => {
    try {
      // 传入的intentInfo中如果有intent_id,说明是添加案例，没有的话说明是创建意图
      const hasSelectedIntentId = _has(intentInfo, 'intent_id');
      const example = hasSelectedIntentId
        ? intentInfo?.example
        : intentInfo?.examples[0]?.example;
      const askingTemplateForExamples = {
        model: GPT_MODEL.GPT35,
        temperature: 0.2,
        messages: [
          {
            role: 'user',
            content: `Give 10 examples in the same language with the similar idea of:${example}`,
          },
        ],
      };
      const { data } = await GptTurboApi.create(askingTemplateForExamples);
      const { content } = data[0].message;
      const lines = content.split('\n');
      // 过滤出问题并去掉序号
      lines.forEach(async item => {
        const filteredExample = item.replace(/^\d+\.\s*/, '');
        const intent_id = hasSelectedIntentId
          ? intentInfo.intent_id
          : intent.id;
        const examples = {
          intent_id,
          example: filteredExample,
        };
        await GptIntentExamplesApi.create(examples);
      });
    } catch (error) {
      throw new Error(error);
    }
  },
  updateResponse: async ({ commit }, { id, ...data }) => {
    commit(SET_INTENT_UI_FLAG, { isUpdating: true });
    try {
      const response = await GptIntentResponseApi.update(id, data);
      commit(UPDATE_RESPONSE, response.data);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_INTENT_UI_FLAG, { isUpdating: false });
    }
  },
  createIntentFromGptAnswer: async (
    { commit, dispatch },
    { productDescription, language }
  ) => {
    const questionsTotal = 5;
    const askingTemplate = {
      model: GPT_MODEL.GPT35,
      temperature: 0.2,
      messages: [
        {
          role: 'user',
          content: `I hope you can simulate user questions based on the product description I provided. I hope the questions you generate will closely match the product description I provided, please follow the product description I provided as closely as possible and generate ${questionsTotal} of the most similar questions without any unnecessary explanation. After you generate the questions, I will ask you questions based on your responses, and you must strictly adhere to my product description when responding. For this conversation, you only need to generate questions. Here is my product description:"${productDescription}"\nPlease write in ${language} language.`,
        },
      ],
    };
    const gptRequestTemplate = {
      model: GPT_MODEL.GPT35,
      temperature: 0.2,
      messages: [],
    };
    const firstRoundUserQuestions = {
      role: 'user',
      content: ``,
    };
    const { data: initialPayload } = await GptTurboApi.create(askingTemplate);
    const initialResponseMessage = initialPayload[0].message || '';
    gptRequestTemplate.messages = [
      ...askingTemplate.messages,
      initialResponseMessage,
      firstRoundUserQuestions,
    ];
    const multiTurnDialogue = async () => {
      const intent = {
        name: '',
        examples: [],
        response: { text: '' },
      };
      const { data: payload } = await GptTurboApi.create(gptRequestTemplate);
      const newMessages = [
        ...gptRequestTemplate.messages,
        payload[0].message || '',
      ];
      gptRequestTemplate.messages = newMessages;
      const filter = gptRequestTemplate.messages
        .filter(c => c.role === 'assistant')
        .filter(item => item.content.includes('example'));
      filter.forEach(assistantResponse => {
        // 需要去除空格，因为gpt可能会在内容前后加空格导致匹配不上
        const contents = assistantResponse.content
          .split('\n')
          .map(str => str.trim());
        contents.forEach(content => {
          // 解析gpt响应
          parseGptContent(content, intent);
        });
      });
      const timestamp = new Date().getTime() / 1000;
      const formattedDate = formatUnixDate(timestamp, 'yyyy-MM-dd HH:mm:ss');
      // 创建意图
      const createIntentPromise = dispatch('create', {
        intentInfo: {
          name: `${intent.name} ${formattedDate}`,
          examples: intent.examples,
          response: intent.response,
        },
      });
      // 每次生成一个intent后，清空最后一个GPT的response
      gptRequestTemplate.messages.pop();
      return createIntentPromise;
    };
    const promises = [];
    // 将多个Promise放入数组中
    for (let count = 1; count <= questionsTotal; count += 1) {
      firstRoundUserQuestions.content = `Please generate 10 similar examples for the question ${count}. These examples should have very similar meanings, but the wording should be different. The fields for these examples are "example", each "example" should end with a line break. Note: these "examples" must be questions. You also need to generate a short topic to summarize this set of questions and answers. The field for this topic is "name", and note that the topic cannot exceed 6 words. You must strictly follow the format "name:{your topic} \n example:{your example} \n example:{your example} \n example:{your example} \n example:{your example} \n example:{your example} \n example:{your example} \n example:{your example} \n response:{your reply}" to output the results, and you only need to generate one set. You do not need to do any marking or numbering when replying to me. You must strictly follow the product description I provide to fill in the "response" field, and the content in the "response" field cannot contain line breaks.\nPlease write in ${language} language.`;
      promises.push(multiTurnDialogue());

      // await promises.[promises.length - 1]
    }
    // 并行执行所有异步请求
    Promise.all(promises)
      .then(() => {
        commit(SET_GPT_IMPORTED_INTENT_STATUS, true);
      })
      .catch(error => {
        throw new Error(error);
      })
      .finally(() => {
        commit(SET_GPT_IMPORTED_INTENT_STATUS, true);
      });
  },
};
