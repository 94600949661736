export const SET_STORIES_UI_FLAG = 'SET_STORIES_UI_FLAG';
export const CLEAR_STORIES = 'CLEAR_STORIES';
export const SET_STORIES = 'SET_STORIES';
export const SET_STORY_ITEM = 'SET_STORY_ITEM';
export const EDIT_STORY = 'EDIT_STORY';
export const DELETE_STORY = 'DELETE_STORY';
export const SET_STORIES_COUNT = 'SET_STORIES_COUNT';
export const SET_MESSAGES_LOADED = 'SET_MESSAGES_LOADED';
export const SET_ALL_CONVERSATION = 'SET_ALL_CONVERSATION';
export const ADD_STORY_MESSAGE = 'ADD_STORY_MESSAGE';
export const DELETE_MESSAGE = 'DELETE_MESSAGE';
export const UPDATE_CONVERSATION = 'UPDATE_CONVERSATION';
export const SET_MESSAGE = 'SET_MESSAGE';
export const SET_GPT_TURBO_MESSAGE = 'SET_GPT_TURBO_MESSAGE';
export const CLEAR_GPT_TURBO_MESSAGE = 'CLEAR_GPT_TURBO_MESSAGE';
export const SET_COUNTERS = 'SET_COUNTERS';
export const CLEAR_COUNTERS = 'CLEAR_COUNTERS';
