<template>
  <modal :show.sync="show" :on-close="onClose" size="medium">
    <div class="column content-box">
      <woot-loading-state
        v-if="uiFlags.isFetching"
        :message="$t('AI_STORY_MANAGE_SETTINGS.MODAL.LOADING')"
      />
      <div v-if="canRenderMessages">
        <woot-modal-header
          :header-title="$t('AI_STORY_MANAGE_SETTINGS.NEW_STORY')"
        />
        <form class="" @submit.prevent="handleSubmit">
          <woot-input
            v-model.trim="storyExamples.title"
            class="medium-12"
            :label="$t('AI_STORY_MANAGE_SETTINGS.MODAL.STORY_EXAMPLE_NAME')"
            :placeholder="$t('AI_STORY_MANAGE_SETTINGS.MODAL.PLACEHOLDER')"
            :class="{ error: $v.storyExamples.title.$error }"
            @keydown.enter.prevent
            @input="$v.storyExamples.title.$touch"
          />
          <div ref="scrollArea" class="conversation-panel wrapper">
            <story-message
              v-for="message in processedMessages"
              :key="message.id"
              :data="message"
              :delete-message="handleDeleteMessage"
            />
          </div>
          <div class="add-reply-group">
            <label>{{
              $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.QUESTION')
            }}</label>
            <textarea
              v-model.trim="contentObj.ask.content"
              class="medium-3"
              :placeholder="
                $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.QUESTION_PLACEHOLDER')
              "
              :rows="'1'"
            />
            <label>{{
              $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.ANSWER')
            }}</label>
            <textarea
              v-model.trim="contentObj.reply.content"
              class="medium-3"
              :placeholder="
                $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.ANSWER_PLACEHOLDER')
              "
              :rows="'1'"
            />
            <woot-button
              icon="add-circle"
              variant="clear"
              color-scheme="secondary"
              @click.prevent="handleAddMessage"
            >
              {{ $t('AI_STORY_MANAGE_SETTINGS.ADD_MESSAGE.BUTTON_TEXT') }}
            </woot-button>
          </div>
          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-submit-button
                :button-text="$t('CANNED_MGMT.ADD.FORM.SUBMIT')"
                :loading="showLoading"
                :disabled="isButtonDisabled"
              />
              <button class="button clear" @click.prevent="onClose">
                {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import _first from 'lodash/first';
import conversationMixin from '../../../../../mixins/conversations';
import StoryMessage from '../components/StoryMessage.vue';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import storyMixin from 'dashboard/mixins/storyMixin';
export default {
  components: {
    WootSubmitButton,
    Modal,
    StoryMessage,
  },
  mixins: [alertMixin, conversationMixin, storyMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      showLoading: false,
      show: true,
      conversationPanel: null,
      isLoadingPrevious: true,
      contentObj: {
        ask: {
          content: '',
          message_type: MESSAGE_TYPE.INCOMING,
          created_at: 0,
        },
        reply: {
          content: '',
          message_type: MESSAGE_TYPE.OUTGOING,
          created_at: 1,
        },
      },
      storyExamples: { title: '', steps: [] },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'aiStoryManage/getUIFlags',
      currentChat: 'getSelectedChat',
      storyMessage: 'aiStoryManage/getAllConversations',
      messageLoadingStatus: 'aiStoryManage/getMessagesLoaded',
    }),
    canRenderMessages() {
      return this.storyMessage.length && !this.uiFlags.isFetching;
    },
    isButtonDisabled() {
      return this.$v.storyExamples.title.$invalid || this.showLoading;
    },
    processedMessages() {
      const msg = this.getReadMessages.filter(
        _ => _.message_type !== MESSAGE_TYPE.ACTIVITY
      );
      return msg;
    },
    getReadMessages() {
      const chat = this.getCurrentConversationAllMessages?.messages;
      return chat === undefined
        ? null
        : this.readMessages(chat, this.currentChat.agent_last_seen_at);
    },
    getCurrentConversationAllMessages() {
      const [chat] = this.storyMessage.filter(
        c => c.id === this.currentChat.id
      );
      return chat;
    },
    formatMessages() {
      return this.processedMessages.map(({ content, message_type }) => {
        let formattedContent = content
          .trim()
          .split('\n')
          .join('');
        return message_type === MESSAGE_TYPE.INCOMING
          ? { ask: formattedContent }
          : { reply: formattedContent };
      });
    },
  },
  watch: {
    getReadMessages(msg) {
      if (msg) {
        const currentMessage = _first(msg);
        this.fetchMessage(currentMessage);
      }
    },
  },
  created() {
    this.$store.dispatch(
      'aiStoryManage/getAllConversation',
      this.currentChat.id
    );
    this.$store.dispatch('aiStoryManage/initMessageLoaded');
  },

  validations: {
    storyExamples: {
      title: { required },
    },
  },
  methods: {
    showSuccessAlert() {
      this.showAlert(
        this.$t('AI_STORY_MANAGE_SETTINGS.CREATE_FLOW.API.SUCCESS_MESSAGE')
      );
    },
    showErrorAlert() {
      this.showAlert(
        this.$t('AI_STORY_MANAGE_SETTINGS.CREATE_FLOW.API.ERROR_MESSAGE')
      );
    },
    async fetchMessage(currentMessage) {
      if (this.messageLoadingStatus) {
        return;
      }
      if (currentMessage) {
        await this.$store.dispatch('aiStoryManage/fetchPreviousMessages', {
          conversationId: this.currentChat.id,
          before: currentMessage.id,
        });

        await this.fetchMessage();
      }
    },
    resetForm() {
      this.storyExamples = {
        title: '',
        steps: [],
      };
      this.$v.storyExamples.title.$reset();
    },
    handleAddMessage() {
      if (!this.contentObj.ask.content || !this.contentObj.reply.content) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.MODAL.API.ADD_EMPTY_MESSAGE')
        );
        return;
      }
      this.$store.dispatch('aiStoryManage/addStoryMessage', {
        conversationId: this.currentChat.id,
        content: this.contentObj,
      });
      this.contentObj.ask.content = '';
      this.contentObj.reply.content = '';
      this.$nextTick(() => {
        const scrollArea = this.$refs.scrollArea;
        scrollArea.scrollTop = scrollArea.scrollHeight;
      });
    },
    handleDeleteMessage(id) {
      this.$store.dispatch('aiStoryManage/deleteMessage', {
        id: id,
        conversationId: this.currentChat.id,
      });
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.shouldShowInvalidAlert()) {
        this.showInvalidAlert();
        return;
      }
      try {
        this.showLoading = true;
        const { title } = this.storyExamples;
        const steps = this.formatMessages;
        await this.$store.dispatch('aiStoryManage/createStory', {
          title,
          steps,
        });
        this.showLoading = false;
        this.showSuccessAlert();
        this.onClose();
        this.resetForm();
      } catch (error) {
        this.showErrorAlert();
        this.showLoading = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ve-pagination {
  margin-top: var(--space-one);
  display: flex;
  justify-content: flex-end;
}
.add-reply-group {
  display: flex;
  align-items: baseline;
  padding: var(--space-two) 0;
  > * {
    margin-right: var(--space-one);
    font-weight: var(--font-weight-medium);
  }
}
.wrapper {
  max-height: 500px;
  overflow: auto;
}
</style>
