<template>
  <story-form
    :on-submit="createStory"
    :submit-in-progress="false"
    :submit-button-text="$t('AI_STORY_MANAGE_SETTINGS.FORM.CREATE')"
    :created-success="isCreatedSuccess"
  />
</template>

<script>
import StoryForm from '../StoryFrom.vue';
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: { StoryForm },
  mixins: [alertMixin],
  data() {
    return { isCreatedSuccess: false };
  },
  computed: {},
  methods: {
    async createStory(data) {
      try {
        const story = await this.$store.dispatch('aiStoryManage/createStory', {
          ...data,
        });
        if (story) {
          this.showAlert(
            this.$t('AI_STORY_MANAGE_SETTINGS.CREATE_FLOW.API.SUCCESS_MESSAGE')
          );
          this.isCreatedSuccess = true;
          router.replace({
            name: 'settings_ai_story_manage_list',
          });
        }
      } catch (error) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.CREATE_FLOW.API.ERROR_MESSAGE')
        );
        this.isCreatedSuccess = false;
      }
    },
  },
};
</script>
