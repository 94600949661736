<template>
  <div class="day-wrap">
    <div class="checkbox-wrap">
      <input
        v-model="isDayEnabled"
        name="enable-day"
        class="enable-checkbox"
        type="checkbox"
        :title="$t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.DAY.ENABLE')"
      />
    </div>
    <div class="day">
      <span>{{ dayName }}</span>
    </div>
    <div v-if="isDayEnabled" class="hours-select-wrap">
      <div class="hours-range">
        <div class="checkbox-wrap open-all-day">
          <input
            v-model="isOpenAllDay"
            name="enable-open-all-day"
            class="enable-checkbox"
            type="checkbox"
            :title="$t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.ALL_DAY')"
          />
          <span>{{ $t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.ALL_DAY') }}</span>
        </div>
        <multiselect
          v-model="fromTime"
          :options="fromTimeSlots"
          deselect-label=""
          select-label=""
          selected-label=""
          :placeholder="$t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.DAY.CHOOSE')"
          :allow-empty="false"
          :disabled="isOpenAllDay"
        />
        <div class="separator-icon">
          <fluent-icon icon="subtract" type="solid" size="16" />
        </div>
        <multiselect
          v-model="toTime"
          :options="toTimeSlots"
          deselect-label=""
          select-label=""
          selected-label=""
          :placeholder="$t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.DAY.CHOOSE')"
          :allow-empty="false"
          :disabled="isOpenAllDay"
        />
        <div class="add-button">
          <woot-button
            :disabled="isButtonDisabled"
            icon="add"
            size="small"
            color-scheme="primary"
            @click.prevent="addTimeRange"
          >
            {{ $t('AI_CONFIGS_SETTINGS.MODAL.CUSTOM.BUTTON_TEXT') }}
          </woot-button>
        </div>
      </div>
      <div class="time-segment">
        <time-segment
          v-for="(segment, index) in selectedTimeRanges"
          ref="segment"
          :key="index"
          :time-segment="segment"
          @remove-segment="removeTimeRange"
        />
      </div>
      <div v-if="hasError" class="date-error">
        <span class="error">{{
          $t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.DAY.VALIDATION_ERROR')
        }}</span>
      </div>
    </div>
    <div v-else class="day-unavailable">
      <span>
        {{ $t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.DAY.UNAVAILABLE') }}
      </span>
    </div>
  </div>
</template>

<script>
import parse from 'date-fns/parse';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import { generateTimeSlots, formatTimeToSecond } from '../helpers/customHour';
import TimeSegment from './TimeSegment.vue';
import { nanoid } from 'nanoid';
const timeSlots = generateTimeSlots(30);
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    TimeSegment,
  },
  mixins: [alertMixin],

  props: {
    dayName: {
      type: String,
      default: '',
      required: true,
    },
    timeSlot: {
      type: Object,
      default: () => ({
        from: '',
        to: '',
      }),
    },
    defaultOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTimeRanges: [],
      timeRanges: {
        Monday: [],
        Tuesday: [],
        Wednesday: [],
        Thursday: [],
        Friday: [],
        Saturday: [],
        Sunday: [],
      },
    };
  },
  computed: {
    fromTimeSlots() {
      return timeSlots;
    },
    toTimeSlots() {
      return timeSlots.filter(slot => {
        return slot !== '12:00 AM';
      });
    },
    isDayEnabled: {
      get() {
        return (
          (this.timeSlot.from && this.timeSlot.to) ||
          this.timeSlot.options.length > 0 ||
          this.timeSlot.valid
        );
      },
      set(value) {
        if (!value) {
          this.selectedTimeRanges = [];
          this.$emit('clear');
        }
        const newSlot = value
          ? {
              ...this.timeSlot,
              from: timeSlots[0],
              to: timeSlots[16],
              valid: true,
              openAllDay: false,
              options: this.selectedTimeRanges,
            }
          : {
              ...this.timeSlot,
              from: '',
              to: '',
              valid: false,
              openAllDay: false,
              options: [],
            };
        this.$emit('update', newSlot);
      },
    },
    fromTime: {
      get() {
        return this.timeSlot.from;
      },
      set(value) {
        const fromDate = parse(value, 'hh:mm a', new Date());
        const valid = differenceInMinutes(this.toDate, fromDate) / 60 > 0;
        this.$emit('update', {
          ...this.timeSlot,
          from: value,
          valid,
        });
      },
    },
    toTime: {
      get() {
        return this.timeSlot.to;
      },
      set(value) {
        const toDate = parse(value, 'hh:mm a', new Date());
        if (value === '12:00 AM') {
          this.$emit('update', {
            ...this.timeSlot,
            to: value,
            valid: true,
          });
        } else {
          const valid = differenceInMinutes(toDate, this.fromDate) / 60 > 0;
          this.$emit('update', {
            ...this.timeSlot,
            to: value,
            valid,
          });
        }
      },
    },
    isButtonDisabled() {
      return (!this.fromTime && !this.toTime) || this.hasError;
    },
    fromDate() {
      return parse(this.fromTime, 'hh:mm a', new Date());
    },
    toDate() {
      return parse(this.toTime, 'hh:mm a', new Date());
    },
    totalHours() {
      if (this.timeSlot.openAllDay) {
        return 24;
      }
      const totalHours = differenceInMinutes(this.toDate, this.fromDate) / 60;
      return totalHours;
    },
    hasError() {
      return !this.timeSlot.valid;
    },
    isOpenAllDay: {
      get() {
        return this.timeSlot.openAllDay;
      },
      set(value) {
        if (value) {
          this.$emit('update', {
            ...this.timeSlot,
            from: '12:00 AM',
            to: '11:59 PM',
            valid: true,
            openAllDay: value,
          });
        } else {
          this.$emit('update', {
            ...this.timeSlot,
            from: '09:00 AM',
            to: '05:00 PM',
            valid: true,
            openAllDay: value,
          });
        }
      },
    },
  },
  mounted() {
    this.selectedTimeRanges = this.timeSlot.options;
  },

  methods: {
    // 检查时间段是否被占用
    checkTimeRangeExist(options, timeRange) {
      for (let i = 0; i < options.length; i += 1) {
        const option = options[i];
        if (option[timeRange.day]) {
          const timePeriods = option[timeRange.day];
          for (let j = 0; j < timePeriods.length; j += 1) {
            const existingTimePeriod = timePeriods[j];
            if (
              formatTimeToSecond(timeRange.from) === existingTimePeriod.from &&
              formatTimeToSecond(timeRange.to) === existingTimePeriod.to
            ) {
              // 如果时间段已经存在，则返回true
              return true;
            }
          }
        }
      }
      return false;
    },
    addTimeRange() {
      if (!this.timeSlot.valid) {
        return;
      }
      const timeRange = {
        timePeriodKey: nanoid(),
        day: this.dayName,
        from: this.timeSlot.from,
        to: this.timeSlot.to,
        hours: this.totalHours,
      };

      if (this.checkTimeRangeExist(this.defaultOptions, timeRange)) {
        this.showAlert(
          this.$t('AI_CONFIGS_SETTINGS.MODAL.API.EXIST_TIME_MESSAGE')
        );
        return;
      }
      this.selectedTimeRanges.push(timeRange);
      this.$emit('updateOptions', this.selectedTimeRanges);
    },
    removeTimeRange(segment) {
      const filtered = this.selectedTimeRanges.filter(tr => {
        return tr.timePeriodKey !== segment.timePeriodKey;
      });
      this.selectedTimeRanges = filtered;
      this.$emit('removeTimeRange', this.selectedTimeRanges);
      if (this.selectedTimeRanges.length === 0) {
        this.isDayEnabled = false;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.day-wrap::v-deep .multiselect {
  margin: 0;
  width: 12rem;

  > .multiselect__tags {
    padding-left: var(--space-slab);

    .multiselect__single {
      font-size: var(--font-size-small);
      line-height: var(--space-medium);
      padding: var(--space-small) 0;
    }
  }
}
.day-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--space-small) 0;
  min-height: var(--space-larger);
  box-sizing: content-box;
  border-bottom: 1px solid var(--color-border-light);
}
.enable-checkbox {
  margin: 0;
}

.hours-select-wrap {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-grow: 1;
  position: relative;
}

.hours-range,
.day-unavailable {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  flex-grow: 1;
}
.add-button {
  margin: 0 20px;
}
.time-segment {
  display: flex;
  flex-wrap: wrap;
  max-width: 70rem;
}

.day-unavailable {
  font-size: var(--font-size-small);
  color: var(--s-500);
}

.checkbox-wrap {
  display: flex;
  align-items: center;
}

.separator-icon,
.day {
  display: flex;
  align-items: center;
  padding: 0 var(--space-slab);
  height: 100%;
}

.day {
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-medium);
  width: 13rem;
}

.label {
  font-size: var(--font-size-mini);
  color: var(--w-700);
  background: var(--w-50);
}

.date-error {
  padding-top: var(--space-smaller);
}

.error {
  font-size: var(--font-size-mini);
  color: var(--r-300);
}

.open-all-day {
  margin-right: var(--space-medium);
  span {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
    margin-left: var(--space-smaller);
  }
  input {
    font-size: var(--font-size-small);
    font-weight: var(--font-weight-medium);
  }
}
</style>
