import SettingsContent from '../Wrapper';
import Hello from './Hello';
import { frontendURL } from '../../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/entity-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_entity_manage_list';
        return {
          headerTitle: 'ENTITY_MANAGE_SETTINGS.HEADER',
          icon: 'column-triple',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_entity_manage',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_entity_manage_list',
          component: Hello,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
