/** ChatBot回复内容类型 */
export const RESPONSE_CONTENT_TYPE = {
  TEXT_RESPONSE: 0,
  HTML_RESPONSE: 1,
  IMAGE_RESPONSE: 2,
  CUSTOM: 3,
};
export const STORY_CONTENT_TYPE = {
  MANUAL: 0,
  FAQ: 1,
  DOCUMENT: 2,
};
/**

该自定义字段用于记录回复时间，即在一个会话中，当客服进行人工回复后，AI会在接下来的一个小时内暂停对该会话的自动回复。
@const {number} USER_REPLY - 当前时间戳
*/
// eslint-disable-next-line no-underscore-dangle
export const _USER_REPLY = 'user_reply';
// eslint-disable-next-line no-underscore-dangle
export const _UTTER_DEFAULT = 'utter_default';
