<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-loading-state
      v-if="uiFlags.isFetching"
      :message="$t('INTENT_MANAGE_SETTINGS.LOADING')"
    />
    <div v-else>
      <empty-state
        v-if="!intentItem.length"
        :title="$t('INTENT_MANAGE_SETTINGS.EMPTY_DATA')"
      />
    </div>
    <div v-show="canRenderTable" id="loading-container">
      <ve-table
        max-height="calc(100vh - 200px)"
        :columns="columns"
        :table-data="intentItem"
        :border-x="true"
        :border-y="true"
        row-key-field-name="id"
        :cell-selection-option="cellSelectionOption"
      />
      <ve-pagination
        class="ve-pagination"
        :total="intentsCount"
        :page-index="pageIndex"
        :layout="['total', 'prev', 'pager', 'next', 'jumper']"
        @on-page-number-change="onPageChange"
      />
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.TITLE')"
      :message="$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import router from '../../../index';
import { VePagination } from 'vue-easytable';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
export default {
  components: {
    VeTable,
    EmptyState,
    VePagination,
  },
  mixins: [accountMixin, alertMixin, veLoadingMixin],

  data() {
    return {
      pageIndex: 1,
      showDeleteConfirmationPopup: false,
      selectedIntent: {},
      cellSelectionOption: {
        enable: false,
      },
      contentTypes: {
        0: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.CONTENT_TYPE.text'),
        1: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.CONTENT_TYPE.phone'),
        2: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.CONTENT_TYPE.email'),
        3: this.$t(
          'INTENT_MANAGE_SETTINGS.LIST_HEADER.CONTENT_TYPE.universal_id'
        ),
      },
      columns: [
        {
          field: 'id',
          key: 'id',
          title: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.ID'),
          align: 'left',
          width: '5%',
        },
        {
          field: 'name',
          key: 'name',
          title: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.NAME'),
          align: 'left',
          width: 170,
          ellipsis: {
            showTitle: true,
            lineClamp: 3,
          },
        },
        {
          field: 'content_type',
          key: 'content_type',
          title: this.$t('AI_ACTION_MANAGE_SETTINGS.LIST_HEADER.CONTENT_TYPE'),
          align: 'left',
          width: '',
        },
        {
          field: 'text',
          key: 'text',
          title: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.EXAMPLE'),
          align: 'left',
          width: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 3,
          },
        },
        {
          field: '',
          key: 'e',
          title: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.ACTIONS'),
          width: 90,
          center: 'left',
          renderBodyCell: ({ row: intent }) => {
            return (
              <span>
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary
                  "
                  icon="edit"
                  class="edit-custom-view__button"
                  on-click={() => this.editRow(intent.id)}
                ></woot-button>
                &nbsp;
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="delete"
                  class="delete-custom-view__button"
                  on-click={() => this.openDeletePopup(intent)}
                ></woot-button>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      intentData: 'intentManage/getIntents',
      uiFlags: 'intentManage/getUIFlags',
      intentsCount: 'intentManage/getCount',
    }),
    intentItem() {
      let intentArr = Object.values(this.intentData);
      if (intentArr) {
        let newIntentArr = intentArr.map(intent => ({
          id: intent.id,
          name: intent.name,
          content_type: this.contentTypes[intent.content_type],
          text: `${intent.examples?.map(({ example }) => example).join('\n')}`,
          response: intent.response,
        }));
        return newIntentArr;
      }
      return {};
    },
    canRenderTable() {
      return (
        this.intentItem &&
        this.intentItem.length > 0 &&
        !this.uiFlags.isFetching
      );
    },
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedIntent.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.NO')} ${
        this.selectedIntent.name
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedIntent.name}?`;
    },
  },
  created() {
    this.$store.dispatch('intentManage/get', { page: this.pageIndex });
  },
  methods: {
    onPageChange(page) {
      this.pageIndex = page;
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('intentManage/get', { page });
    },
    editRow(id) {
      router.push({
        path: this.addAccountScoping(`settings/intent-manage/${id}/edit`),
        params: {
          page: 'edit',
          id: id,
        },
      });
    },
    openDeletePopup(intent) {
      this.showDeleteConfirmationPopup = true;
      this.selectedIntent = intent;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteIntent(this.selectedIntent.id);
    },
    async deleteIntent(id) {
      this.showVeLoading();
      try {
        await this.$store.dispatch('intentManage/delete', id);
        await this.$store.dispatch('intentManage/get', {
          page: this.pageIndex,
        });
        if (!this.intentData.length && this.pageIndex > 1) {
          this.onPageChange(1);
        }
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.DELETE.API.ERROR_MESSAGE')
        );
      } finally {
        this.closeVeLoading();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ve-pagination {
  margin-top: var(--space-one);
  display: flex;
  justify-content: flex-end;
  background-color: transparent !important;
}
</style>
