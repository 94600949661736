export const FEATURE_FLAGS = {
  AGENT_BOTS: 'agent_bots',
  AGENT_MANAGEMENT: 'agent_management',
  AUTO_RESOLVE_CONVERSATIONS: 'auto_resolve_conversations',
  AUTOMATIONS: 'automations',
  CAMPAIGNS: 'campaigns',
  CANNED_RESPONSES: 'canned_responses',
  CRM: 'crm',
  CUSTOM_ATTRIBUTES: 'custom_attributes',
  INBOX_MANAGEMENT: 'inbox_management',
  INTEGRATIONS: 'integrations',
  LABELS: 'labels',
  MACROS: 'macros',
  HELP_CENTER: 'help_center',
  REPORTS: 'reports',
  TEAM_MANAGEMENT: 'team_management',
  VOICE_RECORDER: 'voice_recorder',
  AUDIT_LOGS: 'audit_logs',
  INTENT_MANAGEMENT: 'intent_management',
  AI_DEPLOYMENT: 'ai_deployment',
  AI_STORY_MANAGEMENT: 'ai_story_management',
  AI_CONFIGS: 'ai_configs',
  AI_ACTION_MANAGEMENT: 'ai_action_management',
  KNOWLEDGE_BASE: 'knowledge_base',
  FAQ_MANAGEMENT: 'faq_management',
  ENTITY_MANAGEMENT: 'entity_management',
  VOCABULARY_MANAGEMENT: 'vocabulary_management',
};
