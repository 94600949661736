/* global axios */
import ApiClient from './ApiClient';

export class GptConfigs extends ApiClient {
  constructor() {
    super('gpt/gpt_configs ', { accountScoped: true });
  }

  updateConfig({ id }) {
    return axios.post(`${this.url}/${id}/gpt/gpt_configs`);
  }
}

export default new GptConfigs();
