/* global axios */
import ApiClient from './ApiClient';

export class GptStories extends ApiClient {
  constructor() {
    super('gpt/gpt_stories', { accountScoped: true });
  }

  getStories(pageNumber, type) {
    let requestURL = `${this.url}?page=${pageNumber}&content_type=${type}`;
    return axios.get(requestURL);
  }

  addStories({ id }) {
    return axios.post(`${this.url}/${id}/gpt/gpt_stories`);
  }

  updateStories({ id, storyId }) {
    return axios.patch(`${this.url}/${id}/gpt/gpt_stories${storyId}`);
  }
}

export default new GptStories();
