var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wizard-body column content-box"},[_c('div',{staticClass:"small-6 columns"},[_c('h3',[_vm._v("\n      "+_vm._s(_vm.$t(
          'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.TITLE'
        ))+"\n    ")]),_vm._v(" "),_c('p',{staticStyle:{"line-height":"25px"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.ROW_1'
        ))+"\n    ")])]),_vm._v(" "),(!_vm.showLoading)?_c('div',[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.content),expression:"content"}],attrs:{"rows":15,"type":"text","maxlength":_vm.maxTextareaLength},domProps:{"value":(_vm.content)},on:{"input":function($event){if($event.target.composing){ return; }_vm.content=$event.target.value}}}),_vm._v(" "),(_vm.content.length >= _vm.maxTextareaLength)?_c('p',{staticStyle:{"color":"red"}},[_vm._v("\n      "+_vm._s(_vm.$t(
          'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.EXCEED_LENGTH'
        ))+"\n    ")]):_vm._e(),_vm._v(" "),(_vm.content)?_c('div',[_c('p',[_vm._v("\n        "+_vm._s(_vm.$t(
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.CURRENT_TEXT_LENGTH'
          ))+_vm._s(_vm.content.length)+"\n      ")]),_vm._v(" "),_c('p',[_vm._v("段落总数:"+_vm._s(_vm.segments))]),_vm._v(" "),_c('p',[_vm._v("\n        "+_vm._s(_vm.$t(
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.EXPECTED_TIME',
            { estimatedWaitingTime: _vm.estimatedWaitingTime }
          ))+"\n      ")])]):_vm._e()]):_vm._e(),_vm._v(" "),(_vm.showLoading)?_c('woot-loading-state',{attrs:{"message":_vm.isImportingText}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"columns small-4 medium-2"},[_c('label',[_vm._v("\n      "+_vm._s(_vm.$t(
          'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.LABEL.OUTPUT_IN'
        ))+"\n    ")]),_vm._v(" "),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.locale),expression:"locale"}],attrs:{"disabled":_vm.showLoading},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.locale=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},_vm._l((_vm.languagesSortedByCode),function(lang){return _c('option',{key:lang.name,domProps:{"value":lang.name}},[_vm._v("\n        "+_vm._s(lang.name)+"\n      ")])}),0)]),_vm._v(" "),_c('div',{staticClass:"medium-12 columns"},[_c('woot-button',{attrs:{"loading":_vm.showLoading,"disabled":_vm.isButtonDisabled},on:{"click":_vm.generateStoryWithGPT}},[_vm._v("\n      "+_vm._s(_vm.buttonText)+"(生成话术案例)\n    ")]),_vm._v(" "),_c('woot-button',{attrs:{"loading":_vm.showLoading,"disabled":_vm.isButtonDisabled},on:{"click":_vm.generateIntentWithGPT}},[_vm._v("\n      "+_vm._s(_vm.buttonText)+"(生成意图)\n    ")]),_vm._v(" "),_c('woot-confirm-modal',{ref:"confirmDialog",attrs:{"title":_vm.generateIntentTitle,"description":_vm.generateIntentMessage}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }