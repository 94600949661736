<template>
  <intent-form
    v-if="showIntentForm"
    :on-submit="updateIntent"
    :submit-in-progress="showLoading"
    :submit-button-text="$t('INTENT_MANAGE_SETTINGS.FORM.UPDATE')"
    :form-data="intentData"
  />
</template>

<script>
import IntentForm from '../IntentForm';
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';

import { mapGetters } from 'vuex';

export default {
  components: {
    IntentForm,
  },
  mixins: [alertMixin],
  data() {
    return {
      enabledFeatures: {},
      showLoading: false,
    };
  },
  computed: {
    intentData() {
      const { intentId } = this.$route.params;
      const intents = this.$store.getters['intentManage/getIntent'](intentId);
      if (intents && intents.examples) {
        intents.examples.forEach((example, index) => {
          example.rowKey = index + 1;
          return example;
        });
      }
      return intents;
    },
    cameFromStory() {
      return this.$route.query.fromStory;
    },
    showIntentForm() {
      const { id } = this.intentData;
      return id && !this.uiFlags.isFetching && !this.uiFlags.FetchingItem;
    },
    ...mapGetters({
      uiFlags: 'intentManage/getUIFlags',
    }),
  },
  methods: {
    async updateIntent(data) {
      try {
        this.showLoading = true;
        const { intentId } = this.$route.params;
        await this.$store.dispatch('intentManage/update', {
          id: intentId,
          ...data,
        });
        this.showLoading = false;
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.EDIT_FLOW.API.SUCCESS_MESSAGE')
        );
        if (this.cameFromStory) {
          router.replace({
            name: 'settings_ai_story_manage_list',
          });
        } else {
          router.replace({
            name: 'settings_intent_manage_list',
          });
        }
      } catch (error) {
        this.showLoading = false;
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.EDIT_FLOW.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
