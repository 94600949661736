import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  stories: {
    count: 0,
    data: [],
  },
  gptTurboRecord: {
    messages: [],
  },
  messagesLoaded: false,
  allConversations: [],
  uiFlags: {
    isFetching: true,
    isCreating: false,
    isUpdating: false,
    isDeleting: false,
    isImporting: false,
  },
  counters: {
    importedCount: 0,
    generatedCount: 0,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
