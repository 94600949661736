import {
  SET_MODELS,
  SET_TRAINING_IN_PROGRESS,
  TRAIN_STATUS,
  DEPLOY_STATUS,
  SET_MODELS_COUNT,
  DELETE_MODEL,
  SET_MODELS_UI_FLAG,
  CLEAR_MODELS,
} from './types';
import GptModelApi from '../../../api/gptModels';

export const actions = {
  get: async ({ commit }, { page = 1 } = {}) => {
    let timeoutId;
    commit(SET_MODELS_UI_FLAG, { isFetching: true });
    try {
      const fetchTrainingList = async () => {
        const { data: payload } = await GptModelApi.page(page);
        commit(CLEAR_MODELS);
        if (payload.data.length === 0) {
          commit(SET_MODELS_UI_FLAG, { isFetching: false });
        } else {
          commit(SET_MODELS_COUNT, payload.count);
          commit(SET_MODELS, payload.data);
          commit(SET_MODELS_UI_FLAG, { isFetching: false });
        }

        const isAllTrainingFinished = payload.data.every(
          item => item.status !== TRAIN_STATUS.TRAINING
        );
        const isAllDeployingFinished = payload.data.every(
          item => item.deployed !== DEPLOY_STATUS.RELEASE_IN
        );
        if (isAllTrainingFinished && isAllDeployingFinished) {
          commit(SET_TRAINING_IN_PROGRESS, {
            training: false,
            deploying: false,
          });
          clearTimeout(timeoutId);
        } else {
          timeoutId = setTimeout(fetchTrainingList, 10000);
          commit(SET_TRAINING_IN_PROGRESS, { training: true, deploying: true });
        }
      };
      fetchTrainingList();
    } catch (error) {
      commit(SET_TRAINING_IN_PROGRESS, { training: false, deploying: false });
      throw new Error(error);
    } finally {
      // commit(SET_MODELS_UI_FLAG, { isFetching: false });
    }
  },
  start: async ({ commit }) => {
    commit(SET_TRAINING_IN_PROGRESS, { training: true, deploying: true });
    try {
      await GptModelApi.create();
    } catch (error) {
      commit(SET_TRAINING_IN_PROGRESS, { training: false, deploying: false });
      throw new Error(error);
    }
  },
  deploy: async ({ commit }, ModelId) => {
    commit(SET_TRAINING_IN_PROGRESS, { training: true, deploying: true });
    try {
      await GptModelApi.update(ModelId);
    } catch (error) {
      commit(SET_TRAINING_IN_PROGRESS, { training: false, deploying: false });
      throw new Error(error);
    }
  },
  delete: async ({ commit }, ModelId) => {
    commit(SET_MODELS_UI_FLAG, { isDeleting: true });
    try {
      await GptModelApi.delete(ModelId);
      commit(DELETE_MODEL, ModelId);
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_MODELS_UI_FLAG, { isDeleting: false });
    }
  },
};
