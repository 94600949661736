import GptConfigsApi from '../../../api/gptConfigs';
export const actions = {
  // eslint-disable-next-line no-unused-vars
  updateConfig: async ({ commit }, payload) => {
    try {
      await GptConfigsApi.create(payload);
    } catch (error) {
      throw new Error(error);
    }
  },
};
