import GptIntentResponseApi from '../../../api/gptIntentResponse';
import gptStoriesApi from '../../../api/gptStories';
import {
  SET_ACTIONS,
  CLEAR_ACTIONS,
  SET_ACTIONS_UI_FLAG,
  EDIT_ACTION,
  SET_ACTION_ITEM,
  DELETE_ACTION,
} from './types';

export const actions = {
  get: async ({ commit }, { page = 1 } = {}) => {
    commit(SET_ACTIONS_UI_FLAG, { isFetching: true });
    try {
      commit(CLEAR_ACTIONS);
      const { data: payload } = await GptIntentResponseApi.page(page);
      if (payload.data.length === 0) {
        commit(SET_ACTIONS_UI_FLAG, { isFetching: false });
      } else {
        commit(SET_ACTIONS, payload);
        commit(SET_ACTIONS_UI_FLAG, { isFetching: false });
      }
    } catch (error) {
      // Handle error
    } finally {
      commit(SET_ACTIONS_UI_FLAG, { isFetching: false });
    }
  },
  getAll: async ({ commit }) => {
    commit(SET_ACTIONS_UI_FLAG, { isFetching: true });
    try {
      const { data: payload } = await GptIntentResponseApi.get();
      commit(CLEAR_ACTIONS);
      if (payload.data.length === 0) {
        commit(SET_ACTIONS_UI_FLAG, { isFetching: false });
      } else {
        commit(SET_ACTIONS, payload);
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      commit(SET_ACTIONS_UI_FLAG, { isFetching: false });
    }
  },
  create: async ({ commit }, payload) => {
    try {
      const response = await gptStoriesApi.create(payload);
      const action = response.data;
      commit(SET_ACTION_ITEM, action);
      return action;
    } catch (error) {
      throw new Error(error);
    }
  },
  update: async ({ commit }, { id, ...updateObj }) => {
    try {
      const response = await GptIntentResponseApi.update(id, updateObj);
      commit(EDIT_ACTION, response.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  delete: async ({ commit }, responseId) => {
    try {
      await GptIntentResponseApi.delete(responseId);
      commit(DELETE_ACTION, responseId);
    } catch (error) {
      throw new Error(error);
    }
  },
};
