<template>
  <ul>
    <li>
      <span>{{ ranges.from }} - {{ ranges.to }}</span>
      <!-- <p v-if="isDayEnabled && !hasError" class="label">
        {{ ranges.hours }} {{ $t('INBOX_MGMT.BUSINESS_HOURS.DAY.HOURS') }}
      </p> -->
      <woot-button
        size="small"
        variant="clear"
        color-scheme="alert"
        icon="dismiss-circle"
        @click.prevent="removeTimeSegment"
      />
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    timeSegment: {
      type: Object,
      default: () => [],
    },
  },
  data() {
    return {
      ranges: this.timeSegment,
    };
  },
  watch: {
    timeSegment(timeSegment) {
      this.ranges = timeSegment;
    },
  },
  methods: {
    removeTimeSegment() {
      this.$emit('remove-segment', this.ranges);
    },
  },
};
</script>
<style lang="scss" scoped>
ul {
  margin: 0;
  li {
    list-style: none;
    p {
      padding: 5px;
    }
    span {
      display: inline-block;
      margin: 10px;
      padding: 7px;
      background: var(--w-50);
      border-radius: 5px;
      font-size: var(--font-size-small);
      float: none;
      clear: none;
    }
  }
}
</style>
