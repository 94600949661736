<template>
  <faq-form
    v-if="showFaqForm"
    :on-submit="editFaq"
    :submit-in-progress="showLoading"
    :submit-button-text="$t('FAQ_MANAGE_SETTINGS.EDIT_FLOW.UPDATE.BUTTON_TEXT')"
    :form-data="faqData"
  />
</template>

<script>
import FaqForm from '../FaqForm';
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    FaqForm,
  },
  mixins: [alertMixin],
  data() {
    return {
      enabledFeatures: {},
      showLoading: false,
    };
  },

  computed: {
    faqData() {
      const { storyId } = this.$route.params;
      const stories = this.$store.getters['aiStoryManage/getStory'](storyId);
      return stories;
    },
    showFaqForm() {
      const { id } = this.faqData;
      return id;
    },
  },
  methods: {
    async editFaq(data) {
      try {
        this.showLoading = true;
        const { storyId } = this.$route.params;
        await this.$store.dispatch('aiStoryManage/updateStory', {
          id: storyId,
          ...data,
        });
        this.showAlert(
          this.$t('FAQ_MANAGE_SETTINGS.EDIT_FLOW.API.SUCCESS_MESSAGE')
        );
        this.showLoading = false;
        router.replace({
          name: 'settings_faq_manage_list',
        });
      } catch (error) {
        this.showAlert(
          this.$t('FAQ_MANAGE_SETTINGS.EDIT_FLOW.API.ERROR_MESSAGE')
        );
        this.showLoading = false;
      }
    },
  },
};
</script>
