<template>
  <div class="flex-1 overflow-auto p-4">
    <woot-loading-state
      v-if="uiFlags.isFetching"
      :message="$t('AI_ACTION_MANAGE_SETTINGS.LOADING')"
    />
    <div v-else>
      <empty-state
        v-if="!actionsList.length"
        :title="$t('AI_ACTION_MANAGE_SETTINGS.EMPTY_DATA')"
      />
    </div>
    <div v-show="canRenderTable" id="loading-container">
      <ve-table
        max-height="calc(100vh - 200px)"
        :columns="columns"
        :table-data="actionsList"
        :border-x="true"
        :border-y="true"
      />
      <ve-pagination
        class="ve-pagination"
        :total="actions.count"
        :page-index="pageIndex"
        :layout="['total', 'prev', 'pager', 'next', 'jumper']"
        @on-page-number-change="onPageChange"
      />
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AI_ACTION_MANAGE_SETTINGS.DELETE.CONFIRM.TITLE')"
      :message="$t('AI_ACTION_MANAGE_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import { mapGetters } from 'vuex';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import router from '../../../index';
import { VePagination } from 'vue-easytable';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
export default {
  components: {
    VeTable,
    EmptyState,
    VePagination,
  },
  mixins: [accountMixin, alertMixin, veLoadingMixin],

  data() {
    return {
      pageIndex: 1,
      faqData: [],
      cellSelectionOption: {
        enable: false,
      },
      selectedAction: {},
      showDeleteConfirmationPopup: false,
      contentTypes: {
        0: this.$t(
          'AI_ACTION_MANAGE_SETTINGS.RADIO.CONTENT_TYPE.text_response'
        ),
        1: this.$t(
          'AI_ACTION_MANAGE_SETTINGS.RADIO.CONTENT_TYPE.html_response'
        ),
        2: this.$t(
          'AI_ACTION_MANAGE_SETTINGS.RADIO.CONTENT_TYPE.image_response'
        ),
        3: this.$t('AI_ACTION_MANAGE_SETTINGS.RADIO.CONTENT_TYPE.custom'),
      },
      columns: [
        {
          field: 'id',
          key: 'id',
          title: this.$t('AI_ACTION_MANAGE_SETTINGS.LIST_HEADER.ID'),
          align: 'left',
          width: '5%',
        },
        {
          field: 'name',
          key: 'name',
          title: this.$t('AI_ACTION_MANAGE_SETTINGS.LIST_HEADER.NAME'),
          align: 'left',
          width: 180,
          ellipsis: {
            showTitle: true,
            lineClamp: 3,
          },
        },
        {
          field: 'content_type',
          key: 'content_type',
          title: this.$t('AI_ACTION_MANAGE_SETTINGS.LIST_HEADER.CONTENT_TYPE'),
          align: 'left',
          width: '',
          ellipsis: {
            showTitle: true,
            lineClamp: 3,
          },
        },
        {
          field: 'text',
          key: 'text',
          title: this.$t('AI_ACTION_MANAGE_SETTINGS.LIST_HEADER.DATA'),
          align: 'left',
          width: '',
          ellipsis: {
            showTitle: false,
            lineClamp: 5,
          },
        },
        {
          field: '',
          key: 'e',
          title: this.$t('AI_ACTION_MANAGE_SETTINGS.LIST_HEADER.ACTIONS'),
          width: 90,
          center: 'left',
          renderBodyCell: ({ row: actions }) => {
            return (
              <span>
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary
                  "
                  icon="edit"
                  class="edit-custom-view__button"
                  on-click={() => this.editRow(actions.id)}
                ></woot-button>
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="delete"
                  class="delete-custom-view__button"
                  on-click={() => this.openDeletePopup(actions)}
                ></woot-button>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      actions: 'aiActionManage/getActions',
      uiFlags: 'aiActionManage/getUIFlags',
    }),
    actionsList() {
      if (this.actions.data) {
        return this.actions.data.map(action => {
          return {
            id: action.id,
            name: action.name,
            content_type: this.contentTypes[action.content_type],
            text: action.text,
          };
        });
      }
      return '';
    },
    canRenderTable() {
      return this.actionsList && this.actionsList.length > 0;
    },
    deleteConfirmText() {
      return `${this.$t('AI_ACTION_MANAGE_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedAction.name
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AI_ACTION_MANAGE_SETTINGS.DELETE.CONFIRM.NO')} ${
        this.selectedAction.name
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedAction.name}?`;
    },
  },
  created() {
    this.$store.dispatch('aiActionManage/get', {
      page: 1,
      contentType: 0,
    });
  },
  methods: {
    onPageChange(page) {
      this.pageIndex = page;
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('aiActionManage/get', {
        page,
      });
    },
    editRow(id) {
      router.push({
        path: this.addAccountScoping(`settings/ai-action-manage/${id}/edit`),
        params: {
          page: 'edit',
          id: id,
        },
      });
    },
    openDeletePopup(action) {
      this.showDeleteConfirmationPopup = true;
      this.selectedAction = action;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteActionById(this.selectedAction.id);
    },
    async deleteActionById(id) {
      this.showVeLoading();
      try {
        await this.$store.dispatch('aiActionManage/delete', id);
        await this.$store.dispatch('aiActionManage/get', {
          page: this.pageIndex,
        });
        if (!this.actions.data.length && this.pageIndex > 1) {
          this.onPageChange(1);
        }
        this.showAlert(
          this.$t('AI_ACTION_MANAGE_SETTINGS.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          this.$t('AI_ACTION_MANAGE_SETTINGS.DELETE.API.ERROR_MESSAGE')
        );
      } finally {
        this.closeVeLoading();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ve-pagination {
  margin-top: var(--space-one);
  display: flex;
  justify-content: flex-end;
  background-color: transparent !important;
}
</style>
