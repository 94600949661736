<template>
  <li :class="alignBubble">
    <div :class="wrapClass">
      <div>
        <div class="measure">{{ data.content }}</div>
        <div>
          <textarea
            ref="textarea"
            v-model="data.content"
            :class="bubbleClass"
            :style="{ height: autoHeight }"
            @input="autoHeight"
          />
          <div class="label-name">
            <span
              v-if="showEditEntrance"
              @click.prevent="JumpToIntentEditor(data.intent.id)"
            >
              {{ $t('AI_STORY_MANAGE_SETTINGS.EDIT_FLOW.LABEL.INTENT') }}:{{
                intentName
              }}
            </span>
            <span v-if="showEditEntrance">
              {{ $t('AI_STORY_MANAGE_SETTINGS.EDIT_FLOW.LABEL.ACTION') }}:{{
                actionName
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="context-menu-wrap">
      <woot-button
        icon="delete"
        variant="clear"
        color-scheme="alert"
        @click.prevent="handleDelete(data.id)"
      />
    </div>
  </li>
</template>
<script>
import messageFormatterMixin from 'shared/mixins/messageFormatterMixin';
import alertMixin from 'shared/mixins/alertMixin';
import { MESSAGE_TYPE } from 'shared/constants/messages';
import router from '../../../../index';
import accountMixin from 'dashboard/mixins/account';
export default {
  mixins: [alertMixin, messageFormatterMixin, accountMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    deleteMessage: {
      type: Function,
      default: () => {},
    },
    onSubmit: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      isEditable: false,
      showContextMenu: false,
      hasImageError: false,
      showTranslateModal: false,
    };
  },
  computed: {
    showEditEntrance() {
      const { message_type, intent } = this.data;
      return message_type === MESSAGE_TYPE.INCOMING && intent;
    },
    intentName() {
      const intentName = this.data.intent.name;
      if (intentName.length > 20) {
        return intentName.slice(0, 20) + '...';
      }
      return intentName;
    },
    actionName() {
      const actionName = this.data?.action?.name;
      if (actionName && actionName.length > 20) {
        return actionName.slice(0, 20) + '...';
      }
      return actionName;
    },
    contentType() {
      const {
        data: { content_type: contentType },
      } = this;
      return contentType;
    },
    alignBubble() {
      const { message_type: messageType } = this.data;

      const isCentered = messageType === MESSAGE_TYPE.ACTIVITY;
      const isLeftAligned = messageType === MESSAGE_TYPE.INCOMING;
      const isRightAligned =
        messageType === MESSAGE_TYPE.OUTGOING ||
        messageType === MESSAGE_TYPE.TEMPLATE;
      return {
        center: isCentered,
        left: isLeftAligned,
        right: isRightAligned,
      };
    },
    isBubble() {
      return [0, 1, 3].includes(this.data.message_type);
    },
    wrapClass() {
      return {
        wrap: this.isBubble,
        'activity-wrap': !this.isBubble,
      };
    },
    bubbleClass() {
      return {
        bubble: this.isBubble,
      };
    },
    contextMenuPosition() {
      const { message_type: messageType } = this.data;
      return messageType ? 'right' : 'left';
    },
  },
  mounted() {
    // 初始设置高度
    this.autoHeight();
  },
  methods: {
    async JumpToIntentEditor(id) {
      await this.$store.dispatch('intentManage/show', { id });
      await Promise.resolve().then(() => {
        router.push({
          path: this.addAccountScoping(`settings/intent-manage/${id}/edit`),
          params: {
            page: 'edit',
            id: id,
          },
          query: {
            fromStory: true,
          },
        });
      });
    },
    autoHeight() {
      const textarea = this.$refs.textarea;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    },
    handleDelete(id) {
      this.deleteMessage(id);
    },
  },
};
</script>
<style lang="scss" scoped>
textarea {
  resize: none;
  overflow-y: hidden;
}
.label-name {
  position: absolute;
  :nth-child(1) {
    cursor: pointer;
  }
  span {
    display: block;
    margin: 0 !important;
    margin: 5px 0;
    margin-top: var(--space-one);
    color: var(--s-500);
    font-size: var(--font-size-small);
  }
}

.wrap {
  > .bubble {
    min-width: 128px;
  }
}
.measure {
  visibility: hidden;
  height: 0;
}

li.left,
li.right {
  display: flex;
  align-items: flex-end;

  &:hover .button--delete-message {
    visibility: visible;
  }
}

li.left.has-tweet-menu .context-menu {
  margin-bottom: var(--space-medium);
}
li.right .context-menu-wrap {
  margin-left: auto;
}

li.right {
  flex-direction: row-reverse;
  justify-content: flex-end;

  .wrap.is-pending {
    margin-left: auto;
  }

  .wrap.is-failed {
    display: flex;
    flex-direction: row-reverse;
    align-items: flex-end;
    margin-left: auto;
  }
}

.has-context-menu {
  background: var(--color-background);
  .button--delete-message {
    visibility: visible;
  }
}

.context-menu {
  position: relative;
}
</style>
