<template>
  <div v-if="tableData.length">
    <woot-modal
      :show.sync="isFallbackResponseModalOpen"
      :on-close="hideFallbackResponseModal"
    >
      <time-based-fallback-Modal
        :model-configs="modelConfigs"
        :on-close="hideFallbackResponseModal"
        @updateTable="updateSchedule"
      />
    </woot-modal>
    <ve-table
      max-height="calc(100vh - 500px)"
      :columns="columns"
      :border-x="true"
      :border-y="true"
      :table-data="tableData"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import TimeBasedFallbackModal from './configModal/TimeBasedFallbackModal.vue';
import { formatTime } from './helpers/customHour';
export default {
  components: {
    VeTable,
    TimeBasedFallbackModal,
  },
  props: {
    modelConfigs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isFallbackResponseModalOpen: false,
      options: [],
      columns: [
        {
          title: this.$t('AI_CONFIGS_SETTINGS.LIST_HEADER.DAYS'),
          field: 'days',
          key: 'days',
        },
        {
          title: this.$t('AI_CONFIGS_SETTINGS.LIST_HEADER.FALLBACK_RESPONSE'),
          key: 'fallbackResponse',
          field: 'fallbackResponse',
        },
        {
          field: '',
          key: 'e',
          title: this.$t('AI_CONFIGS_SETTINGS.LIST_HEADER.ACTIONS'),
          width: 90,
          center: 'left',
          renderBodyCell: ({ row: f }) => {
            return (
              <span>
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary
                  "
                  icon="edit"
                  class="edit-custom-view__button"
                  on-click={e => {
                    e.preventDefault();
                    this.updateFallbackResponse(f.fallbackResponse, f.replyKey);
                  }}
                ></woot-button>
                &nbsp;
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="delete"
                  class="delete-custom-view__button"
                  on-click={e => {
                    e.preventDefault();
                    this.deleteFallbackResponse(f.replyKey);
                  }}
                ></woot-button>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    tableData() {
      return this.options
        .filter(option => Object.keys(option).length > 1)
        .map(option => {
          const dayStr = Object.keys(option)
            .filter(key => key !== 'text' && key !== 'replyKey')
            .map(key => {
              const timeStrs = option[key]
                .filter(time => time !== '')
                .map(time => {
                  const from = formatTime(time.from);
                  const to = formatTime(time.to);
                  return `${key.slice(0, 3)} ${from}-${to}`;
                });
              return timeStrs
                .join(', ')
                .split(',')
                .join(', ');
            })
            .filter(str => str.length > 0);
          return {
            days: dayStr,
            fallbackResponse: option.text,
            replyKey: option.replyKey,
          };
        })
        .map(item => {
          item.days = item.days.map(str =>
            str.endsWith(',') ? str : str + ','
          );
          return item;
        });
    },
  },
  mounted() {
    this.initializeConfigs();
  },
  methods: {
    initializeConfigs() {
      this.options = this.modelConfigs.utter_default.options;
    },
    updateSchedule(text, replyKey) {
      const findItem = this.options.find(
        option => option.replyKey === replyKey
      );
      const index = this.options.indexOf(findItem);
      findItem.text = text;
      this.$set(this.options, index, findItem);
    },
    showFallbackResponseModal() {
      this.isFallbackResponseModalOpen = true;
    },
    hideFallbackResponseModal() {
      this.isFallbackResponseModalOpen = false;
    },
    updateFallbackResponse(text, replyKey) {
      this.showFallbackResponseModal();
      this.$emit('update-options-item', text, replyKey);
    },
    deleteFallbackResponse(replyKey) {
      this.options = this.options.filter(
        option => option.replyKey !== replyKey
      );
      this.$emit('delete-options-item', this.options);
    },
  },
};
</script>
