<template>
  <action-form
    v-if="showActionForm"
    :on-submit="editAction"
    :submit-in-progress="showLoading"
    :submit-button-text="
      $t('AI_ACTION_MANAGE_SETTINGS.EDIT_FLOW.UPDATE.BUTTON_TEXT')
    "
    :form-data="actionData"
  />
</template>

<script>
import ActionForm from '../ActionForm.vue';
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    ActionForm,
  },
  mixins: [alertMixin],
  data() {
    return {
      showLoading: false,
    };
  },
  computed: {
    actionData() {
      const { responseId } = this.$route.params;
      const action = this.$store.getters['aiActionManage/getAction'](
        responseId
      );
      return action;
    },
    showActionForm() {
      const { id } = this.actionData;
      return id;
    },
  },
  methods: {
    async editAction(data) {
      this.showLoading = true;
      try {
        const { responseId } = this.$route.params;
        await this.$store.dispatch('aiActionManage/update', {
          id: responseId,
          ...data,
        });
        this.showAlert(
          this.$t('AI_ACTION_MANAGE_SETTINGS.EDIT_FLOW.API.SUCCESS_MESSAGE')
        );
        this.showLoading = false;
        router.replace({
          name: 'settings_ai_action_manage_list',
        });
      } catch (error) {
        this.showAlert(
          this.$t('AI_ACTION_MANAGE_SETTINGS.EDIT_FLOW.API.ERROR_MESSAGE')
        );
        this.showLoading = false;
      }
    },
  },
};
</script>
