<template>
  <div>
    <form class="row" @submit.prevent="addExample">
      <div class="medium-12 columns">
        <div class="intent-label">
          <label :class="{ error: $v.example.$error }">
            {{ $t('INTENT_MANAGE_SETTINGS.MODAL.MULTISELECT.LABEL.NAME') }}
          </label>
          <multiselect
            v-model="selectIntent"
            :options="intentItems"
            :placeholder="
              $t('INTENT_MANAGE_SETTINGS.MODAL.MULTISELECT.SELECT_ONE_INTENT')
            "
            :max-height="150"
            label="name"
            track-by="id"
            selected-label
            deselect-label=""
            :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
            @select="handleSelect"
          />
        </div>
        <label :class="{ error: $v.example.$error }">
          {{ $t('INTENT_MANAGE_SETTINGS.LIST_HEADER.EXAMPLE') }}
          <input
            v-model.trim="example"
            type="text"
            :class="{ error: $v.example.$error }"
            :placeholder="
              $t('INTENT_MANAGE_SETTINGS.MODAL.DESCRIPTION.EXAMPLE_PLACEHOLDER')
            "
            @input="$v.example.$touch"
          />
        </label>
      </div>
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :button-text="$t('INTENT_MANAGE_SETTINGS.FORM.SUBMIT_ADD')"
            :loading="showLoading"
            :disabled="
              $v.intentId.$invalid || $v.example.$invalid || showLoading
            "
          />
          <button class="button clear" @click.prevent="onClose">
            {{ $t('INTENT_MANAGE_SETTINGS.FORM.CANCEL_BUTTON_TEXT') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    WootSubmitButton,
  },
  validations: {
    intentId: {
      required,
    },
    example: {
      required,
    },
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    intentItems: {
      type: Array,
      default: () => [],
    },
    exampleContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectIntent: [],
      intentId: null,
      example: this.exampleContent || '',
      showLoading: false,
    };
  },
  methods: {
    handleSelect(selectedRange) {
      this.intentId = selectedRange.id;
    },
    async addExample() {
      try {
        this.showLoading = true;
        await this.$store.dispatch('intentManage/createExample', {
          intent_id: this.intentId,
          example: this.example.split('\n').join(''),
        });
        this.showLoading = false;
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.FORM.API.ADD_SUCCESS_MESSAGE')
        );
        this.onClose();
      } catch (error) {
        this.showLoading = false;
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.FORM.API.ADD_ERROR_MESSAGE')
        );
        this.onClose();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.intent-label {
  padding: var(--space-one) 0 0;
}
</style>
