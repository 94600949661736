<template>
  <modal :show.sync="show" size="medium" :on-close="onClose">
    <div class="column content-box">
      <intent-tabs
        :tabs="intentTabItems"
        @tab-change="tab => (selectedTab = tab)"
      />
      <div id="loading-container">
        <add-example
          v-if="filterAddExample"
          :on-close="onClose"
          :example-content="exampleContent"
          :intent-items="intentItems"
        />
      </div>
      <div>
        <create-intent
          v-if="filterCreateIntent"
          :on-close="onClose"
          :example-content="exampleContent"
        />
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import Modal from '../../../../../components/Modal.vue';
import IntentTabs from './IntentTabs.vue';
import CreateIntent from './CreateIntent.vue';
import AddExample from './AddExample.vue';
import { TABS_TYPE } from '../helpers/constants';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
export default {
  components: {
    IntentTabs,
    Modal,
    CreateIntent,
    AddExample,
  },
  mixins: [veLoadingMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    exampleContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedTab: TABS_TYPE.ADD_EXAMPLE,
      showLoading: false,
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      intentData: 'intentManage/getIntents',
      uiFlags: 'intentManage/getUIFlags',
    }),
    intentItems() {
      let intentArr = Object.values(this.intentData);
      if (intentArr) {
        let newIntentArr = intentArr.map(intent => ({
          id: intent.id,
          name: intent.name,
          example: `${
            intent?.examples?.map(({ example }) => {
              return example;
            })[0]
          }`,
          rowKey: intent.id,
        }));
        return newIntentArr;
      }
      return {};
    },
    intentTabItems() {
      const ASSIGNEE_TYPE_TAB_KEYS = {
        addExample: TABS_TYPE.ADD_EXAMPLE,
        createIntent: TABS_TYPE.CREATE_INTENT,
      };
      return Object.keys(ASSIGNEE_TYPE_TAB_KEYS).map(key => {
        return {
          key,
          name: this.$t(`INTENT_MANAGE_SETTINGS.TYPE_TABS.${key}`),
        };
      });
    },
    filterAddExample() {
      return this.selectedTab === 'addExample' || '';
    },
    filterCreateIntent() {
      return this.selectedTab === 'createIntent' || '';
    },
  },
  watch: {
    uiFlags({ isFetching }) {
      if (isFetching) {
        this.showVeLoading();
      } else {
        this.closeVeLoading();
      }
    },
  },
  created() {
    this.$store.dispatch('intentManage/getAll');
  },
};
</script>
<style lang="scss" scoped></style>
