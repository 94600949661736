export const SET_INTENT_UI_FLAG = 'SET_INTENT_UI_FLAG';
export const CLEAR_INTENTS = 'CLEAR_INTENTS';
export const SET_INTENTS = 'SET_INTENTS';
export const SET_INTENT_ITEM = 'SET_INTENT_ITEM';
export const EDIT_INTENT = 'EDIT_INTENT';
export const DELETE_INTENT = 'DELETE_INTENT';
export const SET_INTENTS_COUNT = 'SET_INTENTS_COUNT';
export const SET_INTENTS_DIALOG = 'SET_INTENTS_DIALOG';
export const UPDATE_RESPONSE = 'UPDATE_RESPONSE';
export const SET_GPT_IMPORTED_INTENT_STATUS = 'SET_GPT_IMPORTED_INTENT_STATUS';
