import { MESSAGE_TYPE } from 'shared/constants/messages';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  mixins: [alertMixin],
  computed: {
    checkStoryRepeat() {
      const { ask, reply } = this.processedMessages.reduce(
        (acc, { content, message_type }) => {
          const key = message_type === MESSAGE_TYPE.INCOMING ? 'ask' : 'reply';
          acc[key].push(content);
          return acc;
        },
        { ask: [], reply: [] }
      );
      if (ask.length !== reply.length) {
        return false;
      }
      return true;
    },
    hasStepEmpty() {
      return this.formatMessages.some(_ => _.ask === '' || _.reply === '');
    },
  },
  methods: {
    // 将steps转换为文本
    formatStepsToText(steps) {
      return steps
        .reduce((acc, { ask, reply }) => {
          if (acc.length >= 4) return acc;
          if (ask) acc.push(`${ask} =>`);
          if (reply) acc[acc.length - 1] += ` ${reply} \n`;
          return acc;
        }, [])
        .join('\n');
    },
    shouldShowInvalidAlert() {
      return this.$v.$invalid || !this.checkStoryRepeat || this.hasStepEmpty;
    },
    showInvalidAlert() {
      if (this.$v.$invalid) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.MODAL.API.CHECK_NAME')
        );
      } else if (!this.checkStoryRepeat) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.MODAL.API.CHECK_MESSAGE')
        );
      } else if (this.hasStepEmpty) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.MODAL.API.EMPTY_MESSAGE')
        );
      }
    },
    showSuccessAlert() {
      this.showAlert(this.$t('AI_STORY_MANAGE_SETTINGS.API.SUCCESS_MESSAGE'));
    },
    showErrorAlert() {
      this.showAlert(this.$t('AI_STORY_MANAGE_SETTINGS.API.ERROR_MESSAGE'));
    },
  },
};
