<template>
  <div>
    <woot-loading-state
      v-if="uiFlags.isFetching"
      :message="$t('AI_STORY_MANAGE_SETTINGS.LOADING')"
    />
    <div v-else>
      <empty-state
        v-if="!storyList.length"
        :title="$t('AI_STORY_MANAGE_SETTINGS.EMPTY_EXAMPLE')"
      />
    </div>
    <div v-show="canRenderTable" id="loading-container">
      <ve-table
        max-height="calc(100vh - 200px)"
        :columns="columns"
        :table-data="storyList"
        :border-x="true"
        :border-y="true"
      />
      <ve-pagination
        class="ve-pagination"
        :total="stories.count"
        :page-index="pageIndex"
        :layout="['total', 'prev', 'pager', 'next', 'jumper']"
        @on-page-number-change="onPageChange"
      />
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('AI_STORY_MANAGE_SETTINGS.DELETE.CONFIRM.TITLE')"
      :message="$t('AI_STORY_MANAGE_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { VeTable } from 'vue-easytable';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import { VePagination } from 'vue-easytable';
import { mapGetters } from 'vuex';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import router from '../../../index';
import storyMixin from 'dashboard/mixins/storyMixin';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
export default {
  components: {
    VeTable,
    EmptyState,
    VePagination,
  },
  mixins: [accountMixin, alertMixin, storyMixin, veLoadingMixin],
  data() {
    return {
      selectedContentType: this.$t(
        'AI_STORY_MANAGE_SETTINGS.SELECT.CONTENT_TYPE'
      )[0],
      contentTypeGroup: this.$t('AI_STORY_MANAGE_SETTINGS.SELECT.CONTENT_TYPE'),
      pageIndex: 1,
      showDeleteConfirmationPopup: false,
      selectedStory: {},
      columns: [
        {
          field: 'id',
          key: 'id',
          title: this.$t('AI_STORY_MANAGE_SETTINGS.LIST_HEADER.ID'),
          align: 'left',
          width: '5%',
        },
        {
          field: 'title',
          key: 'title',
          title: this.$t('AI_STORY_MANAGE_SETTINGS.LIST_HEADER.NAME'),
          align: 'left',
          width: 180,
          ellipsis: {
            showTitle: true,
            lineClamp: 3,
          },
        },
        {
          field: 'steps',
          key: 'steps',
          title: this.$t('AI_STORY_MANAGE_SETTINGS.LIST_HEADER.STORY_EXAMPLE'),
          align: 'left',
          width: '',
          ellipsis: {
            showTitle: false,
            lineClamp: 5,
          },
        },
        {
          field: '',
          key: 'e',
          title: this.$t('AI_STORY_MANAGE_SETTINGS.LIST_HEADER.ACTIONS'),
          width: 90,
          center: 'left',
          renderBodyCell: ({ row: stories }) => {
            return (
              <span>
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="secondary
                  "
                  icon="edit"
                  class="edit-custom-view__button"
                  on-click={() => this.editRow(stories.id)}
                ></woot-button>
                &nbsp;
                <woot-button
                  size="small"
                  variant="smooth"
                  color-scheme="alert"
                  icon="delete"
                  class="delete-custom-view__button"
                  on-click={() => this.openDeletePopup(stories)}
                ></woot-button>
              </span>
            );
          },
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      stories: 'aiStoryManage/getStories',
      uiFlags: 'aiStoryManage/getUIFlags',
    }),
    storyList() {
      let storiesArr = this.stories.data;
      if (storiesArr) {
        let newStoriesArr = storiesArr.map(story => ({
          id: story.id,
          title: story.title,
          steps: `${this.formatStepsToText(story.steps)}`,
        }));
        return newStoriesArr;
      }
      return {};
    },
    canRenderTable() {
      return (
        this.storyList && this.storyList.length > 0 && !this.uiFlags.isFetching
      );
    },
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('AI_STORY_MANAGE_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedStory.title
      }`;
    },
    deleteRejectText() {
      return `${this.$t('AI_STORY_MANAGE_SETTINGS.DELETE.CONFIRM.NO')} ${
        this.selectedStory.title
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedStory.title}?`;
    },
  },

  created() {
    this.$store.dispatch('aiStoryManage/getStories', {
      page: this.pageIndex,
      contentType: this.contentTypeGroup[0].type,
    });
  },
  methods: {
    onPageChange(page) {
      this.pageIndex = page;
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('aiStoryManage/getStories', {
        page,
        contentType: this.selectedContentType.type,
      });
    },
    editRow(id) {
      router.push({
        path: this.addAccountScoping(`settings/ai-story-manage/${id}/edit`),
        params: {
          page: 'edit',
          id: id,
        },
      });
    },
    handleSelectType(selectedType) {
      this.selectedContentType = selectedType;
      this.pageIndex = 1;
      this.$store.dispatch('aiStoryManage/getStories', {
        page: this.pageIndex,
        contentType: this.selectedContentType.type,
      });
    },
    openDeletePopup(story) {
      this.showDeleteConfirmationPopup = true;
      this.selectedStory = story;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteStoryById(this.selectedStory.id);
    },
    async deleteStoryById(id) {
      this.showVeLoading();
      try {
        await this.$store.dispatch('aiStoryManage/deleteStory', id);
        await this.$store.dispatch('aiStoryManage/getStories', {
          page: this.pageIndex,
          contentType: this.selectedContentType.type,
        });
        if (!this.stories.data.length && this.pageIndex > 1) {
          this.onPageChange(1);
        }
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.DELETE.API.ERROR_MESSAGE')
        );
      } finally {
        this.closeVeLoading();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.ve-pagination {
  margin-top: var(--space-one);
  display: flex;
  justify-content: flex-end;
  background-color: transparent !important;
}
</style>
