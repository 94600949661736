<template>
  <document-form
    v-if="showDocumentForm"
    :on-submit="editDocument"
    :submit-in-progress="showLoading"
    :submit-button-text="
      $t('KNOWLEDGE_BASE_SETTINGS.EDIT_FLOW.UPDATE.BUTTON_TEXT')
    "
    :form-data="documentData"
  />
</template>

<script>
import documentForm from '../documentForm';
import router from '../../../../index';
import alertMixin from 'shared/mixins/alertMixin';

export default {
  components: {
    documentForm,
  },
  mixins: [alertMixin],
  data() {
    return {
      showLoading: false,
    };
  },

  computed: {
    documentData() {
      const { storyId } = this.$route.params;
      const stories = this.$store.getters['aiStoryManage/getStory'](storyId);
      return stories;
    },
    showDocumentForm() {
      const { id } = this.documentData;
      return id;
    },
  },
  methods: {
    async editDocument(data) {
      this.showLoading = true;
      try {
        const { storyId } = this.$route.params;
        await this.$store.dispatch('aiStoryManage/updateStory', {
          id: storyId,
          ...data,
        });
        this.showAlert(
          this.$t('KNOWLEDGE_BASE_SETTINGS.EDIT_FLOW.API.SUCCESS_MESSAGE')
        );
        this.showLoading = false;
        router.replace({
          name: 'settings_knowledge_base_list',
        });
      } catch (error) {
        this.showAlert(
          this.$t('KNOWLEDGE_BASE_SETTINGS.EDIT_FLOW.API.ERROR_MESSAGE')
        );
        this.showLoading = false;
      }
    },
  },
};
</script>
