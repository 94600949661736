<template>
  <div class="wizard-body column content-box">
    <div class="small-6 columns">
      <h3>
        {{
          $t(
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.TITLE'
          )
        }}
      </h3>
      <p style="line-height:25px">
        {{
          $t(
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.ROW_1'
          )
        }}
      </p>
    </div>
    <div v-if="!showLoading">
      <textarea
        v-model="content"
        :rows="15"
        type="text"
        :maxlength="maxTextareaLength"
      />
      <p v-if="content.length >= maxTextareaLength" style="color: red;">
        {{
          $t(
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.EXCEED_LENGTH'
          )
        }}
      </p>
      <div v-if="content">
        <p>
          {{
            $t(
              'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.CURRENT_TEXT_LENGTH'
            )
          }}{{ content.length }}
        </p>
        <p>段落总数:{{ segments }}</p>
        <p>
          {{
            $t(
              'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.DESCRIPTION.EXPECTED_TIME',
              { estimatedWaitingTime }
            )
          }}
        </p>
      </div>
    </div>
    <woot-loading-state v-if="showLoading" :message="isImportingText" />
    <div class="columns small-4 medium-2">
      <label>
        {{
          $t(
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.LABEL.OUTPUT_IN'
          )
        }}
      </label>
      <select v-model="locale" :disabled="showLoading">
        <option
          v-for="lang in languagesSortedByCode"
          :key="lang.name"
          :value="lang.name"
        >
          {{ lang.name }}
        </option>
      </select>
    </div>
    <div class="medium-12 columns">
      <woot-button
        :loading="showLoading"
        :disabled="isButtonDisabled"
        @click="generateStoryWithGPT"
      >
        {{ buttonText }}(生成话术案例)
      </woot-button>
      <woot-button
        :loading="showLoading"
        :disabled="isButtonDisabled"
        @click="generateIntentWithGPT"
      >
        {{ buttonText }}(生成意图)
      </woot-button>
      <woot-confirm-modal
        ref="confirmDialog"
        :title="generateIntentTitle"
        :description="generateIntentMessage"
      />
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { mapGetters } from 'vuex';
import { required } from 'vuelidate/lib/validators';
import configMixin from 'shared/mixins/configMixin';

const defaultLanguage = '中文 (zh-CN)';
export default {
  components: {},
  mixins: [alertMixin, configMixin],
  validations: {
    content: {
      required,
    },
  },

  data() {
    return {
      currentRequests: 0,
      maxTextareaLength: 10000,
      showLoading: false,
      hideTotalRequests: false,
      generateIntentMessage: this.$t(
        'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.CONFIRM.MESSAGE'
      ),
      generateIntentTitle: this.$t(
        'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.HEADER'
      ),
      content: '',
      locale: defaultLanguage,
      intent: {
        name: '',
        response: { text: '' },
        examples: [
          {
            example: '',
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      uiFlags: 'aiStoryManage/getUIFlags',
      counters: 'aiStoryManage/getCounters',
      intentImportedStatus: 'intentManage/getGptImportedIntentStatus',
    }),
    buttonText() {
      return this.$t(
        'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.BUTTON_TEXT'
      );
    },
    isImportingText() {
      return this.uiFlags.isImporting
        ? this.$t(
            `INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.LOADING_TEXT.IMPORTING`
            // {
            //   importedCount: this.counters.importedCount,
            //   totalRequests: this.totalRequests,
            // }
          )
        : this.$t(
            `INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.LOADING_TEXT.GENERATING`
            // {
            //   generatedCount: this.counters.generatedCount,
            //   totalRequests: this.totalRequests,
            // }
          );
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
    isButtonDisabled() {
      return (
        this.showLoading ||
        this.content.length >= this.maxTextareaLength ||
        this.$v.content.$invalid
      );
    },
    segments() {
      return this.getSegments(this.content).length;
    },
    estimatedWaitingTime() {
      return (this.totalRequests * 30) / 60;
    },
    totalRequests() {
      return Math.ceil(this.content.length / 1000);
    },
  },
  watch: {
    intentImportedStatus() {
      if (this.intentImportedStatus) {
        this.showLoading = false;
        this.showAlert(
          this.$t(
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.SUCCESS_MESSAGE'
          )
        );
      }
    },
  },
  methods: {
    // 将长文本分段的方法
    getSegments(content) {
      const segments = [];
      const segmentLength = 1000; // 每段长度为1000
      let startIndex = 0;
      for (let i = 0; i < content.length; i += 1) {
        if (i - startIndex >= segmentLength) {
          // 当达到每段长度时，分割一段
          segments.push(content.slice(startIndex, i).trim());
          startIndex = i;
          // 只有当当前位置是一个换行符，且前一个位置不是换行符，同时当前位置不是文章的最后一个字符时，才会被认为是一个段落的分隔符。
        } else if (
          content[i] === '\n' &&
          content[i - 1] !== '\n' &&
          i !== content.length - 1
        ) {
          // 当遇到换行符且不是连续的两个换行符时，分割一段
          segments.push(content.slice(startIndex, i).trim());
          startIndex = i;
        }
      }
      if (startIndex < content.length) {
        // 处理最后一段
        segments.push(content.slice(startIndex).trim());
      }
      return segments;
    },
    // 调用gpt生成意图
    async generateIntentWithGPT() {
      const ok = await this.$refs.confirmDialog.showConfirmation();
      if (ok) {
        this.showLoading = true;
        try {
          const segments = this.getSegments(this.content);
          const fetchTasks = segments.map(productDescription => {
            return this.$store.dispatch(
              'intentManage/createIntentFromGptAnswer',
              {
                productDescription, // 当前请求的段落内容
                language: this.locale, // 语言类型
              }
            );
          });
          await Promise.all(fetchTasks)
            .then(() => {
              // this.showLoading = false;
              // this.showAlert(
              //   this.$t(
              //     'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.SUCCESS_MESSAGE'
              //   )
              // );
            })
            .catch(error => {
              this.showLoading = false;
              this.showAlert(
                this.$t(
                  'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.ERROR_MESSAGE',
                  { error }
                )
              );
              throw new Error(error);
            });
        } catch (error) {
          this.showLoading = false;
          this.showAlert(
            this.$t(
              'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.ERROR_MESSAGE',
              { error }
            )
          );
          throw new Error(error);
        } finally {
          this.content = '';
        }
      }
    },
    // 调用gpt生成话术案例
    async generateStoryWithGPT() {
      try {
        this.showLoading = true;
        const segments = this.getSegments(this.content);
        // 遍历所有内容段落，创建对应的请求任务
        const fetchTasks = segments.map(productDescription => {
          this.currentRequests += 1; // 增加请求计数器
          return this.$store.dispatch('aiStoryManage/fetchGptTurboAssistant', {
            productDescription, // 当前请求的段落内容
            language: this.locale, // 语言类型
          });
        });
        await Promise.all(fetchTasks)
          .then(() => {
            this.showLoading = false;
            this.showAlert(
              this.$t(
                'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.SUCCESS_MESSAGE'
              )
            );
          })
          .catch(error => {
            this.showLoading = false;
            this.showAlert(
              this.$t(
                'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.API.ERROR_MESSAGE',
                { error }
              )
            );
            throw new Error(error);
          });
      } catch (error) {
        throw new Error(error);
      } finally {
        this.content = '';
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
