<template>
  <div class="">
    <div>
      <form class="" @submit.prevent="handleSubmit">
        <div class="medium-4">
          <label>
            {{ $t('AI_CONFIGS_SETTINGS.CONTAINER.LANGUAGE') }}
            <select v-model="locale">
              <option
                v-for="lang in languagesSortedByCode"
                :key="lang.name"
                :value="lang.name"
              >
                {{ lang.name }}
              </option>
            </select>
            <span class="message">
              {{ $t('GENERAL_SETTINGS.FORM.LANGUAGE.ERROR') }}
            </span>
          </label>
          <label>
            {{ $t('AI_CONFIGS_SETTINGS.CONTAINER.DEFAULT_FALLBACK_RESPONSE') }}
            <textarea v-model.trim="utter_default.text" rows="4" type="text" />
          </label>
          <div class="add-fallback-reply">
            <woot-modal
              :show.sync="isFallbackResponseModalOpen"
              :on-close="hideFallbackResponseModal"
            >
              <time-based-fallback-modal
                v-if="isFallbackResponseModalOpen"
                :model-configs="modelConfigs"
                :on-close="hideFallbackResponseModal"
                :is-new-built="isNewBuilt"
              />
            </woot-modal>
            <span>{{
              $t('AI_CONFIGS_SETTINGS.CONTAINER.ADD_BUTTON_LABEL')
            }}</span>
            <woot-button
              size="small"
              color-scheme="primary"
              icon="add"
              variant="smooth"
              @click.prevent="showFallbackResponseModal"
            >
              Add
            </woot-button>
          </div>
          <div class="timezone-input-wrap">
            <label>
              {{ $t('AI_CONFIGS_SETTINGS.CONTAINER.TIMEZONE_LABEL') }}
            </label>
            <multiselect
              v-model="utter_default.timezone"
              :options="timeZones"
              deselect-label=""
              select-label=""
              selected-label=""
              :show-labels="false"
              :close-on-select="true"
              :placeholder="$t('AI_CONFIGS_SETTINGS.BUSINESS_HOURS.DAY.CHOOSE')"
              :allow-empty="false"
            />
          </div>
        </div>
        <div>
          <schedule-table
            :model-configs="modelConfigs"
            @delete-options-item="handleDeleteResponse"
            @update-options-item="handleUpdateResponse"
          />
        </div>
        <div class="time-interval-wrapper">
          <label>
            {{ $t('AI_CONFIGS_SETTINGS.CONTAINER.TIME_INTERVAL') }}
          </label>
          <div class="medium-12 small-8 time-interval-input">
            <input
              v-model="intervalInMinutes"
              type="number"
              class="small-input"
              @keydown.enter.prevent
            />
            <p>{{ $t('AI_CONFIGS_SETTINGS.CONTAINER.MINUTES') }}</p>
          </div>
        </div>
        <div class="description-fallback">
          <span>
            {{ $t('AI_CONFIGS_SETTINGS.CONTAINER.DESCRIPTION') }}
          </span>
        </div>
        <div class="modal-footer">
          <div class="medium-12 columns">
            <woot-submit-button
              :button-text="this.$t('AI_CONFIGS_SETTINGS.SUBMIT')"
              :loading="showLoading"
            />
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  defaultTimeSlot,
  timeZoneOptions,
} from 'dashboard/routes/dashboard/settings/inbox/helpers/businessHour';
import configMixin from 'shared/mixins/configMixin';
import alertMixin from 'shared/mixins/alertMixin';
import TimeBasedFallbackModal from './configModal/TimeBasedFallbackModal.vue';
import ScheduleTable from './ScheduleTable.vue';
const defaultLanguage = 'English (en)';
export default {
  components: { ScheduleTable, TimeBasedFallbackModal },
  mixins: [alertMixin, configMixin],
  props: {
    modelConfigs: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      id: '',
      name: '',
      locale: defaultLanguage,
      domain: '',
      isFallbackResponseModalOpen: false,
      utter_default: {},
      timeSlots: [...defaultTimeSlot],
      show: true,
      showLoading: false,
      isNewBuilt: true,
    };
  },
  computed: {
    intervalInMinutes: {
      get() {
        return Math.floor(this.utter_default.time_interval / 60);
      },
      set(value) {
        this.utter_default.time_interval = value * 60;
      },
    },
    timeZones() {
      return timeZoneOptions().map(option => option.value);
    },
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
  },

  created() {
    this.initializeConfigs();
  },
  methods: {
    onSlotUpdate(slotIndex, slotData) {
      this.timeSlots = this.timeSlots.map(item =>
        item.day === slotIndex ? slotData : item
      );
    },
    handleDeleteResponse(newOptions) {
      this.modelConfigs.utter_default.options = newOptions;
    },
    handleUpdateResponse(editText, replyKey) {
      this.modelConfigs.utter_default.timeConfigText = editText;
      this.modelConfigs.utter_default.replyKey = replyKey;
    },
    showFallbackResponseModal() {
      this.isFallbackResponseModalOpen = true;
    },
    hideFallbackResponseModal() {
      this.isFallbackResponseModalOpen = false;
    },
    initializeConfigs() {
      this.utter_default = this.modelConfigs.utter_default;
      this.locale = this.modelConfigs.language_code;
    },
    async handleUpdatedConfigs(utter_default) {
      this.showLoading = true;
      try {
        await this.$store.dispatch('aiConfigs/updateConfig', utter_default);
        this.showLoading = false;
        this.showAlert(this.$t('AI_CONFIGS_SETTINGS.API.SUCCESS_MESSAGE'));
      } catch (error) {
        this.showLoading = false;
        this.showAlert(this.$t('AI_CONFIGS_SETTINGS.API.ERROR_MESSAGE'));
      }
    },
    async handleSubmit() {
      delete this.utter_default.timeConfigText;
      delete this.utter_default.replyKey;
      await this.handleUpdatedConfigs({
        language_code: this.locale,
        utter_default: this.utter_default,
      });
      await this.$store.dispatch('validityCheck');
    },
  },
};
</script>
<style lang="scss" scoped>
.add-fallback-reply {
  display: flex;
  align-items: center;
  span {
    margin-right: var(--space-normal);
    font-size: var(--font-size-small);
    font-weight: 450;
  }
}
.description-fallback {
  margin-bottom: var(--space-normal);
  color: var(--b-500);
  font-size: var(--font-size-small);
}
.time-interval-wrapper {
  margin-top: var(--space-normal);
  .time-interval-input {
    margin: var(--space-micro) 0;
    display: flex;
    align-items: center;
    .small-input {
      max-width: var(--space-mega);
      height: var(--space-large);
    }
    > p {
      margin-left: var(--space-slab);
      margin-bottom: var(--space-slab);
      font-size: var(--font-size-default);
    }
  }
}

.reply-schedule-wrapper {
  padding: 0 0 var(--space-normal) 0;
}
.modal-footer {
  margin-bottom: var(--space-normal);
}
</style>
