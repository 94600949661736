/* global axios */
import ApiClient from './ApiClient';

export class GptIntentExamplesApi extends ApiClient {
  constructor() {
    super('gpt/gpt_intent_examples', { accountScoped: true });
  }

  getIntents({ id }) {
    return axios.get(`${this.url}/${id}/gpt/gpt_intent_examples`);
  }

  addExamples({ id }) {
    return axios.post(`${this.url}/${id}/gpt/gpt_intent_examples`);
  }
}

export default new GptIntentExamplesApi();
