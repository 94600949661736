import Vue from 'vue';
import {
  SET_INTENT_UI_FLAG,
  CLEAR_INTENTS,
  SET_INTENTS,
  SET_INTENT_ITEM,
  EDIT_INTENT,
  DELETE_INTENT,
  SET_INTENTS_COUNT,
  SET_INTENTS_DIALOG,
  UPDATE_RESPONSE,
  SET_GPT_IMPORTED_INTENT_STATUS,
} from './types';

export const mutations = {
  [SET_INTENT_UI_FLAG]: ($state, data) => {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },

  [CLEAR_INTENTS]: $state => {
    Vue.set($state, 'intentList', {});
  },

  [SET_INTENTS_COUNT]: ($state, count) => {
    $state.count = count;
  },
  [SET_INTENTS]: ($state, data) => {
    data.forEach(intent => {
      Vue.set($state.intentList, intent.id, {
        ...($state.intentList[intent.id] || {}),
        ...intent,
      });
    });
  },

  [SET_INTENT_ITEM]: ($state, data) => {
    Vue.set($state.intentList, data.id, {
      ...($state.intentList[data.id] || {}),
      ...data,
    });
  },

  [EDIT_INTENT]: ($state, data) => {
    Vue.set($state.intentList, data.id, data);
  },

  [DELETE_INTENT]: ($state, IntentId) => {
    const { [IntentId]: toDelete, ...intentList } = $state.intentList;
    Vue.set($state, 'intentList', intentList);
  },
  [SET_INTENTS_DIALOG]: ($state, data) => {
    data.forEach(intent => {
      Vue.set($state.dialogIntentList, intent.id, {
        ...($state.dialogIntentList[intent.id] || {}),
        ...intent,
      });
    });
  },
  [UPDATE_RESPONSE]: ($state, data) => {
    Vue.set($state.intentList, data.id, {
      ...($state.intentList[data.id] || {}),
      ...data,
    });
  },
  [SET_GPT_IMPORTED_INTENT_STATUS]: ($state, status) => {
    $state.importCompleted = status;
  },
};
