import {
  SET_ACTIONS,
  CLEAR_ACTIONS,
  SET_ACTIONS_UI_FLAG,
  EDIT_ACTION,
  SET_ACTION_ITEM,
} from './types';
import Vue from 'vue';
export const mutations = {
  [SET_ACTIONS]: ($state, actions) => {
    const { data } = $state.actions;
    Vue.set($state.actions, 'count', actions.count);
    actions.data.forEach(response => {
      Vue.set(data, response.id, {
        ...(data[response.id] || {}),
        ...response,
      });
    });
  },
  [CLEAR_ACTIONS]: $state => {
    Vue.set($state.actions, 'data', []);
  },
  [SET_ACTION_ITEM]: ($state, data) => {
    Vue.set($state.actions.data, data.id, {
      ...($state.actions.data[data.id] || {}),
      ...data,
    });
  },
  [SET_ACTIONS_UI_FLAG]: ($state, data) => {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [EDIT_ACTION]: ($state, data) => {
    Vue.set($state.actions.data, data.id, data);
  },
};
