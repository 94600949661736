/* global axios */
/* model:gpt-3.5-turbo-0301 */
import ApiClient from './ApiClient';

export class GptTurboApi extends ApiClient {
  constructor() {
    super('gpt/gpt_stories/message', { accountScoped: true });
  }

  TurboAssistant({ id }) {
    return axios.post(`${this.url}/${id}/gpt/gpt_stories/message`);
  }
}

export default new GptTurboApi();
