import timeZoneData from '../../inbox/helpers/timezones.json';
import parse from 'date-fns/parse';
export const defaultTimeSlot = [
  {
    day: 0,
    to: '',
    from: '',
    valid: false,
    options: [],
  },
  {
    day: 1,
    to: '',
    from: '',
    valid: false,
    options: [],
  },
  {
    day: 2,
    to: '',
    from: '',
    valid: false,
    options: [],
  },
  {
    day: 3,
    to: '',
    from: '',
    valid: false,
    options: [],
  },
  {
    day: 4,
    to: '',
    from: '',
    valid: false,
    options: [],
  },
  {
    day: 5,
    to: '',
    from: '',
    valid: false,
    options: [],
  },
  {
    day: 6,
    to: '',
    from: '',
    valid: false,
    options: [],
  },
];

export const generateTimeSlots = (step = 15) => {
  /* 
    Generates a list of time strings from 12:00 AM to next 24 hours. Each new string
     will be generated by adding `step` minutes to the previous one.
    The list is generated by starting with a random day and adding step minutes till end of the same day.
  */
  const date = new Date(1970, 1, 1);
  const slots = [];
  while (date.getDate() === 1) {
    slots.push(
      date.toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: true,
      })
    );
    date.setMinutes(date.getMinutes() + step);
  }
  return slots;
};
// 获取时区列表
export const timeZoneOptions = () => {
  return Object.keys(timeZoneData).map(key => ({
    label: key,
    value: timeZoneData[key],
  }));
};
// 将秒数转换为时间格式 hh:mm a
export const formatTime = time => {
  const totalMinutes = Math.floor(time / 60);
  const hour = Math.floor(totalMinutes / 60) % 24;
  const minute = totalMinutes % 60;
  const period = hour >= 12 ? 'PM' : 'AM'; // 判断是上午还是下午
  const hour12 = hour % 12 || 12; // 将24小时制转换为12小时制
  return `${hour12.toString().padStart(2, '0')}:${minute
    .toString()
    .padStart(2, '0')} ${period}`;
};
// 将时间格式 hh:mm a 转换为秒数
export const formatTimeToSecond = timeStr => {
  const date = parse(timeStr, 'h:mm a', new Date());
  const seconds =
    date.getHours() * 3600 + date.getMinutes() * 60 + date.getSeconds();
  return seconds;
};
// 将星期转换为对应的数字
export const getWeekdayNumber = weekday => {
  switch (weekday) {
    case 'Sunday':
      return 0;
    case 'Monday':
      return 1;
    case 'Tuesday':
      return 2;
    case 'Wednesday':
      return 3;
    case 'Thursday':
      return 4;
    case 'Friday':
      return 5;
    case 'Saturday':
      return 6;
    default:
      return -1;
  }
};
// 将数字转换为对应的星期
export const getWeekday = weekdayNum => {
  switch (weekdayNum) {
    case 0:
      return 'Sunday';
    case 1:
      return 'Monday';
    case 2:
      return 'Tuesday';
    case 3:
      return 'Wednesday';
    case 4:
      return 'Thursday';
    case 5:
      return 'Friday';
    case 6:
      return 'Saturday';
    default:
      return -1;
  }
};
