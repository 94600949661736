<template>
  <div class="column content-box">
    <woot-loading-state
      v-if="uiFlags.isFetching"
      :message="$t('AI_DEPLOYMENT_SETTINGS.LOADING')"
    />
    <div v-else>
      <empty-state
        v-if="!modelItem.length"
        :title="$t('AI_DEPLOYMENT_SETTINGS.EMPTY_DATA')"
      />
    </div>
    <woot-button
      color-scheme="success"
      class-names="button--fixed-top"
      icon="bot-add"
      :disabled="isTraining || isDeploying"
      @click="startTraining"
    >
      {{ $t('AI_DEPLOYMENT_SETTINGS.START_TRAIN') }}
    </woot-button>
    <div v-show="canRenderTable" id="loading-container">
      <ve-table
        max-height="calc(100vh - 200px)"
        :columns="columns"
        :table-data="modelItem"
        :border-x="true"
        :border-y="true"
        class="padding-1 margin-b-1"
      />
      <ve-pagination
        class="ve-pagination"
        :total="modelsCount"
        :page-index="pageIndex"
        :layout="['total', 'prev', 'pager', 'next', 'jumper']"
        @on-page-number-change="onPageChange"
      />
    </div>
    <woot-delete-modal
      :show.sync="showDeleteConfirmationPopup"
      :on-close="closeDeletePopup"
      :on-confirm="confirmDeletion"
      :title="$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.TITLE')"
      :message="$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.MESSAGE')"
      :message-value="deleteMessage"
      :confirm-text="deleteConfirmText"
      :reject-text="deleteRejectText"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VeTable } from 'vue-easytable';
import accountMixin from '../../../../mixins/account';
import alertMixin from 'shared/mixins/alertMixin';
import { VePagination } from 'vue-easytable';
import EmptyState from 'dashboard/components/widgets/EmptyState.vue';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';
export default {
  components: {
    VeTable,
    EmptyState,
    VePagination,
  },
  mixins: [accountMixin, alertMixin, veLoadingMixin],
  data() {
    return {
      pageIndex: 1,
      showDeleteConfirmationPopup: false,
      selectedModel: {},
      columns: [
        {
          field: 'id',
          key: 'id',
          title: this.$t('AI_DEPLOYMENT_SETTINGS.LIST_HEADER.ID'),
          align: 'left',
          width: '5%',
        },
        {
          field: 'created_at',
          key: 'created_at',
          title: this.$t('AI_DEPLOYMENT_SETTINGS.LIST_HEADER.TRAINING_TIME'),
          align: 'left',
          width: '',
        },
        {
          field: 'status',
          key: 'status',
          title: this.$t('AI_DEPLOYMENT_SETTINGS.LIST_HEADER.DEPLOY_STATUS'),
          align: 'left',
          width: '',
          renderBodyCell: ({ row, column }) => {
            const text = row[column.field];
            return (
              <span>
                {text ===
                this.$t(
                  'AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.TRAINING_ERROR'
                ) ? (
                  <a style="color:#fb2424;cursor:pointer;">{text}</a>
                ) : (
                  <a style="color:black;cursor:pointer;">{text}</a>
                )}
              </span>
            );
          },
        },
        {
          field: 'deployed',
          key: 'deployed',
          title: this.$t('AI_DEPLOYMENT_SETTINGS.LIST_HEADER.TRAINING_STATUS'),
          align: 'left',
          width: '',
          renderBodyCell: ({ row, column }) => {
            const text = row[column.field];
            return (
              <span>
                {text ===
                this.$t('AI_DEPLOYMENT_SETTINGS.API.DEPLOY.STATUS.RUNNING') ? (
                  <a style="color:#33AB00;cursor:pointer;">{text}</a>
                ) : (
                  <a style="color:black;cursor:pointer;">{text}</a>
                )}
              </span>
            );
          },
        },
        {
          field: '',
          key: 'e',
          title: this.$t('AI_DEPLOYMENT_SETTINGS.LIST_HEADER.ACTION'),
          width: 110,
          center: 'left',
          renderBodyCell: ({ row: models }) => {
            return (
              <div>
                {models.deployed !==
                  this.$t('AI_DEPLOYMENT_SETTINGS.API.DEPLOY.STATUS.RUNNING') &&
                  models.status !==
                    this.$t(
                      'AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.TRAINING_ERROR'
                    ) &&
                  models.status !==
                    this.$t(
                      'AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.GENERATED'
                    ) && (
                    <span>
                      <woot-button
                        size="small"
                        variant="smooth"
                        icon="play"
                        class="edit-custom-view__button button-margin"
                        on-click={() => this.startDeploy(models.id, models)}
                      ></woot-button>
                    </span>
                  )}
                {models.deployed !==
                  this.$t('AI_DEPLOYMENT_SETTINGS.API.DEPLOY.STATUS.RUNNING') &&
                  models.status !==
                    this.$t(
                      'AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.RUNNING'
                    ) && (
                    <woot-button
                      size="small"
                      variant="smooth"
                      color-scheme="alert"
                      icon="delete"
                      class="delete-custom-view__button button-margin"
                      on-click={() => this.openDeletePopup(models)}
                    ></woot-button>
                  )}
              </div>
            );
          },
        },
      ],
    };
  },

  computed: {
    ...mapGetters({
      modelsData: 'aiDeployment/getModels',
      isTraining: 'aiDeployment/getTraining',
      isDeploying: 'aiDeployment/getDeploying',
      modelsCount: 'aiDeployment/getCount',
      uiFlags: 'aiDeployment/getUIFlags',
    }),
    modelItem() {
      let modelsArr = Object.values(this.modelsData);
      if (modelsArr) {
        let newModelsArr = modelsArr.map(model => ({
          id: model.id,
          created_at:
            model.created_at !== null &&
            new Date(model.created_at).toLocaleString(),
          status: this.getStatusText(model.status),
          deployed: this.getDeployedText(model.deployed),
        }));
        return newModelsArr;
      }
      return {};
    },
    canRenderTable() {
      return (
        this.modelItem && this.modelItem.length > 0 && !this.uiFlags.isFetching
      );
    },
    // Delete Modal
    deleteConfirmText() {
      return `${this.$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.YES')} ${
        this.selectedModel.id
      }`;
    },
    deleteRejectText() {
      return `${this.$t('INTENT_MANAGE_SETTINGS.DELETE.CONFIRM.NO')} ${
        this.selectedModel.id
      }`;
    },
    deleteMessage() {
      return ` ${this.selectedModel.id}?`;
    },
  },
  watch: {
    uiFlags({ isFetching, isDeleting }) {
      if (isFetching || this.isTraining || this.isDeploying || isDeleting) {
        this.showVeLoading();
      } else {
        this.closeVeLoading();
      }
    },
  },
  created() {
    this.$store.dispatch('aiDeployment/get');
  },
  // updated() {
  //   if (this.isTraining || this.isDeploying) {
  //     this.showVeLoading();
  //   } else {
  //     this.closeVeLoading();
  //   }
  // },
  methods: {
    async startTraining() {
      try {
        if (this.isTraining) {
          return;
        }
        await this.$store.dispatch('aiDeployment/start');
        this.$store.dispatch('aiDeployment/get', { page: this.pageIndex });
      } catch (error) {
        this.$store.dispatch('aiDeployment/get', { page: this.pageIndex });
        this.showAlert(
          this.$t('AI_DEPLOYMENT_SETTINGS.API.TRAIN.ERROR_MESSAGE')
        );
        throw new Error(error);
      }
    },
    async startDeploy(id) {
      try {
        if (this.isDeploying) {
          return;
        }
        await this.$store.dispatch('aiDeployment/deploy', id);
        this.$store.dispatch('aiDeployment/get', { page: this.pageIndex });
      } catch (error) {
        this.$store.dispatch('aiDeployment/get', { page: this.pageIndex });
        throw new Error(error);
      }
    },
    openDeletePopup(model) {
      this.showDeleteConfirmationPopup = true;
      this.selectedModel = model;
    },
    closeDeletePopup() {
      this.showDeleteConfirmationPopup = false;
    },
    confirmDeletion() {
      this.closeDeletePopup();
      this.deleteModelById(this.selectedModel.id);
    },
    async deleteModelById(id) {
      try {
        await this.$store.dispatch('aiDeployment/delete', id);
        this.$store.dispatch('aiDeployment/get', { page: this.pageIndex });
        if (!this.modelsData.length && this.pageIndex > 1) {
          this.onPageChange(1);
        }
        this.showAlert(
          this.$t('AI_DEPLOYMENT_SETTINGS.DELETE.API.SUCCESS_MESSAGE')
        );
      } catch (error) {
        this.showAlert(
          this.$t('AI_DEPLOYMENT_SETTINGS.DELETE.API.ERROR_MESSAGE')
        );
      }
    },
    getStatusText(status) {
      switch (status) {
        case 0:
          return this.$t(
            'AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.DISABLED_DELETED'
          );
        case 1:
          return this.$t(
            'AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.TRAINING_ERROR'
          );
        case 2:
          return this.$t('AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.GENERATED');
        case 3:
          return this.$t('AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.TRAINING');
        case 4:
          return this.$t(
            'AI_DEPLOYMENT_SETTINGS.API.TRAIN.STATUS.TRAINING_COMPLETED'
          );
        default:
          return '';
      }
    },
    getDeployedText(deployed) {
      switch (deployed) {
        case 0:
          return this.$t(
            'AI_DEPLOYMENT_SETTINGS.API.DEPLOY.STATUS.NOT_DEPLOYED'
          );
        case 1:
          return this.$t('AI_DEPLOYMENT_SETTINGS.API.DEPLOY.STATUS.DEPLOYING');
        case 2:
          return this.$t('AI_DEPLOYMENT_SETTINGS.API.DEPLOY.STATUS.RUNNING');
        default:
          return '';
      }
    },
    onPageChange(page) {
      this.pageIndex = page;
      window.history.pushState({}, null, `${this.$route.path}?page=${page}`);
      this.$store.dispatch('aiDeployment/get', { page });
      return '';
    },
  },
};
</script>
<style lang="scss" scoped>
.ve-pagination {
  margin-top: var(--space-one);
  display: flex;
  justify-content: flex-end;
  background-color: transparent !important;
}
.button-margin {
  margin: 0 0.5rem;
}
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
</style>
