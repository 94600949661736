import {
  SET_STORIES_UI_FLAG,
  CLEAR_STORIES,
  SET_STORIES,
  DELETE_STORY,
  SET_MESSAGES_LOADED,
  SET_ALL_CONVERSATION,
  SET_STORY_ITEM,
  EDIT_STORY,
  SET_MESSAGE,
  UPDATE_CONVERSATION,
  DELETE_MESSAGE,
  ADD_STORY_MESSAGE,
  SET_GPT_TURBO_MESSAGE,
  CLEAR_GPT_TURBO_MESSAGE,
  SET_COUNTERS,
  CLEAR_COUNTERS,
} from './types';
import Vue from 'vue';
import _cloneDeep from 'lodash/cloneDeep';
export const mutations = {
  [SET_ALL_CONVERSATION](_state, conversationList) {
    const newAllConversations = [..._state.allConversations];
    conversationList.forEach(conversation => {
      const indexInCurrentList = newAllConversations.findIndex(
        c => c.id === conversation.id
      );
      if (indexInCurrentList < 0) {
        newAllConversations.push(conversation);
      }
    });
    _state.allConversations = newAllConversations;
  },
  [SET_STORIES_UI_FLAG]: ($state, data) => {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...data,
    };
  },
  [CLEAR_STORIES]: $state => {
    Vue.set($state.stories, 'data', []);
  },

  [SET_STORIES]: ($state, stories) => {
    const { data } = $state.stories;
    Vue.set($state.stories, 'count', stories.count);
    stories.data.forEach(story => {
      Vue.set(data, story.id, {
        ...(data[story.id] || {}),
        ...story,
      });
    });
  },

  [SET_STORY_ITEM]: ($state, data) => {
    Vue.set($state.stories.data, data.id, {
      ...($state.stories.data[data.id] || {}),
      ...data,
    });
  },
  [EDIT_STORY]: ($state, data) => {
    Vue.set($state.stories.data, data.id, data);
  },
  [DELETE_STORY]: ($state, StoryId) => {
    const { [StoryId]: Delete, ...stories } = $state.stories.data;
    Vue.set($state.stories, 'data', stories);
  },
  [SET_MESSAGE]($state, { id, data }) {
    if (data.length) {
      const [chat] = $state.allConversations.filter(c => c.id === id);
      chat.messages.unshift(...data);
    }
  },
  [UPDATE_CONVERSATION](_state, conversation) {
    _state.allConversations = conversation;
  },
  [DELETE_MESSAGE]: ($state, { id, conversationId }) => {
    const [chat] = $state.allConversations.filter(c => c.id === conversationId);
    chat.messages = chat.messages.filter(msg => msg.id !== id);
  },
  [ADD_STORY_MESSAGE]: ($state, { conversationId, content }) => {
    const contentArr = Object.values(content);
    const cloneContent = _cloneDeep(contentArr);
    const newCloneContent = cloneContent.map(item => {
      const timestamp = Date.now();
      const random = Math.floor(Math.random() * 1000000);
      const id = `${timestamp}${random}`;
      return { ...item, id };
    });
    const [chat] = $state.allConversations.filter(c => c.id === conversationId);

    chat.messages.push(...newCloneContent);
  },
  [SET_MESSAGES_LOADED]($state, payload) {
    Vue.set($state, 'messagesLoaded', payload);
  },
  // eslint-disable-next-line no-unused-vars
  [SET_GPT_TURBO_MESSAGE]: ($state, payload) => {
    // 用于本地开发的时候console预览,其他时候注释掉
    // const finalResult = [];
    // const filterAssistant = payload
    //   .filter(c => c.role === 'assistant')
    //   .filter(item => item.content.includes('ask'));
    // filterAssistant.forEach(message => {
    //   const contents = message.content.split('\n');
    //   contents.forEach(content => {
    //     if (content.startsWith('ask: ')) {
    //       finalResult.push({ ask: content.substring(5) });
    //     } else if (content.startsWith('reply: ')) {
    //       finalResult.push({ reply: content.substring(7) });
    //     }
    //   });
    // });
    // console.log(finalResult, 'finalResult');
    // $state.gptTurboRecord.messages = filterAssistant;
  },
  [CLEAR_GPT_TURBO_MESSAGE]: $state => {
    Vue.set($state.gptTurboRecord, 'messages', []);
  },
  [SET_COUNTERS]: ($state, payload) => {
    $state.counters = {
      ...$state.counters,
      ...payload,
    };
  },
  [CLEAR_COUNTERS]: $state => {
    Vue.set($state, 'counters', {
      importedCount: 0,
      generatedCount: 0,
    });
  },
};
