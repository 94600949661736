import {
  SET_STORIES_UI_FLAG,
  CLEAR_STORIES,
  SET_STORIES,
  DELETE_STORY,
  SET_MESSAGES_LOADED,
  SET_STORY_ITEM,
  EDIT_STORY,
  SET_MESSAGE,
  UPDATE_CONVERSATION,
  DELETE_MESSAGE,
  ADD_STORY_MESSAGE,
  CLEAR_GPT_TURBO_MESSAGE,
  SET_COUNTERS,
  CLEAR_COUNTERS,
} from './types';
import { GPT_MODEL } from 'shared/constants/gptModel';
import GptStoriesApi from '../../../api/gptStories';
import MessageApi from '../../../api/inbox/message';
import _cloneDeep from 'lodash/cloneDeep';
import GptTurboApi from '../../../api/GptTurbo';
import { nanoid } from 'nanoid';
export const actions = {
  getAllConversation: async ({ commit, rootGetters }) => {
    try {
      const AllConversations = rootGetters.getAllConversations;
      const copyAllConversations = _cloneDeep(AllConversations);
      commit(UPDATE_CONVERSATION, copyAllConversations);
    } catch (error) {
      // Ignore error
    }
  },
  /* story、faq、知识库共用,根据传入的contentType判断类型 */
  getStories: async ({ commit }, { page = 1, contentType = 0 } = {}) => {
    commit(SET_STORIES_UI_FLAG, { isFetching: true });
    try {
      commit(CLEAR_STORIES);
      const { data: payload } = await GptStoriesApi.getStories(
        page,
        contentType
      );
      if (payload.data.length === 0) {
        commit(SET_STORIES_UI_FLAG, { isFetching: false });
      } else {
        commit(SET_STORIES, payload);
        commit(SET_STORIES_UI_FLAG, { isFetching: false });
      }
    } catch (error) {
      // Handle error
    } finally {
      commit(SET_STORIES_UI_FLAG, { isFetching: false });
    }
  },
  createStory: async ({ commit }, payload) => {
    try {
      const response = await GptStoriesApi.create(payload);
      const story = response.data;
      commit(SET_MESSAGES_LOADED, false);
      commit(SET_STORY_ITEM, story);
      return story;
    } catch (error) {
      throw new Error(error);
    }
  },
  updateStory: async ({ commit }, { id, ...updateObj }) => {
    try {
      const response = await GptStoriesApi.update(id, updateObj);
      commit(EDIT_STORY, response.data);
    } catch (error) {
      throw new Error(error);
    }
  },
  deleteStory: async ({ commit }, StoryId) => {
    try {
      await GptStoriesApi.delete(StoryId);
      commit(DELETE_STORY, StoryId);
    } catch (error) {
      // Ignore error
    } finally {
      commit(SET_STORIES_UI_FLAG, { isFetching: false });
    }
  },
  deleteMessage: async ({ commit }, { id, conversationId }) => {
    try {
      commit(DELETE_MESSAGE, { id, conversationId });
    } catch (error) {
      // Ignore error
    }
  },
  addStoryMessage: async (
    { commit },
    { content, message_type, conversationId }
  ) => {
    try {
      commit(ADD_STORY_MESSAGE, { content, message_type, conversationId });
    } catch (error) {
      // Ignore error
    }
  },
  initMessageLoaded: async ({ commit }) => {
    commit(SET_MESSAGES_LOADED, false);
  },
  fetchPreviousMessages: async ({ commit }, data) => {
    commit(SET_STORIES_UI_FLAG, { isFetching: true });
    try {
      const {
        data: { payload },
      } = await MessageApi.getPreviousMessages(data);
      commit(SET_MESSAGE, {
        id: data.conversationId,
        data: payload,
      });

      if (payload.length === 0) {
        commit(SET_MESSAGES_LOADED, true);
        commit(SET_STORIES_UI_FLAG, { isFetching: false });
      }
      // }
    } catch (error) {
      // Handle error
    }
  },
  fetchGptTurboAssistant: async (
    { commit, dispatch, state },
    { productDescription, language }
  ) => {
    // 清空对话
    commit(CLEAR_GPT_TURBO_MESSAGE);
    // 清空计数器
    commit(CLEAR_COUNTERS);
    // 重置导入状态
    commit(SET_STORIES_UI_FLAG, { isImporting: false });

    const askingTemplate = {
      model: GPT_MODEL.GPT_TURBO,
      temperature: 0.2,
      messages: [
        {
          role: 'user',
          content: `I hope you can simulate user questions based on the product description I provided. I hope the questions you generate will closely match the product description I provided, please follow the product description I provided as closely as possible and generate 10 of the most similar questions without any unnecessary explanation. After you generate the questions, I will ask you questions based on your responses, and you must strictly adhere to my product description when responding. For this conversation, you only need to generate questions. Here is my product description:"${productDescription}"\nPlease write in ${language} language.`,
        },
      ],
    };
    const gptRequestTemplate = {
      model: GPT_MODEL.GPT_TURBO,
      temperature: 0.2,
      messages: [],
    };
    const lastQuestionTemplate = {
      model: GPT_MODEL.GPT_TURBO,
      temperature: 0.2,
      messages: [],
    };
    try {
      const multiTurnDialogue = async () => {
        const { data: payload } = await GptTurboApi.create(gptRequestTemplate);
        const newMessages = [
          ...gptRequestTemplate.messages,
          payload[0].message,
        ];
        gptRequestTemplate.messages = newMessages;
      };
      const continueLastQuestion = async () => {
        const { data: payload } = await GptTurboApi.create(
          lastQuestionTemplate
        );
        gptRequestTemplate.messages = [
          ...gptRequestTemplate.messages,
          payload[0].message,
        ];
      };
      const { data: initialPayload } = await GptTurboApi.create(askingTemplate);
      const initialResponseMessage = initialPayload[0].message;
      // 第一轮的问题
      const firstRoundUserQuestions = {
        role: 'user',
        content: `Please generate 1 similar question for each of the above questions (total of 10 questions). The field for these questions is "ask", and there must be a corresponding answer for each question, labeled "reply". You must strictly adhere to the format of "ask: xxx \n reply: xxx" when outputting the results. There is no need to include any markers or numbering when you reply to me. You must strictly follow the instructions provided in the product description to fill in the "reply" section.\nPlease write in ${language} language.`,
      };
      // 第二轮的问题
      const secondRoundUserQuestions = {
        role: 'user',
        content: `Please generate 1 similar questions for each question based on each question (total of 10 questions). This time, try to generate questions that are not the same as the previous ones, but are similar in meaning. The field for these questions is "ask", and there must be a corresponding answer for each question in the field "reply". You must strictly follow the format of "ask:xxx \n reply:xxx" to output the results. You do not need to do any marking or numbering when replying to me. You must strictly follow the content provided in the product description to fill in the "reply" section. In the reply field, you can only provide answers, not ask questions.\nPlease write in ${language} language.`,
      };

      // 第一轮请求
      gptRequestTemplate.messages = [
        ...askingTemplate.messages,
        initialResponseMessage,
        firstRoundUserQuestions,
      ];
      await multiTurnDialogue();
      let generatedCount = state.counters.generatedCount;
      generatedCount += 1;
      commit(SET_COUNTERS, { generatedCount });

      // 第二轮请求
      gptRequestTemplate.messages = [
        ...gptRequestTemplate.messages,
        secondRoundUserQuestions,
      ];

      lastQuestionTemplate.messages = _cloneDeep(gptRequestTemplate.messages);
      await multiTurnDialogue();
      await continueLastQuestion();

      // 用于本地开发的时候console预览,其他时候注释掉
      // commit(SET_GPT_TURBO_MESSAGE, gptRequestTemplate.messages);

      // 将gpt的回答过滤出来
      const finalResult = [];
      // 过滤出assistant的回复
      const filterAssistant = gptRequestTemplate.messages
        .filter(c => c.role === 'assistant')
        // 过滤出ask和reply
        .filter(item => item.content.includes('ask'));
      filterAssistant.forEach(assistantResponse => {
        const contents = assistantResponse.content.split('\n');
        contents.forEach(content => {
          if (content.startsWith('ask: ')) {
            finalResult.push({ ask: content.substring(5) });
          } else if (content.startsWith('reply: ')) {
            finalResult.push({ reply: content.substring(7) });
          }
        });
      });
      // 生成story
      let Generate = {
        title: `ProductDescription:${nanoid()}`,
        steps: finalResult,
      };
      dispatch('createStory', Generate).then(() => {
        let importedCount = state.counters.importedCount;
        importedCount += 1;
        commit(SET_COUNTERS, { importedCount });
      });
      commit(SET_STORIES_UI_FLAG, { isImporting: true });
    } catch (error) {
      throw new Error(error);
    }
  },
};
