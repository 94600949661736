/* global axios */
import ApiClient from './ApiClient';

export class GptIntensApi extends ApiClient {
  constructor() {
    super('gpt/gpt_intents', { accountScoped: true });
  }

  getIntents({ id }) {
    return axios.get(`${this.url}/${id}/gpt/gpt_intents`);
  }

  addIntents({ id }) {
    return axios.post(`${this.url}/${id}/gpt/gpt_intents`);
  }

  updateIntens({ id, intentId }) {
    return axios.patch(`${this.url}/${id}/gpt/gpt_intents${intentId}`);
  }

  deleteIntents({ intentId }) {
    return axios.delete(`${this.url}/${intentId}/gpt/gpt_intents${intentId}`);
  }
}

export default new GptIntensApi();
