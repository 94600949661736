export const getters = {
  getTrainingStatus($state) {
    return $state.trainingStatus;
  },
  getModels($state) {
    return Object.values($state.models).sort((a, b) => b.id - a.id);
  },
  getCount($state) {
    return $state.count;
  },
  getTraining($state) {
    return $state.training;
  },
  getDeploying($state) {
    return $state.deploying;
  },
  getUIFlags($state) {
    return $state.uiFlags;
  },
};
