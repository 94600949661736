<template>
  <modal :show.sync="show" size="medium" :on-close="onClose">
    <div class="column content-box">
      <div>
        <woot-modal-header
          :header-title="
            $t('AI_ACTION_MANAGE_SETTINGS.MODAL.UPDATE_ACTION.TITLE')
          "
        />
        <form
          id="loading-container"
          class="row"
          @submit.prevent="UpdatedResponse"
        >
          <div class="medium-12 columns">
            <label>
              {{ $t('AI_ACTION_MANAGE_SETTINGS.MODAL.MULTISELECT.LABEL.NAME') }}
            </label>
            <multiselect
              v-model="selectAction"
              :options="actionItem"
              :placeholder="
                $t(
                  'AI_ACTION_MANAGE_SETTINGS.MODAL.MULTISELECT.SELECT_ONE_ACTION'
                )
              "
              deselect-label=""
              :max-height="150"
              label="name"
              track-by="id"
              selected-label
              :select-label="$t('FORMS.MULTISELECT.ENTER_TO_SELECT')"
              @select="handleSelect"
            />
          </div>
          <div class="medium-12 columns">
            <label>
              {{
                $t(
                  'AI_ACTION_MANAGE_SETTINGS.MODAL.UPDATE_ACTION.LABEL.RESPONSE'
                )
              }}
              <input
                v-model.trim="response"
                type="text"
                :class="{ error: $v.response.$error }"
                :placeholder="
                  $t(
                    'AI_ACTION_MANAGE_SETTINGS.MODAL.DESCRIPTION.ACTION_PLACEHOLDER'
                  )
                "
                @input="$v.response.$touch"
              />
            </label>
          </div>
          <div class="modal-footer">
            <div class="medium-12 columns">
              <woot-submit-button
                :button-text="
                  $t(
                    'AI_ACTION_MANAGE_SETTINGS.MODAL.UPDATE_ACTION.BUTTON_TEXT'
                  )
                "
                :loading="showLoading"
                :disabled="
                  $v.actionId.$invalid || $v.response.$invalid || showLoading
                "
              />
              <button class="button clear" @click.prevent="onClose">
                {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </modal>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';
import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';
import Modal from '../../../../../components/Modal.vue';
import alertMixin from 'shared/mixins/alertMixin';
import veLoadingMixin from 'dashboard/mixins/veLoadingMixin';

export default {
  components: {
    WootSubmitButton,
    Modal,
  },
  mixins: [alertMixin, veLoadingMixin],
  props: {
    responseContent: {
      type: String,
      default: '',
    },
    onClose: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      selectAction: [],
      actionId: null,
      actionName: '',
      response: this.responseContent || '',
      showLoading: false,
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      actions: 'aiActionManage/getActions',
      uiFlags: 'aiActionManage/getUIFlags',
    }),
    actionItem() {
      let actions = Object.values(this.actions.data);
      if (actions) {
        let newActionArr = actions.map(action => ({
          id: action.id,
          name: action.name,
          response: action.response,
          rowKey: action.id,
        }));
        return newActionArr;
      }
      return {};
    },
  },
  watch: {
    uiFlags({ isFetching }) {
      if (isFetching) {
        this.showVeLoading();
      } else {
        this.closeVeLoading();
      }
    },
  },
  created() {
    this.$store.dispatch('aiActionManage/getAll');
  },
  validations: {
    actionId: {
      required,
    },
    response: {
      required,
    },
  },
  methods: {
    async UpdatedResponse() {
      try {
        this.showLoading = true;
        await this.$store.dispatch('aiActionManage/update', {
          id: this.actionId,
          text: this.response.split('\n').join(''),
        });
        this.showLoading = false;
        this.showAlert(
          this.$t('AI_ACTION_MANAGE_SETTINGS.MODAL.API.SUCCESS_MESSAGE')
        );
        this.onClose();
      } catch (error) {
        this.showLoading = false;
        this.showAlert(
          this.$t('AI_ACTION_MANAGE_SETTINGS.MODAL.API.ERROR_MESSAGE')
        );
      }
    },
    handleSelect(selectedRange) {
      this.actionId = selectedRange.id;
      this.actionName = selectedRange.name;
    },
  },
};
</script>
<style lang="scss" scoped></style>
