import SettingsContent from '../Wrapper';
import aiActionManageHome from './Index.vue';
import { frontendURL } from '../../../../helper/URLHelper';
import EditAction from './Edit/EditAction.vue';
import EditStepWrap from './Edit/Index.vue';
import CreateAction from './Create/CreateAction.vue';
import CreateStepWrap from './Create/index.vue';

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/ai-action-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_ai_action_manage_list';
        return {
          headerTitle: 'AI_ACTION_MANAGE_SETTINGS.HEADER',
          // headerButtonText: 'AI_ACTION_MANAGE_SETTINGS.NEW_ACTION',
          // newButtonRoutes: ['settings_ai_story_manage_new'],
          icon: 'inprivate-account',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_ai_action_manage',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_ai_action_manage_list',
          component: aiActionManageHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'settings_ai_action_manage_new',
              component: CreateAction,
              roles: ['administrator'],
            },
          ],
        },
        {
          path: ':responseId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_ai_action_manage_edit',
              component: EditAction,
              roles: ['administrator'],
            },
          ],
        },
      ],
    },
  ],
};
