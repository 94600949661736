import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state = {
  training: false,
  deploying: false,
  uiFlags: {
    isFetching: true,
    isDeleting: false,
  },
  count: 0,
  models: [],
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
