<template>
  <div class="flex-1 overflow-auto p-4">
    <ai-configs />
  </div>
</template>

<script>
import AiConfigs from './AiConfig.vue';
export default {
  components: {
    AiConfigs,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped></style>
