import SettingsContent from '../Wrapper';

import { frontendURL } from '../../../../helper/URLHelper';
import Hello from './Hello';
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/vocabulary-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton =
          params.name !== 'settings_vocabulary_manage_list';
        return {
          headerTitle: 'VOCABULARY_MANAGE_SETTINGS.HEADER',
          icon: 'book-database',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_vocabulary_manage',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_vocabulary_manage_list',
          component: Hello,
          roles: ['administrator'],
        },
      ],
    },
  ],
};
