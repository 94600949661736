/* eslint arrow-body-style: 0 */
import SettingsContent from '../Wrapper';
import IntentsHome from './Index';
import CreateStepWrap from './Create/Index';
import EditStepWrap from './Edit/Index';
import CreateIntent from './Create/CreateIntent';
import EditIntent from './Edit/EditIntent';
import { frontendURL } from '../../../../helper/URLHelper';
import FAQstepWrap from './widgets/FAQ/Index.vue';
import ImportFAQ from './widgets/FAQ/ImportFAQ.vue';
import ProductInstructionsStepWrap from './widgets/ProductInstructions/Index.vue';
import ImportProductInstructions from './widgets/ProductInstructions/ImportProductInstructions.vue';
/* 意图管理 */
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/intent-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_intent_manage_list';
        return {
          headerTitle: 'INTENT_MANAGE_SETTINGS.HEADER',
          headerButtonText: 'INTENT_MANAGE_SETTINGS.NEW_INTENT',
          icon: 'intent-manage',
          newButtonRoutes: ['settings_intent_manage_new'],
          showBackButton,
          keepAlive: false,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_intent_manage',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_intent_manage_list',
          component: IntentsHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: CreateStepWrap,
          children: [
            {
              path: '',
              name: 'settings_intent_manage_new',
              component: CreateIntent,
              roles: ['administrator'],
            },
          ],
        },
        {
          path: ':intentId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_intent_manage_edit',
              component: EditIntent,
              roles: ['administrator'],
            },
          ],
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/intent-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_intent_manage_list';
        return {
          headerTitle: 'INTENT_MANAGE_SETTINGS.WIDGET.FAQ.HEADER',
          icon: 'calendar-import',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_intent_manage',
          redirect: 'list',
        },
        {
          path: 'widgets/import-faq',
          component: FAQstepWrap,
          children: [
            {
              path: '',
              name: 'settings_intent_manage_widgets',
              component: ImportFAQ,
              roles: ['administrator'],
            },
          ],
        },
      ],
    },
    {
      path: frontendURL('accounts/:accountId/settings/intent-manage'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_intent_manage_list';
        return {
          headerTitle:
            'INTENT_MANAGE_SETTINGS.WIDGET.PRODUCT_INSTRUCTIONS.HEADER',
          icon: 'calendar-import',
          showBackButton,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_intent_manage',
          redirect: 'list',
        },
        {
          path: 'widgets/import-product-instructions',
          component: ProductInstructionsStepWrap,
          children: [
            {
              path: '',
              name: 'settings_intent_manage_widgets',
              component: ImportProductInstructions,
              roles: ['administrator'],
            },
          ],
        },
      ],
    },
  ],
};
