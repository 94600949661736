import SettingsContent from '../Wrapper';
import { frontendURL } from '../../../../helper/URLHelper';
import KnowledgeBaseHome from './Index.vue';
import AddDocument from './Add/AddDocument.vue';
import EditDocument from './Edit/EditDocument.vue';
import AddStepWrap from './Add/Index.vue';
import EditStepWrap from './Edit/Index.vue';
export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/knowledge-base'),
      component: SettingsContent,
      props: params => {
        const showBackButton = params.name !== 'settings_knowledge_base_list';
        return {
          headerTitle: 'KNOWLEDGE_BASE_SETTINGS.HEADER',
          headerButtonText: 'KNOWLEDGE_BASE_SETTINGS.NEW_DOCUMENT',
          newButtonRoutes: ['settings_knowledge_base_new'],
          icon: 'book-search',
          showBackButton,
          keepAlive: false,
        };
      },
      children: [
        {
          path: '',
          name: 'settings_knowledge_base',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'settings_knowledge_base_list',
          component: KnowledgeBaseHome,
          roles: ['administrator'],
        },
        {
          path: 'new',
          component: AddStepWrap,
          children: [
            {
              path: '',
              name: 'settings_knowledge_base_new',
              component: AddDocument,
              roles: ['administrator'],
            },
          ],
        },
        {
          path: ':storyId/edit',
          component: EditStepWrap,
          children: [
            {
              path: '',
              name: 'settings_knowledge_base_edit',
              component: EditDocument,
              roles: ['administrator'],
            },
          ],
        },
      ],
    },
  ],
};
