/**
 * 解析给定的内容并相应地添加到提供的intent对象中。
 * 内容应以'example:', 'response:', 或 'name:' 开头，以指定要解析的数据类型。
 * @param {string} content - 需要解析的内容。
 * @param {Object} intent - 存储解析结果的 intent 对象。
 */
export const parseGptContent = (content, intent) => {
  const contentPrefix = content.split(':')[0];
  const contentValue = content.substring(contentPrefix.length + 1);
  switch (contentPrefix) {
    case 'example':
      intent.examples.push({ example: contentValue });
      break;
    case 'response':
      intent.response.text = contentValue;
      break;
    case 'name':
      intent.name = contentValue;
      break;
    default:
      // eslint-disable-next-line no-console
      console.error(`Invalid content prefix: ${contentPrefix}`);
  }
};
