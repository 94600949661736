<template>
  <div class="wizard-body column content-box">
    <form @submit.prevent="handleSubmit">
      <h3>FAQ</h3>
      <div class="small-4 columns">
        <p>{{ $t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.DESCRIPTION.ROW_1') }}</p>
        <p>{{ $t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.DESCRIPTION.ROW_2') }}</p>
        <p>{{ $t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.DESCRIPTION.ROW_3') }}</p>
        <p>
          {{ $t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.DESCRIPTION.ROW_4') }}
        </p>
        <p>{{ $t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.DESCRIPTION.ROW_5') }}</p>
        <p class="input-tip small-8 columns">
          {{
            $t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.DESCRIPTION.EXAMPLE_QUESTION')
          }}
          <br />
          {{
            $t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.DESCRIPTION.EXAMPLE_ANSWER')
          }}
        </p>
      </div>
      <div>
        <textarea v-model="content" :rows="15" type="text" />
      </div>
      <div class="medium-12 columns">
        <woot-submit-button
          :disabled="showLoading"
          :button-text="$t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.BUTTON_TEXT')"
          :loading="showLoading"
        />
      </div>
    </form>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';

import { nanoid } from 'nanoid';
export default {
  mixins: [alertMixin],
  data() {
    return { content: '', result: [], showLoading: false };
  },
  computed: {
    faqUniqueId() {
      return `FAQ:${nanoid(11)}`;
    },
  },

  methods: {
    parseQnAFormat() {
      const regex = /^Q:(.*)\nA:(.*)$/;
      const lines = this.content.trim().split('\n');
      const result = [];
      for (let i = 0; i < lines.length; i += 2) {
        if (!regex.test(lines[i] + '\n' + lines[i + 1])) {
          this.showAlert(
            this.$t(
              'INTENT_MANAGE_SETTINGS.WIDGET.FAQ.API.CHECK_CONTENT_ERROR_MESSAGE'
            )
          );
          return;
        }
        const q = { ask: lines[i].substring(2) };
        const a = { reply: lines[i + 1].substring(2) };
        result.push(q);
        result.push(a);
      }
      this.result = result;
    },
    async handleSubmit() {
      if (!this.content.trim()) {
        this.showAlert(
          this.$t(
            'INTENT_MANAGE_SETTINGS.WIDGET.FAQ.API.EMPTY_CONTENT_ERROR_MESSAGE'
          )
        );
        return;
      }
      this.parseQnAFormat();
      try {
        if (!this.result.length) {
          return;
        }
        this.showLoading = true;
        await this.$store.dispatch('aiStoryManage/createStory', {
          title: `FAQ:${nanoid(11)}`,
          steps: this.result,
        });
        this.showLoading = false;
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.API.SUCCESS_MESSAGE')
        );
        this.content = '';
        this.result = [];
      } catch (error) {
        this.showAlert(
          this.$t('INTENT_MANAGE_SETTINGS.WIDGET.FAQ.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.input-tip {
  top: 0;
  left: 0;
  padding: 5px;
  background-color: #f3f3f3;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: var(--font-size-small);
  color: #999;
}
</style>
