<template>
  <div>
    <form class="row" @submit.prevent="createIntent">
      <div class="medium-12 columns">
        <div class="intent-label">
          <label :class="{ error: $v.intentName.$error }">
            {{ $t('INTENT_MANAGE_SETTINGS.FORM.NAME.LABEL') }}
            <input
              v-model.trim="intentName"
              type="text"
              :class="{ error: $v.example.$error }"
              :placeholder="
                $t(
                  'INTENT_MANAGE_SETTINGS.FORM.DESCRIPTION.INTENT_NAME_PLACEHOLDER'
                )
              "
              @input="$v.intentName.$touch"
            />
          </label>
        </div>
        <label :class="{ error: $v.example.$error }">
          {{ $t('INTENT_MANAGE_SETTINGS.LIST_HEADER.EXAMPLE') }}
          <input
            v-model.trim="example"
            type="text"
            :class="{ error: $v.example.$error }"
            :placeholder="
              $t('INTENT_MANAGE_SETTINGS.MODAL.DESCRIPTION.EXAMPLE_PLACEHOLDER')
            "
            @input="$v.example.$touch"
          />
        </label>
      </div>
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :button-text="$t('INTENT_MANAGE_SETTINGS.FORM.SUBMIT_CREATE')"
            :loading="showLoading"
            :disabled="
              $v.intentName.$invalid || $v.example.$invalid || showLoading
            "
          />
          <button class="button clear" @click.prevent="onClose">
            {{ $t('CANNED_MGMT.ADD.CANCEL_BUTTON_TEXT') }}
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import WootSubmitButton from '../../../../../components/buttons/FormSubmitButton';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: {
    WootSubmitButton,
  },
  mixins: [alertMixin],
  props: {
    onClose: {
      type: Function,
      default: () => {},
    },
    exampleContent: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      intentName: '',
      examplesArr: [],
      example: this.exampleContent || '',
      showLoading: false,
      isConversation: true,
    };
  },
  validations: {
    intentName: {
      required,
    },
    example: {
      required,
    },
  },
  methods: {
    async createIntent() {
      this.showLoading = true;
      this.examplesArr.push({
        example: this.example,
      });
      try {
        const intent = await this.$store.dispatch('intentManage/create', {
          intentInfo: {
            name: this.intentName,
            examples: this.examplesArr,
            response: {
              text: '',
            },
          },
          isConversation: this.isConversation,
        });
        if (intent) {
          this.showLoading = false;
          this.onClose();
          this.showAlert(
            this.$t('INTENT_MANAGE_SETTINGS.CREATE_FLOW.API.SUCCESS_MESSAGE')
          );
        }
      } catch (error) {
        if (error) {
          this.showLoading = false;
          this.showAlert(
            this.$t('INTENT_MANAGE_SETTINGS.CREATE_FLOW.API.ERROR_MESSAGE')
          );
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.intent-label {
  padding: var(--space-one) 0 0;
}
</style>
