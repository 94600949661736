/* global axios */
import ApiClient from './ApiClient';

export class GptIntentResponseApi extends ApiClient {
  constructor() {
    super('gpt/gpt_responses', { accountScoped: true });
  }

  getResponses(pageNumber) {
    let requestURL = `${this.url}?page=${pageNumber}`;
    return axios.get(requestURL);
  }

  updateResponse({ id, responseId }) {
    return axios.patch(`${this.url}/${id}/gpt/gpt_responses${responseId}`);
  }

  deleteResponse({ responseId }) {
    return axios.delete(
      `${this.url}/${responseId}/gpt/gpt_intents${responseId}`
    );
  }
}

export default new GptIntentResponseApi();
