<template>
  <div>
    <div class="columns small-9">
      <reply-schedule :model-configs="modelConfigsDetail" />
    </div>
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import configMixin from 'shared/mixins/configMixin';
import accountMixin from '../../../../mixins/account';
import { mapGetters } from 'vuex';
import ReplySchedule from './ReplySchedule.vue';
const DEFAULT_TIMEZONE = 'America/Los_Angeles';
export default {
  components: { ReplySchedule },
  mixins: [accountMixin, alertMixin, configMixin],
  data() {
    return {
      modelConfigsDetail: {},
      initModelConfigs: {
        language_code: 'English (en)',
        utter_default: {
          replyKey: '',
          timeConfigText: '',
          text: '',
          time_interval: 0,
          timezone: DEFAULT_TIMEZONE,
          options: [],
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'getCurrentUser',
    }),
    languagesSortedByCode() {
      const enabledLanguages = [...this.enabledLanguages];
      return enabledLanguages.sort((l1, l2) =>
        l1.iso_639_1_code.localeCompare(l2.iso_639_1_code)
      );
    },
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      const { model_config } = this.currentUser || {};
      if (!model_config && !model_config?.language_code) {
        delete this.initModelConfigs.utter_default.replyKey;
        delete this.initModelConfigs.utter_default.timeConfigText;
        this.$store.dispatch('aiConfigs/updateConfig', this.initModelConfigs);
      }
      this.modelConfigsDetail = model_config || this.initModelConfigs;
      const { language_code } = this.modelConfigsDetail;
      this.locale = language_code;
    },
  },
};
</script>
<style lang="scss" scoped></style>
