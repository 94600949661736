import Vue from 'vue';

import {
  SET_MODELS,
  SET_TRAINING_IN_PROGRESS,
  SET_MODELS_COUNT,
  SET_MODELS_UI_FLAG,
  DELETE_MODEL,
  CLEAR_MODELS,
} from './types';

export const mutations = {
  [SET_MODELS_UI_FLAG]: ($state, payload) => {
    $state.uiFlags = {
      ...$state.uiFlags,
      ...payload,
    };
  },
  [CLEAR_MODELS]: $state => {
    Vue.set($state, 'models', {});
  },
  [SET_MODELS]: ($state, models) => {
    models.forEach(model => {
      Vue.set($state.models, model.id, {
        ...($state.models[model.id] || {}),
        ...model,
      });
    });
  },
  [SET_TRAINING_IN_PROGRESS]: ($state, payload) => {
    $state.training = payload.training;
    $state.deploying = payload.deploying;
  },
  [SET_MODELS_COUNT]: ($state, count) => {
    Vue.set($state, 'count', count);
  },

  [DELETE_MODEL]: ($state, ModelId) => {
    const { [ModelId]: toDelete, ...models } = $state.models;
    Vue.set($state, 'models', models);
  },
};
