<template>
  <action-form
    :on-submit="create"
    :submit-in-progress="false"
    :submit-button-text="
      $t('AI_ACTION_MANAGE_SETTINGS.CREATE_FLOW.CREATE.BUTTON_TEXT')
    "
    :created-success="isCreatedSuccess"
  />
</template>

<script>
import ActionForm from '../ActionForm.vue';
import alertMixin from 'shared/mixins/alertMixin';
export default {
  components: { ActionForm },
  mixins: [alertMixin],
  data() {
    return { isCreatedSuccess: false };
  },
  computed: {},
  methods: {
    // eslint-disable-next-line no-empty-function
    async create() {},
  },
};
</script>
