import pdf from 'vue-pdf';
import * as mammoth from 'mammoth';
export const formatBytes = (bytes, decimals = 2) => {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
};

export const fileSizeInMegaBytes = bytes => {
  return bytes / (1024 * 1024);
};

export const checkFileSizeLimit = (file, maximumUploadLimit) => {
  const fileSize = file?.file?.size || file?.size;
  const fileSizeInMB = fileSizeInMegaBytes(fileSize);
  return fileSizeInMB <= maximumUploadLimit;
};

/* 读取 txt, markdown 内容 */
export const parseTxtContent = file => {
  return new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = error => {
        reject(new Error(`Failed to read txt or markdown file ${error}`));
      };
      reader.readAsText(file);
    } catch (error) {
      reject(
        new Error(
          `Failed to read the txt or markdown file. Please check if your browser supports it. ${error}`
        )
      );
    }
  });
};
/* 读取 doc, docx 内容 */
export const parseDocContent = file =>
  new Promise((resolve, reject) => {
    try {
      const reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async ({ target: { result } = { target: {} } }) => {
        if (!result) return reject(new Error('Failed to read the doc file'));
        try {
          const Output = await mammoth.extractRawText({
            arrayBuffer: result,
          });
          resolve(Output?.value);
        } catch (error) {
          reject(new Error(`Failed to read the doc file. ${error}`));
        }
      };
      reader.onerror = error => {
        reject(new Error(`Failed to read the doc file. ${error}`));
      };
    } catch (error) {
      reject(
        new Error(
          `Failed to read the doc file. Please check if your browser supports it. ${error}}`
        )
      );
    }
  });

/* 读取 pdf 内容 */
export const parsePdfContent = file =>
  new Promise((resolve, reject) => {
    try {
      const readPDFPage = async (doc, pageNo) => {
        const page = await doc.getPage(pageNo);
        const tokenizedText = await page.getTextContent();
        const pageText = tokenizedText.items.map(token => token.str).join(' ');
        return pageText;
      };
      let reader = new FileReader();
      reader.readAsArrayBuffer(file);
      reader.onload = async ({ target: { result } = { target: {} } }) => {
        if (!result) return new Error(`Failed to read the doc file.`);

        try {
          const fileData = new Uint8Array(reader.result);
          const doc = await pdf.createLoadingTask(fileData).promise;
          const pageContentPromises = [];
          for (let pageNo = 1; pageNo <= doc.numPages; pageNo += 1) {
            pageContentPromises.push(readPDFPage(doc, pageNo));
          }
          const pageContents = await Promise.all(pageContentPromises);
          resolve(pageContents.join('\n'));
        } catch (error) {
          reject(new Error(`Failed to read the pdf file. ${error}`));
        }
      };
      reader.onerror = error => {
        reject(new Error(`Failed to read the pdf file. ${error}`));
      };
    } catch (error) {
      reject(
        new Error(
          `Failed to read the doc file. Please check if your browser supports it. ${error}}`
        )
      );
    }
  });
