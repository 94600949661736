<template>
  <div class="wizard-body columns">
    <form @submit.prevent="handleSubmit">
      <woot-input
        v-model.trim="title"
        :class="{ error: $v.title.$error }"
        class="medium-12 input-row margin-b-large"
        :label="this.$t('INTENT_MANAGE_SETTINGS.FORM.NAME.LABEL')"
        :placeholder="
          $t('INTENT_MANAGE_SETTINGS.FORM.DESCRIPTION.INTENT_NAME_PLACEHOLDER')
        "
        @input="$v.title.$touch"
      />
      <ve-table
        ref="tableRef"
        row-key-field-name="rowKey"
        :columns="columns"
        max-height="calc(100vh - 50rem)"
        :table-data="examples"
        :edit-option="editOption"
        :row-style-option="rowStyleOption"
        :border-x="true"
        :border-y="true"
        class="p-all-1 margin-b-1 ve-table"
      />
      <div v-if="examples.length === 0" class="margin-b-1 empty-data">
        {{ this.$t('INTENT_MANAGE_SETTINGS.EMPTY_EXAMPLE') }}
      </div>
      <label>
        <textarea
          v-model.trim="example"
          rows="5"
          type="text"
          :placeholder="
            $t('INTENT_MANAGE_SETTINGS.FORM.DESCRIPTION.EXAMPLE_PLACEHOLDER')
          "
          @keyup.enter.prevent="handleKeyEvents"
        />
      </label>
      <div class="medium-12">
        <woot-button
          size="small"
          icon="add"
          :disabled="submitInProgress"
          variant="smooth"
          color-scheme="secondary"
          @click.prevent="addExample"
        >
          {{ $t('INTENT_MANAGE_SETTINGS.FORM.ADD_EXAMPLE_BUTTON') }}
        </woot-button>
      </div>
      <div class="gap" />
      <div class="modal-footer">
        <div class="medium-12 columns">
          <woot-submit-button
            :disabled="isButtonDisabled"
            :button-text="submitButtonText"
            :loading="submitInProgress"
          />
          <woot-button
            variant="clear"
            color-scheme="secondary"
            size=""
            @click.prevent="handleCancel"
          >
            {{ $t('INTENT_MANAGE_SETTINGS.FORM.CANCEL_BUTTON_TEXT') }}
          </woot-button>
        </div>
      </div>
    </form>
    <woot-confirm-modal
      ref="confirmDialog"
      :title="$t('INTENT_MANAGE_SETTINGS.FORM.CONFIRM.TITLE')"
      :description="$t('INTENT_MANAGE_SETTINGS.FORM.CONFIRM.MESSAGE')"
      :confirm-label="$t('INTENT_MANAGE_SETTINGS.FORM.CONFIRM.YES')"
      :cancel-label="$t('INTENT_MANAGE_SETTINGS.FORM.CONFIRM.NO')"
    />
  </div>
</template>

<script>
import WootSubmitButton from '../../../../components/buttons/FormSubmitButton';
import validations from './helpers/validations';
import { VeTable } from 'vue-easytable';
import alertMixin from 'shared/mixins/alertMixin';
import _has from 'lodash/has';
import { buildHotKeys } from 'shared/helpers/KeyboardHelpers';
import router from '../../../index';

export default {
  components: {
    WootSubmitButton,
    VeTable,
  },
  mixins: [alertMixin],
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    createdSuccess: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    const formData = this.formData || {};
    const {
      response = {
        text: '',
      },
      name: title = '',
      examples = [],
    } = formData;

    return {
      title,
      dynamicValidateForm: [],
      response,
      examples,
      hideResponse: null,
      index: 0,
      example: '',
      rowStyleOption: {
        clickHighlight: false,
        hoverHighlight: false,
      },
      editOption: {
        beforeCellValueChange: ({ column, changeValue }) => {
          if (column.field === 'example' && !/^.+$/.test(changeValue)) {
            this.showAlert(
              this.$t('INTENT_MANAGE_SETTINGS.FORM.API.ALERT.EXAMPLE')
            );
            return false;
          }
          return '';
        },
      },
      widgetScreens: [
        {
          id: 'option1',
          title: '文本',
          checked: true,
        },
        {
          id: 'option2',
          title: '单选',
          checked: false,
        },
        {
          id: 'option3',
          title: '多选',
          checked: false,
        },
      ],
      columns: [
        {
          field: 'example',
          key: 'example',
          align: 'left',
          title: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.EXAMPLE'),
          edit: true,
          ellipsis: {
            showTitle: true,
            lineClamp: 3,
          },
        },
        {
          field: '',
          key: 'e',
          title: this.$t('INTENT_MANAGE_SETTINGS.LIST_HEADER.ACTIONS'),
          width: 70,
          center: 'left',
          edit: false,
          renderBodyCell: ({ rowIndex }) => {
            return (
              <span>
                <woot-button
                  type="button"
                  size="small"
                  variant="smooth"
                  color-scheme="secondary
                  "
                  icon="delete"
                  class="delete-custom-view__button"
                  on-click={() => this.deleteRow(rowIndex)}
                  disabled={this.submitInProgress}
                />
              </span>
            );
          },
        },
      ],
      tableData: [],
    };
  },
  computed: {
    isButtonDisabled() {
      return this.$v.title.$invalid || this.submitInProgress;
    },
    intentId() {
      return Number(this.$route.params.intentId);
    },
    hideResponseBox() {
      return this.hideResponse && this.formData;
    },
  },
  validations,
  mounted() {
    document.addEventListener('keydown', this.handleKeyEvents);
    const hasResponse = _has(this.formData, 'response');
    if (!hasResponse) {
      this.hideResponse = true;
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyEvents);
  },
  methods: {
    resetForm() {
      if (this.createdSuccess) {
        this.title = '';
        this.examples = [];
        this.example = '';
        this.response.text = '';
        this.$v.title.$reset();
      }
    },
    handleKeyEvents(e) {
      const keyCode = buildHotKeys(e);
      if (['meta+enter', 'ctrl+enter'].includes(keyCode)) {
        this.addExample();
      }
    },
    addExample() {
      const examples = this.example.split('\n');
      if (this.example === '') return;
      if (this.example.indexOf('\n') === -1) {
        this.examples.unshift({
          example: this.example,
          // eslint-disable-next-line no-plusplus
          rowKey: this.index++,
        });
      } else {
        const exampleObjects = examples.map(item => {
          // eslint-disable-next-line no-plusplus
          return { example: item, rowKey: this.index++ };
        });
        exampleObjects.forEach(exampleObj => {
          this.examples.unshift(exampleObj);
        });
      }
      this.example = '';
      this.showAlert(
        this.$t('INTENT_MANAGE_SETTINGS.FORM.API.ADD_SUCCESS_MESSAGE')
      );
    },
    deleteRow(rowIndex) {
      this.examples.splice(rowIndex, 1);
      this.showAlert(
        this.$t('INTENT_MANAGE_SETTINGS.FORM.API.EXAMPLE_SUCCESS_MESSAGE')
      );
    },
    async handleCancel() {
      const ok = await this.$refs.confirmDialog.showConfirmation();
      if (ok) {
        router.back();
      }
    },
    async handleSubmit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
      const formData = {
        id: this.intentId,
        examples: this.examples.map(example => example),
        name: this.title,
        response: this.response,
      };
      if (this.hideResponseBox) {
        delete formData.response;
      }
      // 提交表单数据
      this.onSubmit(formData);
      await this.resetForm();
    },
  },
};
</script>
<style lang="scss" scoped>
.empty-data {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 150px;
  width: 100%;
  color: #666;
  font-size: 16px;
  border: 1px solid #eee;
  border-top: 0;
}
.input-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  :nth-child(1) {
    flex-shrink: 0;
    flex-grow: 1;
    margin-right: var(--space-one);
    font-size: var(--font-size-default);
  }
  :nth-child(2) {
    margin: 0;
    height: var(--space-large);
  }
}
.ve-table {
  font-size: var(--font-size-default);
}
.margin-b-1 {
  margin-bottom: var(--space-one);
}
.margin-b-large {
  margin-bottom: var(--space-large);
}
.radio-group {
  margin-top: var(--space-minus-small);
}
.pd-all-1 {
  padding: 1rem;
}
.pd-vertical-1 {
  padding: 1rem 0;
}
textarea {
  width: 100%;
}
.gap {
  height: var(--space-large);
}
</style>
