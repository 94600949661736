import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
const state = {
  actions: {
    count: 0,
    data: [],
  },
  uiFlags: {
    isFetching: true,
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
