<template>
  <story-form
    v-if="showStoryForm"
    :on-submit="updateStory"
    :submit-button-text="$t('AI_STORY_MANAGE_SETTINGS.FORM.UPDATE')"
    :form-data="storyData"
  />
</template>

<script>
import StoryForm from '../StoryFrom.vue';
import alertMixin from 'shared/mixins/alertMixin';
import router from '../../../../index';
import { mapGetters } from 'vuex';
export default {
  components: { StoryForm },
  mixins: [alertMixin],
  data() {
    return {
      enabledFeatures: {},
    };
  },
  computed: {
    ...mapGetters({
      actions: 'aiActionManage/getActions',
    }),
    storyData() {
      const { storyId } = this.$route.params;
      const stories = this.$store.getters['aiStoryManage/getStory'](storyId);
      return stories;
    },
    showStoryForm() {
      const { id } = this.storyData;
      return id;
    },
  },
  methods: {
    async updateStory(data) {
      try {
        const { storyId } = this.$route.params;
        await this.$store.dispatch('aiStoryManage/updateStory', {
          id: storyId,
          ...data,
        });
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.EDIT_FLOW.API.SUCCESS_MESSAGE')
        );
        router.replace({
          name: 'settings_ai_story_manage_list',
        });
      } catch (error) {
        this.showAlert(
          this.$t('AI_STORY_MANAGE_SETTINGS.EDIT_FLOW.API.ERROR_MESSAGE')
        );
      }
    },
  },
};
</script>
