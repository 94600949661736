<template>
  <div class="wizard-body column content-box">
    <form @submit.prevent="handleSubmit">
      <div class="small-6 columns">
        <h3>
          {{ $t('KNOWLEDGE_BASE_SETTINGS.DESCRIPTION.TITLE') }}
        </h3>
        <p style="line-height:25px">
          {{ $t('KNOWLEDGE_BASE_SETTINGS.DESCRIPTION.ROW_1') }}
        </p>
      </div>
      <div v-if="!showLoading">
        <woot-input
          v-model.trim="title"
          class="medium-12 input-row"
          :label="$t('KNOWLEDGE_BASE_SETTINGS.FORM.DOCUMENT_NAME')"
          :placeholder="$t('KNOWLEDGE_BASE_SETTINGS.FORM.PLACEHOLDER')"
        />
        <label class="open-select">
          <woot-button
            v-tooltip.top-end="$t('KNOWLEDGE_BASE_SETTINGS.FORM.TIPS')"
            variant="smooth"
            color-scheme="secondary"
            size="small"
            :is-loading="isImporting"
            @click.prevent="openSelect"
          >
            {{ $t('KNOWLEDGE_BASE_SETTINGS.FORM.IMPORT_BUTTON') }}
          </woot-button>
        </label>

        <textarea
          v-model="content"
          :rows="15"
          type="text"
          :placeholder="
            $t('KNOWLEDGE_BASE_SETTINGS.FORM.TEXT_AREA_PLACEHOLDER')
          "
          :maxlength="maxTextareaLength"
        />

        <p v-if="content.length >= maxTextareaLength" style="color: red;">
          {{ $t('KNOWLEDGE_BASE_SETTINGS.FORM.API.EXCEED_LENGTH') }}
        </p>
        <div v-if="content">
          <p>
            {{ $t('KNOWLEDGE_BASE_SETTINGS.DESCRIPTION.CURRENT_TEXT_LENGTH')
            }}{{ content.length }}
          </p>
        </div>
      </div>
      <woot-loading-state v-if="false" />
      <div class="medium-12 columns">
        <woot-submit-button
          :disabled="isButtonDisabled"
          :button-text="submitButtonText"
          :loading="submitInProgress"
        />
        <woot-button
          variant="clear"
          color-scheme="secondary"
          size=""
          @click.prevent="handleCancel"
        >
          {{ $t('KNOWLEDGE_BASE_SETTINGS.FORM.CANCEL_BUTTON_TEXT') }}
        </woot-button>
      </div>
    </form>
    <select-file
      ref="selectFile"
      :file-type="fileExtension"
      :on-select="onSelectFile"
    />
    <woot-confirm-modal
      ref="confirmDialog"
      :title="$t('KNOWLEDGE_BASE_SETTINGS.FORM.CONFIRM.TITLE')"
      :description="$t('KNOWLEDGE_BASE_SETTINGS.FORM.CONFIRM.MESSAGE')"
      :confirm-label="$t('KNOWLEDGE_BASE_SETTINGS.FORM.CONFIRM.YES')"
      :cancel-label="$t('KNOWLEDGE_BASE_SETTINGS.FORM.CONFIRM.NO')"
    />
  </div>
</template>

<script>
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import { STORY_CONTENT_TYPE } from 'dashboard/constants';
import {
  parseTxtContent,
  parsePdfContent,
  parseDocContent,
} from 'shared/helpers/FileHelper';
import SelectFile from './components/SelectFile.vue';
import router from '../../../index';

export default {
  components: { SelectFile },
  mixins: [alertMixin],
  validations: {
    title: {
      required,
    },
    content: {
      required,
    },
  },
  props: {
    onSubmit: {
      type: Function,
      default: () => {},
    },
    submitInProgress: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object,
      default: () => {},
    },
    submitButtonText: {
      type: String,
      default: '',
    },
  },
  data() {
    const formData = this.formData || {};
    const { title, steps = [] } = formData;
    return {
      title,
      steps,
      timerId: null,
      fileExtension: '.txt,.doc,.docx,.pdf,.md',
      currentRequests: 0,
      maxTextareaLength: 10000,
      isImporting: false,
      showLoading: false,
      content: '',
      result: [],
      parsedText: '',
    };
  },
  computed: {
    isButtonDisabled() {
      return (
        this.submitInProgress ||
        this.content.length >= this.maxTextareaLength ||
        this.$v.content.$invalid ||
        this.$v.title.$invalid
      );
    },
    segments() {
      return this.getSegments(this.content).length;
    },
    storyId() {
      return Number(this.$route.params.storyId);
    },
    totalRequests() {
      return Math.ceil(this.content.length / 3000);
    },
  },
  created() {
    this.init();
  },
  methods: {
    // 将长文本分段的方法
    getSegments(content) {
      const segments = [];
      const segmentLength = 3000; // 每段长度为3000
      let currentSegment = '';
      let lines = content.split(/(?<=(?<![a-zA-Z0-9])[。.])\s*(?=\D)/);
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        // 判断当前段落与当前行合并后是否超过了3000个字符
        if (currentSegment.length + line.length <= segmentLength) {
          currentSegment += line; // 合并到当前段落中
        } else {
          segments.push(currentSegment.trim()); // 当前段落结束，存储到分段数组中
          currentSegment = line; // 开始一个新段落
        }
        // 如果当前段落已经超过了3000个字符，存储到分段数组中，并开始一个新段落
        if (currentSegment.length >= segmentLength) {
          segments.push(currentSegment.trim());
          currentSegment = '';
        }
      }
      // 将最后一段文本加入到分段数组中
      if (currentSegment.length > 0) {
        segments.push(currentSegment.trim());
      }

      return segments;
    },
    async handleCancel() {
      const ok = await this.$refs.confirmDialog.showConfirmation();
      if (ok) {
        router.replace({
          name: 'settings_knowledge_base_list',
        });
      }
    },
    async processFile(file) {
      const fileSuffix = file?.name
        ?.split('.')
        ?.pop()
        ?.toLowerCase();
      switch (fileSuffix) {
        case 'txt':
        case 'md':
          this.title = file?.name.split('.')[0] || '';
          this.content = await parseTxtContent(file);
          break;
        case 'pdf':
          this.title = file?.name.split('.')[0] || '';
          this.content = await parsePdfContent(file);
          break;
        case 'doc':
        case 'docx':
          this.title = file?.name.split('.')[0] || '';
          this.content = await parseDocContent(file);
          break;
        default:
          return '';
      }
      return '';
    },
    async onSelectFile(files) {
      this.isImporting = true;
      let promise = Promise.resolve();
      try {
        files.forEach(file => {
          promise.then(async () => {
            await this.processFile(file);
          });
        });
      } catch (error) {
        //
      } finally {
        this.isImporting = false;
      }
    },
    openSelect() {
      this.isImporting = true;
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
      this.timerId = setTimeout(() => {
        this.isImporting = false;
      }, 1500);
      this.$refs.selectFile.onOpen();
    },
    init() {
      if (this.formData)
        this.content = this.steps.map(item => item.reply).join('\n');
    },
    async handleSubmit() {
      const segments = this.getSegments(this.content);
      segments.forEach(segment => {
        if (!segment) {
          return;
        }
        this.result.push({
          reply: segment,
        });
      });
      const { title, storyId, result } = this;
      const formData = {
        id: storyId,
        title,
        steps: result,
        content_type: STORY_CONTENT_TYPE.DOCUMENT,
      };
      this.onSubmit(formData);
      this.result = [];
    },
  },
};
</script>

<style lang="scss" scoped>
.input-row {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: var(--space-one) 0 var(--space-medium) 0;
  :nth-child(1) {
    flex-shrink: 0;
    flex-grow: 1;
    font-size: var(--font-size-default);
    margin-right: var(--space-one);
  }
  :nth-child(2) {
    margin: 0;
    height: var(--space-large);
  }
}
.open-select {
  display: inline;
  margin-left: var(--space-one);
  &:hover {
    color: var(--color-primary-dark);
  }
}
</style>
